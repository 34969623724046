import WhichPage from "../../Init/header/WhichPage";
import ContactForm from "./ContactForm";
import ContactsArea from "./ContactsArea";
import Map from "./Map";

const Contact = () => {
  return (
    <div className="main-wrapper">
      <WhichPage page=" Contact" />
      <div className="contact-us-area pt-65 pb-55">
        <div className="container">
          <div className="section-title-2 mb-20 wow tmFadeInUp">
            <h2>Centre de support</h2>
            <p>
              Nos agents sont disponibles 24/7 par Tickets, Email, Chat et
              téléphone
            </p>
          </div>
        </div>
      </div>
      <ContactsArea />
      <Map />
      <ContactForm />
    </div>
  );
};

export default Contact;
