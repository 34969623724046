import axios from "axios";
import authHeader from "./auth-header";

export function getPanier(){
  return axios.get(`${window.API_URL}user/panier`, authHeader());
};

export function CreatePanier({
    product_id,
    color,
    size,
    quantity
}) {
    return axios.post(`${window.API_URL}user/panier`, 
    {
        product_id,
        color,
        size,
        quantity
    },
    authHeader());
};

export function UpdatePanier({
    panier_id,
    quantity
}){
    return axios.put(`${window.API_URL}user/panier`,
    {
        panier_id,
        quantity
    },
    authHeader());
};

export function DeletePanier(panier_id){
    return axios.delete(`${window.API_URL}user/panier`, {
        data : {
            panier_id
        },
        headers : authHeader().headers
    });
};

// orders

export function CreateOrder({
    address="",
    products=[]
}) {
    return axios.post(`${window.API_URL}user/order`, 
    {
        products,
        address
    },
    authHeader());
};

export function getOrders({
    pageId=0,
    npage=5
}){
    return axios.get(`${window.API_URL}user/order`, {
        ...authHeader(),
        params : {
            pageId,
            npage
        }
    });
};

export function getOrderDetails(order_id){
    return axios.get(`${window.API_URL}user/order/details`, 
    {
        ...authHeader(),
        params : {
            order_id
        },
    });
};

// create command imediat

export function CreateCommandImediat(data) {
    return axios.post(`${window.API_URL}user/achat-maintenant`, 
    data,
    authHeader());
};
