/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import UserService from "../../../services/user.service";

const Footer = () => {
  const [email, setEmail] = useState("");

  const handelSubmit = (e) => {
    e.preventDefault();
    if (email) {
      UserService.suscribe(email)
        .then((res) => NotificationManager.success("vous êtes abonné"))
        .catch((err) =>
          NotificationManager.error(`${err.response.data.erreur}`)
        );
    }
  };

  return (
    <footer className="footer-area bg-gray-2 pt-75">
      <div className="custom-container">
        <div className="footer-top pb-30">
          <div className="row">
            <div className="col-width-33 custom-common-column">
              <div className="footer-widget footer-about-2 mb-40">
                <div className="footer-logo logo-width-1">
                  <a href="/accueil">
                    <img src="/assets/images/logo/logo.png" alt="logo" />
                  </a>
                </div>
                <div className="footer-widget mb-40">
                  <div className="row">
                    <div className="col-6">
                      <div className="footer-info-list">
                        <ul>
                          <li>
                            <a href="/apropos"> À propos de nous </a>
                          </li>
                          <li>
                            <Link to="/blog"> Nos Articles</Link>
                          </li>
                          <li>
                            <Link to="/contact"> Nous contacter </Link>
                          </li>
                          <li>
                            <Link to="/boutique"> Boutique</Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="footer-info-list">
                        <ul>
                          <li>
                            <Link to="/wishlist">Ma liste d'envies </Link>
                          </li>
                          <li>
                            <Link to="/profil"> Mon profil </Link>
                          </li>
                          <li>
                            <Link to="/compare"> Comparateur </Link>
                          </li>
                          <li>
                            <Link to="/reclamation"> Réclamation </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-width-16 custom-common-column">
              <div className="footer-widget mb-40">
                <div className="footer-info-list">
                  <ul>
                    <li>
                      <Link to="/boutique"> Boutique</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Ma liste d'envies </Link>
                    </li>
                    <li>
                      <Link to="/profil"> Mon profil </Link>
                    </li>
                    <li>
                      <Link to="/compare"> Compare </Link>
                    </li>
                    <li>
                      <Link to="/reclamation"> Reclamation </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-width-33 custom-common-column d-flex justify-content-center align-items-start border-right border-left">
              <div className="footer-widget mt-40 mb-40">
                <h3 className="footer-title" style={{ color: "#2f4e81" }}>
                  Nos contacts
                </h3>
                <div className="footer-contact-info">
                  <div>
                    <h4>Casablanca : </h4>
                    <p>
                      504, Lotissement Hadj Fatah 20260 - El Oulfa - Casablanca
                      <br></br>
                      Téléphone : +212 520 333 012 <br></br>
                      Portable : + 212 625 701 262
                    </p>
                    <p>
                      hfc-casa@healthcarefacilitiesconsult.com<br></br>
                      hfc-casa@hfc.ma
                    </p>

                    <h4>Oujda :</h4>
                    <p>
                      32, Lotissement Benmoussa - Quartier du CHU 60000 - Oujda
                      <br></br>
                      Tééphone : +212 520 333 011 <br></br>Portable : +212 664
                      332 058
                    </p>
                    <p>
                      hfc-oujda@healthcarefacilitiesconsult.com<br></br>
                      hfc-oujda@hfc.ma
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-width-33 custom-common-column">
              <div className="footer-widget footer-about-2 mt-40 mb-40">
                <h3 className="footer-title" style={{ color: "#2f4e81" }}>
                  Réseaux sociaux
                </h3>
                <div className="subscribe-wrap">
                  <div className="footer-social-icon tooltip-style-4">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.facebook.com/Healthcare.Facilities.Consult/"
                      aria-label="Facebook"
                      className="facebook"
                    >
                      <i className="fab fa-facebook-f" />
                    </a>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://www.instagram.com/healthcare_facilities_consult/"
                      aria-label="Instagram"
                      className="instagram"
                    >
                      <i className="fab fa-instagram" />
                    </a>
                  </div>
                </div>
                <h3 className="footer-title" style={{ color: "#2f4e81" }}>
                  Inscrivez-vous à notre newsletter
                </h3>
                <div className="subscribe-wrap">
                  <p>
                    Abonnez-vous et recevez toutes nos offres spéciales et
                    nouveaux arrivages en premier.
                  </p>
                  <div id="mc_embed_signup" className="subscribe-form">
                    <form
                      className="validate subscribe-form-style"
                      onSubmit={handelSubmit}
                    >
                      <div id="mc_embed_signup_scroll" className="mc-form">
                        <input
                          className="email"
                          type="email"
                          required
                          placeholder="Votre e-mail"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                        />

                        <div className="clear">
                          <input
                            className="button"
                            type="submit"
                            name="subscribe"
                            value="S'abonner"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-3 border-top">
            <p className="mt-3">
              HFC © 2021 Copyright: BUSINESS INNOVATION SARL AU. Tous les droits
              sont réservés
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
