import { TwitterShareButton } from "react-share";

const ShareOnTwitter = (props) => {
  // const postUrl ="https://hfc.storeproductmaroc.com/public/blog/detail/5"
  return (
    <TwitterShareButton url={props.urlToShare} className="ml-4">
      <i className="fab fa-twitter" />
    </TwitterShareButton>
  );
};

export default ShareOnTwitter;
