import ProductsService from "./product.service";

// var compares = [];
// if (localStorage.compares) compares = JSON.parse(localStorage.compares);

const getListCompares = () => {
  var compares = [];
  if (localStorage.compares) compares = JSON.parse(localStorage.compares);
  return compares;
};
const isItemInList = (id) => {
  var cprs = getListCompares();
  console.log(cprs);
  for (let i = 0; i < cprs.length; i++) {
    if (id === cprs[i]) return true;
  }
  return false;
};

const addCompare = (idProduct) => {
  var cprs = getListCompares();
  cprs.push(idProduct);
  localStorage.compares = JSON.stringify(cprs);
};

const getAllProductinCompare = async () => {
  var tmpList = [];
  const id_Products = CompareService.getListCompares();
  for (let i = 0; i < id_Products.length; i++) {
    await ProductsService.productById(id_Products[i])
      .then((res) => {
        tmpList.push(res.data.result[0]);
      })
      .catch((err) => console.log(err));
  }

  return tmpList;
};

const CompareService = {
  getListCompares,
  addCompare,
  isItemInList,
  getAllProductinCompare,
};
export default CompareService;
