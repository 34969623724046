import WhichPage from "../../Init/header/WhichPage";
import CompareTabContent from "./CompareTabContent";

const Compare = () => {
  return (
    <>
      <WhichPage page="Compare" />
      <CompareTabContent />
    </>
  );
};

export default Compare;
