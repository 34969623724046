import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ProductsService from "../../../services/product.service";

const SearchComponent = () => {
  const [searchValue, setSearchValue] = useState("");
  const [products, setProdcts] = useState([]);
  const history = useHistory();
  const handelSubmit = () => {
    if (searchValue !== '' && searchValue.trim() !== '')
      return history.push(`/search/${searchValue.trim()}#`);
  };
  // useEffect(() => {}, []);
  return (
    <div className="search-popup-wrap main-search-active">
      <div className="mobile-menu-close close-style-wrap">
        <button className="close-style search-close">
          <i className="icon-top" />
          <i className="icon-bottom" />
        </button>
      </div>
      <div className="d-block">
        <div className="search-popup-content">
          <form
            className="search-popup-form"
            onSubmit={(e) => (e.preventDefault())}
          >
            <input
              type="text"
              placeholder="Cherchez un produit…"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <div style={{width : 300, cursor : 'pointer', display : 'flex', alignItems : 'center', justifyContent : 'center'}} onClick={() => handelSubmit()}>
              <button className="close-style search-close" style={{padding : 10, borderRadius : 10, fontSize : 15, fontWeight : 'bold'}} >Submit</button>
            </div>
          </form>
        </div>
        <div
          className="d-flex align-center"
          style={{ height: "400px", overflowY: "auto" }}
        >
          <table className="table mt-5">
            <tbody>
              {products.map((p, i) => (
                <tr key={i}>
                  <td>
                    <a href={"/details-produit/" + p.name}>
                      {p.image && (
                        <img
                          height="50px"
                          src={`${window.IMAGE_API_URL}products/${
                            p.image.split(";")[0]
                          }`}
                          alt=""
                        />
                      )}
                    </a>
                  </td>
                  <td>
                    <a href={"/details-produit/" + p.name}> {p.name}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default SearchComponent;
