import { useState } from "react";
import WhichPage from "../../Init/header/WhichPage";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import "./styles.css";

const Login = () => {
  const [toggle, setToggle] = useState(false);
  const showSingUp = () => {
    setToggle(!toggle);
  };
  return (
    <>
      <WhichPage page=" Se connecter - S'inscrire" />
      <div className="login-register-area pt-75 pb-75">
        <div className="container">
          <div className="row d-flex flex-column justify-content-center box ">
            <LoginForm show={toggle} toggle={showSingUp} />
            <RegisterForm show={toggle} toggle={showSingUp} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
