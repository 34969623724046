import { Link } from "react-router-dom";
const SideMenu = () => {
  return (
    <div className="tm-demo-options-wrapper d-none d-md-block d-lg-block">
      <div className="tm-demo-options-toolbar tooltip-style-2">
        <Link aria-label="Demander un devis" to="/devis">
          <i className="far fa-file-invoice" />
        </Link>
        <Link aria-label="Ma liste d'envies" to="/wishlist">
          <i className="far fa-book-heart" />
        </Link>
        {sessionStorage.getItem("token") && (
          <Link aria-label="Profil" to="/profil">
            <i className="far fa-user" />
          </Link>
        )}
        <Link aria-label="comparateur" to="/compare">
          <i className="far fa-book" />
        </Link>
        <Link aria-label="Réclamation" to="/reclamation">
          <i className="fas fa-comment-exclamation" />
        </Link>
        {/* {sessionStorage.getItem("token") && (
          <Link aria-label="besoin d'aide ?" to="/messages">
            <i className="far fa-comment-alt-dots" />
          </Link>
        )} */}
      </div>
    </div>
  );
};

export default SideMenu;
