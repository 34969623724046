import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategorieService from "../../../services/categories.service";
import SubCategorieService from "../../../services/subCategorie.service";

const SubCategoriesForCatItem = (props) => {
  return (
    props.subcat && (
      <ul className="level-menu">
        {props.subcat.map(
          (subcat, i) =>
            subcat.category_id === props.cat.id && (
              <li key={i}>
                <Link
                  to={`/boutique/${props.cat.name.replaceAll(
                    "/",
                    "_"
                  )}/${subcat.name.replaceAll("/", "_")}`}
                >
                  {subcat.name}
                </Link>
              </li>
            )
        )}
      </ul>
    )
  );
};

const CategorieMenuForBoutiqueLI = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    CategorieService.allCategories().then((res) =>
      setCategories(res.data.results)
    );
    SubCategorieService.allSubCategories().then((res) =>
      setSubCategories(res.data.results)
    );
  }, []);
  return (
    <ul className="sub-menu">
      {categories.map((category, index) => (
        <li key={index}>
          <Link to={`/boutique/${category.name.replaceAll("/", "_")}`}>
            {category.name}
          </Link>
          <SubCategoriesForCatItem cat={category} subcat={subCategories} />
        </li>
      ))}
    </ul>
  );
};

export default CategorieMenuForBoutiqueLI;
