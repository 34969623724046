import { Link } from "react-router-dom";

const WhichPage = (props) => {
  var items = props.page.split("/");

  return (
    <div className="breadcrumb-area breadcrumb-area-padding-2 bg-gray-2">
      <div className="custom-container">
        <div className="breadcrumb-content text-center">
          <ul>
            <li>
              <Link to="/accueil">Accueil</Link>
            </li>
            {items.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default WhichPage;
