import React from "react";

const PaymentInfo = () => {
  return (
    <div className="col-12 col-md-4">
      <div className="card">
        <div className="card-header d-flex justify-content-center">
          <img src="assets/images/icon-img/cash-on-delivery.png" alt="" />
          <h3 className="m-3">Procédure d'achat</h3>
        </div>
        <div className="card-body" style={{ fontSize: "16px" }}>
          <ul>
            <li>
              Demande de devis &nbsp;&nbsp;&nbsp;{" "}
              <i className="fas fa-arrow-circle-left"></i>
            </li>
            <li>Réception de devis</li>
            <li>Prise de contact</li>
            <li>Validation du devis</li>
            <li>Emission du devis</li>
            <li>Réception du bon de commande</li>
            <li>Bon de livraison</li>
            <li>
            {'Livraisons gratuites pour les commandes supérieures à 1000 DH, et 35 DH pour les commandes inférieures.'}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PaymentInfo;
