import axios from "axios";
import authHeader from "./auth-header";

const id_user = sessionStorage.getItem("id");
const allBlogs = () => {
  return axios.get(window.API_URL + "blogs");
};

const allCategoriesBlogs = () => {
  return axios.get(window.API_URL + "catg_blogs");
};

const blogByIdCat = (idCat) => {
  return axios.post(`${window.API_URL}blog_cat/${idCat}`);
};

const blogById = (id) => {
  return axios.get(window.API_URL + "blog/" + id);
};
const lastBlog = () => {
  return axios.get(window.API_URL + "home_blog");
};
const addRate = (idBlog, rate) => {
  const data = { id_blog: idBlog, id_client: id_user, rate: rate };
  return axios.post(window.API_URL + "user/blog_rate", data, authHeader());
};
const addComment = (idBlog, rate) => {
  const data = { user_id: id_user, content: rate, id_blog: idBlog };
  return axios.post(window.API_URL + "user/add_comment", data, authHeader());
};

const blogComments = (id_blog) => {
  return axios.get(window.API_URL + "blog_comments/" + id_blog);
};
const totalBolgsNbr = () => {
  return axios.get(window.API_URL + "blogsNumber");
};
const blogsByPage = (data) => {
  return axios.post(window.API_URL + "blogs", data);
};

const BlogService = {
  allBlogs,
  allCategoriesBlogs,
  blogByIdCat,
  blogById,
  lastBlog,
  addRate,
  addComment,
  blogComments,
  totalBolgsNbr,
  blogsByPage,
};

export default BlogService;
