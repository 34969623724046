import moment from "moment";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import {
    getOrders,
    getOrderDetails
} from "../../../services/panier"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import {
  useHistory
} from 'react-router-dom';

export default function Orders(props) {
    const history = useHistory();
    const [data, setdata] = useState([]);
    const [orderProducts, setorderProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderId, setorderId] = useState(null);
    const [order, setorder] = useState(null);
    const [pagination, setpagination] = useState({
      pageId : 0,
      npage : 5,
      max_pageId : 0// maximum page
    });

    const params = new URLSearchParams(window.location.search)

    const LoadOrders = ({pageId, npage}) => (setLoading(true),getOrders({pageId, npage}).then((data) => (setdata(data.data.data),setpagination({...pagination, pageId, max_pageId : Math.ceil(data.data.length / data.data.npage) || 0})))
    .catch(err => NotificationManager.error(err?.response?.data?.error || err?.message || "something went wrong !!"))
    .then(() => setLoading(false)));

    const LoadOrderDetails = (orderId) => (setLoading(true),getOrderDetails(orderId).then((data) => {
      if (!data.data?.order?.length)
        return NotificationManager.warning("order id not found !!");
      setorderProducts(data.data.data);
      setorder(data.data.order[0]);
    })
    .catch(err => NotificationManager.error(err?.response?.data?.error || err?.message || "something went wrong !!"))
    .then(() => setLoading(false)));

    useEffect(() => 
      !orderId ? LoadOrders({...pagination}) :
      ((!orderProducts.length ||
      orderProducts[0].order_id !== orderId)
      && (setorderProducts([]),LoadOrderDetails(orderId)))
    , [orderId]);

    const SelectColorImg = (order) => {
      try {
        let ColorSize = order.ColorSize ? JSON.parse(order.ColorSize) : [];
        let ImagePath =
        ColorSize.reduce((prev, color) => 
        !prev ? ((color.color === order.color && color.size === order.size) ? color.image : prev) : prev, null);
        console.log('=>', ImagePath, order.color, order);
        if (ImagePath)
          return `${window.API_URL}${ImagePath.replace(/^\//, '')}`;
      }catch (e){
      }
      return `${window.IMAGE_API_URL}${"products/" + order?.image?.split(";")[0]}`;
    }
  
    useEffect(() => {
      let orderId = parseInt(params.get('order_id'));
      if (orderId && /^[\d]{1,100}$/.test(orderId))
        setorderId(orderId);
    },[window.location.search, data]);

    return (
        !orderId ? 
        <div className="myaccount-content">
        <div className="myaccount-table table-responsive text-center">
          <table className="table table-bordered">
            <thead className="thead-light">
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>total</th>
                <th>items</th>
                <th>status</th>
                <th>date</th>
              </tr>
            </thead>
            {
                loading ? 
                <div>
                    <p>Loading ...</p>
                </div> : <></>
            }
            <tbody>
              {data.map((val, i) =>
                <tr key={i}>
                  <td>
                    <div className="check-btn sqr-btn " style={{
                        cursor : 'pointer'
                    }} onClick={() => setorderId(val.order_id)}>
                        <img
                        src={SelectColorImg(val)}
                        alt={'product image'}
                        width={100}
                        />
                    </div>
                  </td>
                  <td>{val.name}</td>
                  <td>{val.total} DH</td>
                  <td>{val.total_items} items</td>
                  <td>
                      {val.status}
                  </td>
                  <td>{moment(val.created_at).format("YYYY-MM-DD")} <span> ( {moment(val.created_at).fromNow()} )</span></td>
                </tr>
              )}
            </tbody>
          </table>
          {
            pagination.max_pageId > 1 ?
            <Stack spacing={2} style={{alignItems : 'center'}}>
              <Pagination
              count={pagination.max_pageId}
              page={pagination.pageId + 1}
              onChange={(e, pageId) => {
                LoadOrders({...pagination, pageId : pageId - 1})
              }}
              color="primary"
              />
            </Stack>
            :<></>
          }
        </div>
      </div>
      :
      <div>
        <OrderDetails
          order={order}
          orderProducts={orderProducts}
          loading={loading}
          setorderId={() => {
            history.push('/Profil#command');
            setorderId(null);
          }}
          SelectColorImg={SelectColorImg}
        />
      </div>
    )
}

function OrderDetails({
    order,
    orderProducts,
    loading,
    setorderId,
    SelectColorImg
}) {
  return (
    <div>
      <div style={{width : '100%', display : 'flex', alignItems : 'center'}}>
        <div style={{marginRight : 10,cursor : 'pointer'}} onClick={() => {
          setorderId(null)
        }}>
          <ArrowBackIcon fontSize={'large'}/>
        </div>
        <div>
          <p style={{fontWeight : 'bold', fontSize : 25}}>Detail Order</p>
        </div>
      </div>
      <div style={{width : '100%', marginTop : 29, marginLeft : 40}}>
        <div style={{display : 'flex', marginBottom : 5, alignItems : 'center'}}>
            <div>
              <p style={{fontSize : 25}}>order number </p>
            </div>
            <div>
              <p style={{fontWeight : 'bold', fontSize : 25, padding : 5}}>{order?.order_id}</p>
            </div>
        </div>
        <div style={{display : 'flex', alignItems : 'center'}}>
            <div>
              <p style={{fontSize : 20}}>status </p>
            </div>
            <div>
              <p style={{fontWeight : 'bold', fontSize : 20, padding : 3}}>{order?.status}</p>
            </div>
        </div>
        <div style={{display : 'flex', alignItems : 'center'}}>
            <div>
              <p style={{fontSize : 20}}>total </p>
            </div>
            <div>
              <p style={{fontWeight : 'bold', fontSize : 20, padding : 3}}>{order?.total} DH</p>
            </div>
        </div>
        <div style={{display : 'flex', marginTop : 10, alignItems : 'center'}}>
            <div>
              <p style={{fontSize : 17, color : 'gray'}}>created le </p>
            </div>
            <div>
              <p style={{fontWeight : 'bold', fontSize : 17, padding : 3, color : 'grey'}}>{moment(order?.created_at).format("YYYY-MM-DD")} <span> ( {moment(order?.created_at).fromNow()} )</span></p>
            </div>
        </div>
        <div style={{display : 'flex', alignItems : 'center'}}>
            <div>
              <p style={{fontSize : 17, color : 'gray'}}>modified le </p>
            </div>
            <div>
              <p style={{fontWeight : 'bold', fontSize : 17, padding : 3, color : 'grey'}}>{moment(order?.modified_at).format("YYYY-MM-DD")} <span> ( {moment(order?.modified_at).fromNow()} )</span></p>
            </div>
        </div>
      </div>
      <hr />
      {
        !loading ?
        <div style={{width : '100%', margin : 20}}>
          <div style={{marginBottom : 10, alignItems : 'center'}}>
              <div>
                <p style={{fontSize : 25}}>ITEMS IN THIS ORDER ({order?.total_items})</p>
              </div>
          </div>
          <div 
          style={{width : '100%', marginLeft : 40,
          display : 'flex', flexDirection : 'row', overflow : 'auto'}}>
            {
              orderProducts.map((val, ind) => 
              <div key={ind}
              style={{
                borderRadius : 5,overflowWrap: 'break-word', alignItems : 'center',
                display : 'flex', flexDirection : 'column',
                borderWidth : 1, borderColor : '#d4cbcb',
                borderStyle : 'solid', padding : 10, marginRight : 5, marginBottom : 5, height : 'auto'}}>
                  <div style={{padding : 10}}>
                    <Link to={`/details-produit/${val.name}`}>
                      <img
                        src={SelectColorImg(val)}
                        alt={'product image'}
                        width={150}
                        style={{
                          borderRadius : 5,
                          borderWidth : 1,
                          borderStyle : 'solid'
                        }}
                      />
                    </Link>
                  </div>
                  <div style={{display : 'flex', alignItems : 'center'}}>
                      <div>
                        <p style={{fontSize : 20, color : 'black'}}>{val?.name || ''}</p>
                      </div>
                  </div>
                  <div style={{display : 'flex', alignItems : 'center'}}>
                      <div>
                        <p style={{fontSize : 18, color : 'black'}}>{'total'}</p>
                      </div>
                      <div>
                        <p style={{fontWeight : 'bold', fontSize : 18, padding : 3, color : 'black'}}>{val?.price * val.quantity} DH</p>
                      </div>
                  </div>
                  <div style={{display : 'flex', alignItems : 'center'}}>
                      <div>
                        <p style={{fontSize : 17, color : 'gray'}}>quantity </p>
                      </div>
                      <div>
                        <p style={{fontWeight : 'bold', fontSize : 17, padding : 3, color : 'grey'}}>
                          {val.quantity}
                        </p>
                      </div>
                  </div>
                  <div style={{display : 'flex', alignItems : 'center'}}>
                      <div>
                        <p style={{fontSize : 17, color : 'gray'}}>Taille </p>
                      </div>
                      <div>
                        <p style={{fontWeight : 'bold', fontSize : 17, padding : 3, color : 'grey'}}>
                          {val.size}
                        </p>
                      </div>
                  </div>
                  <div style={{display : 'flex', alignItems : 'center'}}>
                      <div>
                        <p style={{fontSize : 17, color : 'gray'}}>color </p>
                      </div>
                      <div>
                        <p style={{fontWeight : 'bold', fontSize : 17, padding : 3, color : 'grey'}}>
                          {val.color}
                        </p>
                      </div>
                  </div>
              </div>
              )
            }
          </div>
        </div> :
        <div style={{display : 'flex', alignItems : 'center', marginLeft : 20}}>
          <div>
            <p style={{fontSize : 20, color : 'grey'}}>{'Loading ...'}</p>
          </div>
        </div>
      }
    </div>
  )
}