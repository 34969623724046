import { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DevisService from '../../../services/devis.service'
import { ClipLoader } from "react-spinners";
import AuthService from "../../../services/auth.service";
import Moment from "react-moment";
import UserService from "../../../services/user.service";
const Account = () => {

    const pwdForm = useRef("null")
    const infoForm = useRef("null")

    const [category, setCategory] = useState("")
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(false)
    const [nameDrgerant, setnameDrgerant] = useState("")
    const fdToJson = (fd) => {
        let obj = {};
        for (let key of fd.keys()) {
            obj[key] = fd.get(key);
        }
        return obj;
    };

    const updateInfo = (e) => {
        e.preventDefault()
        const data = fdToJson(new FormData(infoForm.current))
        UserService.updateInfo(data).then(
            NotificationManager.success('vôtre infos sont mis à jour')
        )
            .catch(function (error) {
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                }
                else if (error.response.status === 403) {
                    setLoading(false)
                    error.response.data.error || error.response.data.message ?
                        NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
                        :
                        NotificationManager.warning("Could load user info", "", 2000)

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.response.status === 401) {
                    setLoading(false)
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    setTimeout(() => {
                        AuthService.logout()
                    }, 500);
                }
            });
    }
    const updatePassword = (e) => {
        e.preventDefault()
        const data = fdToJson(new FormData(pwdForm.current))
        UserService.updatePassword(data).then(
            NotificationManager.success('vôtre mot de pass est mis à jour')
        )
            .catch(function (error) {
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                }
                else if (error.response.status === 403) {
                    setLoading(false)
                    error.response.data.error || error.response.data.message ?
                        NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
                        :
                        NotificationManager.warning("Could update password", "", 2000)
                } else if (error.response.status === 401) {
                    setLoading(false)
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    setTimeout(() => {
                        AuthService.logout()
                    }, 500);
                }
            });
    }


    useEffect(() => {
        setLoading(true)
        UserService.getUserById()
            .then(res => {
                // console.log(res.data.result[0].nameDrgerant)
                setUser(res.data.result[0])
                setCategory(res.data.result[0].category)
                setnameDrgerant(res.data.result[0].nameDrgerant)
                setLoading(false)
            })
            .catch(function (error) {
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                }
                else if (error.response.status === 403) {
                    setLoading(false)
                    error.response.data.error || error.response.data.message ?
                        NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
                        :
                        NotificationManager.warning("Could load devis", "", 2000)

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.response.status === 401) {
                    setLoading(false)
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    setTimeout(() => {
                        AuthService.logout()
                    }, 500);
                }
            });
    }, [])


    return (
        !loading ?
            <div className="myaccount-content">
                <div className="account-details-form">
                    <form ref={infoForm} onSubmit={updateInfo}>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="account-info input-style mb-30">
                                    <label>Email *</label>
                                    <input
                                        defaultValue={user.email || ""}
                                        name="email"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="account-info input-style mb-30">
                                    <label>Nom *</label>
                                    <input
                                        defaultValue={user.name || ""}
                                        name="name"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="account-info input-style mb-30">
                                    <label>Prenom*</label>
                                    <input
                                        defaultValue={user.surname || ""}
                                        name="surname"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="account-info input-style mb-30">
                                    <label>ADRESSE*</label>
                                    <input
                                        defaultValue={user.adresse || ""}
                                        name="adresse"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="account-info input-style mb-30">
                                    <label>TÉLÉPHONE*</label>
                                    <input
                                        defaultValue={user.phone || ""}
                                        name="phone"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row account-info mb-30">
                            <label>SEXE *</label>
                            <div className="col d-flex justify-content-center">
                                <div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            defaultChecked={user.gender === "homme" ? "checked" : ''}
                                            id="civ_homme"
                                            value="homme"
                                        />
                                        <label className="form-check-label" htmlFor="civ_homme">
                                            Homme
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col d-flex justify-content-center">
                                <div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="gender"
                                            defaultChecked={user.gender === "Femme" ? "checked" : ''}
                                            id="civ_femme"
                                            value="Femme"
                                        />
                                        <label className="form-check-label" htmlFor="civ_femme">
                                            Femme
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row account-info  mb-30">
                            <label>Civilité</label>
                            <div className="col d-flex justify-content-center">
                                <div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="civ"
                                            id="civ_mr"
                                            defaultChecked={user.civ === "Mr" ? "checked" : ''}
                                            value="Mr"
                                        />
                                        <label className="form-check-label" htmlFor="civ_mr">
                                            Mr
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="civ"
                                        id="civ_mme"
                                        defaultChecked={user.civ === "Mme" ? "checked" : ''}
                                        value="Mme"
                                    />
                                    <label className="form-check-label" htmlFor="civ_mme">
                                        Mme
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="civ"
                                        id="civ_mlle"
                                        defaultChecked={user.civ === "Mlle" ? "checked" : ''}
                                        value="Mlle"
                                    />
                                    <label className="form-check-label" htmlFor="civ_mlle">
                                        Mlle
                                    </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="civ"
                                        id="civ_dr"
                                        defaultChecked={user.civ === "Dr" ? "checked" : ''}
                                        value="Dr"
                                    />
                                    <label className="form-check-label" htmlFor="civ_dr">
                                        Dr
                                    </label>
                                </div>
                            </div>
                        </div>
                        {user.is_pro === 1 && <div className="row account-info  mb-30">
                            <div className="account-info input-style mb-30">
                                <label> RC</label>
                                {/* {console.log((sessionStorage.getItem("id") !== "null") ? sessionStorage.getItem("id") : "")} */}
                                <input
                                    defaultValue={user.rc ? user.rc : ""}
                                    type="text"
                                    placeholder="rc"
                                    name="rc"
                                />
                            </div>
                            <div className="row account-info  mb-30">
                                <label>CATÉGORIE *</label>
                                <div className="col d-flex justify-content-around">
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_clenique"
                                                defaultChecked={user.category === "clinique" ? "checked" : ''}
                                                onChange={() => { setCategory("clinique"); setnameDrgerant("") }}
                                                value="clinique"
                                            />
                                            <label className="form-check-label" htmlFor="cat_clenique">
                                                Clinique
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_medecin"
                                                defaultChecked={user.category === "medecin" ? "checked" : ''}
                                                onChange={() => { setCategory(null); setnameDrgerant("") }}
                                                value="medecin"
                                            />
                                            <label className="form-check-label" htmlFor="cat_medecin">
                                                Medecin
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_pharmacie"
                                                defaultChecked={user.category === "pharmacie" ? "checked" : ''}
                                                onChange={() => { setCategory(null); setnameDrgerant("") }}
                                                value="pharmacie"
                                            />
                                            <label className="form-check-label" htmlFor="cat_pharmacie">
                                                Pharmacie
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_laboratoire"
                                                defaultChecked={user.category === "laboratoire" ? "checked" : ''}
                                                onChange={() => { setCategory(null); setnameDrgerant("") }}
                                                value="laboratoire"
                                            />
                                            <label className="form-check-label" htmlFor="cat_laboratoire">
                                                Laboratoire
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_parapharmaacie"
                                                defaultChecked={user.category === "parapharmaacie" ? "checked" : ''}
                                                onChange={() => { setCategory(null); setnameDrgerant("") }}
                                                value="parapharmaacie"
                                            />
                                            <label className="form-check-label" htmlFor="cat_parapharmaacie">
                                                Parapharmacie
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="category"
                                                id="cat_autre"
                                                defaultChecked={user.category === "autre" ? "checked" : ''}
                                                onChange={() => { setCategory(null); setnameDrgerant("") }}
                                                value="autre"
                                            />
                                            <label className="form-check-label" htmlFor="cat_autre">
                                                Autre
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                category === "clinique" ?
                                    <div className="account-info input-style mb-30">
                                        <label> Nom de gerant </label>
                                        <input
                                            defaultValue={nameDrgerant}
                                            type="text"
                                            placeholder=" Nom de gerant"
                                            name="nameDrgerant"
                                        />
                                    </div>
                                    : null
                            }
                            <div className="account-info input-style mb-30">
                                <label> ICE </label>
                                <input
                                    defaultValue={user.ice ? user.ice : ""}
                                    type="text"
                                    placeholder="ice"
                                    name="ice"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label> RAISON SOCIAL</label>
                                <input
                                    defaultValue={
                                        user.raison_sociale ? user.raison_sociale : ""
                                    }
                                    type="text"
                                    placeholder="raison social"
                                    name="raison_sociale"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label>SIÈGE SOCIAL </label>
                                <input
                                    defaultValue={
                                        user.siege_social ? user.siege_social : ""
                                    }
                                    type="text"
                                    placeholder="siège social"
                                    name="siege_social"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label> TP</label>
                                <input
                                    defaultValue={user.TP ? user.TP : ""}
                                    type="text"
                                    placeholder="TP"
                                    name="TP"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label> Identification Fiscale</label>
                                <input
                                    defaultValue={user.IDF ? user.IDF : ""}
                                    type="text"
                                    placeholder="Identification Fiscale"
                                    name="IDF"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label> CNSS</label>
                                <input
                                    defaultValue={user.CNSS ? user.CNSS : ""}
                                    type="text"
                                    placeholder="CNSS"
                                    name="CNSS"
                                />
                            </div>
                            <div className="account-info input-style mb-30">
                                <label> PERSONNE DE CONTACT </label>
                                <input
                                    defaultValue={user.personne_contact ?
                                        user.personne_contact : ""
                                    }
                                    type="text"
                                    placeholder="personne de contact"
                                    name="personne_contact"
                                />
                            </div>
                        </div>}

                        <span>
                            NB: Changer uniquement le champ que vous avez besoin de
                            changer !
                        </span>
                        <div className="account-info-btn text-right mb-30 mt-30">
                            <button type="submit">Sauvegarder les modifications</button>
                        </div>
                    </form>
                    <form ref={pwdForm} onSubmit={updatePassword}>
                        <fieldset>
                            <legend>Changement de mot de passe</legend>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="account-info input-style mb-30">
                                        <label>
                                            Mot de passe actuel
                                        </label>
                                        <input
                                            type="password"
                                            placeholder="Mot de passe actuel"
                                            name="oldPassword"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="account-info input-style mb-30">
                                        <label>
                                            Nouveau mot de passe
                                        </label>
                                        <input
                                            type="password"
                                            placeholder="Nouveau mot de passe"
                                            name="password"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="account-info input-style">
                                        <label>Confirmer le nouveau mot de passe</label>
                                        <input
                                            type="password"
                                            placeholder="Confirmer le nouveau mot de passe"
                                            name="passwordConfirm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="account-info-btn text-right mb-30 mt-30">
                                <button type="submit">changer le mot de passe</button>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
            :
            <ClipLoader color={"#fffff"} loading={loading} />
    )
}


export default Account;