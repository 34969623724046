import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Swal from "sweetalert2";
import AuthService from "../../../../services/auth.service";
import CompareService from "../../../../services/compare.service";
import DevisService from "../../../../services/devis.service";
import ProductsService from "../../../../services/product.service";
import WLService from "../../../../services/wishList.service";
import "./dayliStyle.css";

const DayliDeal = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ProductsService.promoDeJour().then((res) => {
      setProducts(res.data.results[0]);
      setLoading(false);
    });
    return () => {
      setProducts([]);
    };
  }, []);
  return (
    <div className="product-area pt-80 pb-75">
      <div className="custom-container">
        <div className="row bg-color">
          <div className="col-12 text-center">
            <div className="title">
              <h2>Offre quotidienne du jour</h2>
              <h4>
                Expire dans :{"   "} <CountDown className="count" />
              </h4>
            </div>
          </div>
          <div className="col-12">
            <div className="m-3">
              {products.length > 0 ? <CustomSlider products={products} /> : <></>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomSlider = ({ products }) => {
  const id_user = sessionStorage.getItem("id");
  const addToWL = (id) => {
    if (id_user) {
      const data = {
        id_product: id,
        id_client: id_user,
      };
      WLService.addProductToWl(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };
  const addToCart = (id) => {
    if (id_user) {
      const data = {
        id_produit: id,
        id_user: id_user,
      };
      DevisService.addProductToDevis(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch((err) => NotificationManager.error(`${err}`));
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };
  const addToCompare = (id) => {
    if (CompareService.isItemInList(id)) {
      Swal.fire("ce produit est deja dans la liste");
    } else if (CompareService.getListCompares().length < 3) {
      CompareService.addCompare(id);
      NotificationManager.success("le produit est met en campare list");
    } else {
      Swal.fire("Vous avez deja 3 produit dans la liste");
    }
  };
  const slidesToShow = products.length > 5 ? 5 : products.length;
  var settings = {
    // dots: true,
    infinite: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // pauseOnHover: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: slidesToShow > 3 ? 3 : slidesToShow,
          slidesToScroll: slidesToShow > 3 ? 3 : slidesToShow,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: slidesToShow > 2 ? 2 : slidesToShow,
          slidesToScroll: slidesToShow > 2 ? 2 : slidesToShow,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {products.map((product, i) => {
        return (
        <div className="one-prod" key={i}>
          <div className="single-product-wrap">
            <div className="product-badges product-badges-mrg">
              <span className="discount red m-2">- {product.pourcent} %</span>
            </div>
            <div
              className="product-content-wrap text-center"
              style={{ height: "50px" }}
            >
              <h2 style={{ fontWeight: "500" }}>
                <Link to={`/details-produit/${product.name}`}>
                  {product.name}
                </Link>
              </h2>
              <div className="product-price"></div>
            </div>
            <div className="product-img-action-wrap text-center mb-20 mt-25">
              <div
                className="product-img product-img-zoom"
                style={{ height: "200px" }}
              >
                <Link to={`/details-produit/${product.name}`}>
                  <img
                    // height="100px"
                    // width="auto"
                    className="default-img"
                    src={
                      window.IMAGE_API_URL +
                      "products/" +
                      product.image.split(";")[0]
                    }
                    style={{
                      // width: "auto",
                      width: "100%" /* or any custom size */,
                      height: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </Link>
              </div>
              <div className="text-center mt-3">
                <button
                  className="btn-icon m-1"
                  aria-label="Demander un devis"
                  onClick={() => addToCart(product.id)}
                >
                  <i className="far fa-file-invoice" />
                </button>
                <button
                  className="btn-icon m-1"
                  aria-label="ajouter à la list des envies"
                  onClick={() => addToWL(product.id)}
                >
                  <i className="far fa-heart" />
                </button>
                <button
                  className="btn-icon m-1"
                  aria-label="Comparer"
                  onClick={() => addToCompare(product.id)}
                >
                  <i className="far fa-signal" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )})}
    </Slider>
  );
};

const ArrowNext = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: "absolute",
        fontSize: "30px",
        display: "block",
        right: "-3%",
        top: "48%",
        zIndex: "15",
        height: "40px",
        width: "40px",
        opacity: "1",
        color: "#163C74",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <i className="fas fa-chevron-right" />
    </div>
  );
};

const ArrowPrev = (props) => {
  const { onClick } = props;
  return (
    <div
      style={{
        position: "absolute",
        fontSize: "30px",
        display: "block",
        left: "-1%",
        top: "48%",
        zIndex: "15",
        height: "40px",
        width: "40px",
        opacity: "1",
        color: "#163C74",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      {" "}
      <i className="fas fa-chevron-left" />
    </div>
  );
};

const CountDown = () => {
  const [countDown, setCountDown] = useState("00:00:00");
  useEffect(() => {
    return () => {
      setCountDown("");
      clearTimeout(timeId);
    };
  }, []);
  let timeId = setInterval(function time() {
    var d = new Date();
    var hours = 23 - d.getHours();
    var min = 60 - d.getMinutes();
    if ((min + "").length == 1) {
      min = "0" + min;
    }
    var sec = 60 - d.getSeconds();
    if ((sec + "").length == 1) {
      sec = "0" + sec;
    }
    setCountDown(hours + " : " + min + " : " + sec);
  }, 1000);

  return countDown;
};

export default DayliDeal;