import { useEffect, useState } from "react";
import Rating from "react-rating";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import BlogService from "../../../services/blogs.service";
import WhichPage from "../../Init/header/WhichPage";
import ShareOnFacebook from "../mediashare/ShareOnFacebook";
import ShareOnLinkedin from "../mediashare/ShareOnLinkedin";
import ShareOnTwitter from "../mediashare/ShareOnTwitter";
import MenuArea from "./MenuArea";
import NotificationManager from "react-notifications/lib/NotificationManager";
import moment from "moment";
import DOMPurify from "dompurify";
import AuthService from "../../../services/auth.service";
function compare(a, b) {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
}

const Comments = function (props) {
  const [rate, setRate] = useState(0);
  const [comment, setComment] = useState("");
  const handelSubmit = (e) => {
    e.preventDefault();
    if (rate > 0) {
      BlogService.addRate(props.id_blog, rate)
        .then(() => {
          NotificationManager.success("l'avis a été ajouté");
        })
        .catch((err) => NotificationManager.error(`${err}`));
    }
    if (comment)
      BlogService.addComment(props.id_blog, comment)
        .then(() => {
          BlogService.blogComments(props.id_blog).then((res) => {
            setComments(res.data.results);
          });
          NotificationManager.success("le commentaire a été ajouté");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
  };
  const [comments, setComments] = useState([]);
  useEffect(() => {
    BlogService.blogComments(props.id_blog).then((res) => {
      setComments(res.data.results.sort(compare));
      console.log(res.data);
    });
  }, []);

  return (
    <>
      <div className="blog-details-top-content">
        {comments.map((comment, i) => (
          <div key={i} className="blog-meta-3">
            <ul>
              <li>
                <h5>
                  <span>
                    {comment.user_id ? (
                      <i className="far fa-user">
                        {"  "}
                        {comment.username}
                      </i>
                    ) : comment.adminname ? (
                      <i className="fas fa-crown">
                        {" "}
                        {`${comment.adminname} ${comment.adminsurname}`}
                      </i>
                    ) : (
                      "Unkon"
                    )}
                    {"  "}
                  </span>
                </h5>
              </li>

              <li>
                <Rating
                  initialRating={comment.rate}
                  readonly
                  emptySymbol="far fa-star "
                  fullSymbol="fas fa-star "
                />
                {"   "}
              </li>
            </ul>
            <ul className="ml-3">
              <li>
                <i className="far fa-calendar" />
                {moment(comment.created_at).fromNow()}
              </li>
              <li>
                <p>
                  <i className="far fa-comment-alt-lines" />
                  {comment.content}
                </p>
              </li>
            </ul>
          </div>
        ))}
      </div>
      {sessionStorage.getItem("token") ? (
        <div className="blog-reply-wrapper">
          <h3>Laissez votre pensée ici </h3>
          <form action="#" onSubmit={handelSubmit}>
            <h4>
              <i className="p-4 far fa-user"></i>
              {sessionStorage.getItem("name")}{" "}
              {sessionStorage.getItem("surname")}
            </h4>

            <Rating
              className="ml-5 mb-4"
              initialRating={rate}
              // quiet="true"
              emptySymbol="far fa-star "
              fullSymbol="fas fa-star "
              fractions={2}
              onClick={(value) => {
                setRate(value);
              }}
            />
            <div className="row">
              <div className="col-12">
                <div className="textarea-style blog-textarea-style">
                  <textarea
                    placeholder="Votre commentaire"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="blog-comment-submit mt-3 text-right">
              <input type="submit" value="Soumettre pensée" />
            </div>
          </form>
        </div>
      ) : (
        <div className="text-center">
          <Link
            to="/login-register"
            className="btn"
            style={{
              color: "#fff",
              background: "#163c74",
              fontWeight: "600",
              height: "48px",
              borderRadius: "26px",
            }}
          >
            Connectez-vous pour réagir à notre article!
          </Link>
        </div>
      )}
    </>
  );
};

const BlogDetails = (props) => {
  const [blog, setBlog] = useState("");
  const idBlog = useParams().id;

  const urlToShare = useLocation().pathname;

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  useEffect(() => {
    BlogService.blogById(idBlog).then((res) => {
      setBlog(res.data.result[0]);
      console.log(res.data);
    });
  }, []);

  return (
    <>
      <WhichPage page=" Blog / Details" />
      <div className="blog-details-area padding-30-row-col pt-75 pb-75">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-8">
              <div className="blog-details-wrapper">
                <div className="blog-details-top-content">
                  <div className="post-categories">
                    <Link to={`/blog/${blog.id_categorieBlg}`}>
                      {blog.category_blog}
                    </Link>
                  </div>
                  <h1>{blog.titre}</h1>
                  <div className="blog-meta-3">
                    <ul>
                      <li>
                        <Link to="#">
                          <img
                            src={
                              window.IMAGE_API_URL + "avatar/" + blog.imagereda
                            }
                            alt=""
                            style={{ width: "50px", height: "50px" }}
                          />
                          {blog.redacteur}
                        </Link>
                      </li>
                      <li>
                        <i className="far fa-calendar" />
                        {moment(blog.created_at).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        )}
                      </li>
                      <li>
                        <Rating
                          initialRating={blog.rates || 0}
                          readonly
                          emptySymbol="far fa-star "
                          fullSymbol="fas fa-star "
                        />
                        {"   "}
                      </li>
                      <li>
                        <a href="#comments">
                          <i className="far fa-comment-alt-lines" /> 0 comments
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  src={window.IMAGE_API_URL + "blogs/" + blog.image}
                  alt=""
                />
                <blockquote>
                  <p>{blog.description}</p>
                </blockquote>
                {/* <div className="blog-details-middle-img">
                  <img
                    src="/assets/images/blog/blog-details-middle.jpg"
                    alt=""
                  />
                </div> */}

                <div className="blog-details-column">
                  <div
                    dangerouslySetInnerHTML={createMarkup(blog.contenue)}
                  ></div>
                </div>
                <div className="blog-tag-share-wrap">
                  <div className="blog-tag-wrap">
                    <span className="tag-icon fal fa-tags" />
                    <div className="blog-tag">
                      <Link to={`/blog/${blog.id_categorieBlg}`}>
                        {blog.category_blog}
                      </Link>
                    </div>
                  </div>
                  <div className="blog-share-wrap">
                    <div className="blog-share-content">
                      <span> Partager cette publication</span>
                    </div>
                    <div className="blog-share-icon">
                      <span className="far fa-share-alt" />
                      <div className="blog-share-list d-flex justify-content-between">
                        <Link to="#">
                          <ShareOnFacebook
                            desc={blog.description}
                            urlToShare={urlToShare}
                          />
                        </Link>
                        <Link to="#">
                          <ShareOnTwitter urlToShare={urlToShare} />
                        </Link>
                        <Link to="#">
                          <ShareOnLinkedin urlToShare={urlToShare} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="blog-author-wrap mb-5">
                  <div className="blog-author-img">
                    <img
                      src={window.IMAGE_API_URL + "avatar/" + blog.imagereda}
                      alt=""
                      style={{ width: "50px", height: "50px" }}
                    />
                    <div className="blog-author-social tooltip-style-4">
                      <Link aria-label="Twitter" to="#">
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link aria-label="Facebook" to="#">
                        <i className="fab fa-facebook-f" />
                      </Link>
                      <Link aria-label="Linkedin" to="#">
                        <i className="fab fa-linkedin" />
                      </Link>
                    </div>
                  </div>
                  <div className="blog-author-content">
                    <h4 className="mt-4">{blog.redacteur}</h4>
                  </div>
                </div>

                <div id="comments" className="blog-reply-wrapper mt-3">
                  {blog.id && <Comments id_blog={blog.id} />}
                </div>
              </div>
            </div>
            <MenuArea />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;

// const Rating = () => {
//   const rated = "fas fa-star";
//   const unRated = "far fa-star";

//   const [style, setstyle] = useState(rated);
//   const toggler = (e) => {
//     if (e.target.className === rated) {
//       setstyle(unRated);
//     } else {
//       setstyle(rated);
//     }
//   };

//   return (
//     <div className="d-flex p-4">
//       <span>Votre note: </span>

//       <div className="ml-4">
//         <i className={style} onClick={toggler} />
//         <i className={style} onClick={toggler} />
//         <i className={style} onClick={toggler} />
//         <i className={style} onClick={toggler} />
//         <i className={style} onClick={toggler} />
//       </div>
//     </div>
//   );
// };
