import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CategorieService from "../../../services/categories.service";

const CategorieProductArea = () => {
  const [categories, setCategories] = useState([]);

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: categories.length > 5 ? 5 : categories.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: categories.length > 3 ? 3 : categories.length,
          slidesToScroll: categories.length > 3 ? 3 : categories.length,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: categories.length > 2 ? 2 : categories.length,
          slidesToScroll: categories.length > 2 ? 2 : categories.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    CategorieService.allCategories().then((res) => {
      setCategories(res.data.results);
    });
  }, []);
  return (
    <div className="categories-area pb-70">
      <div className="custom-container">
        <div className="section-title-1 mb-40">
          <h2>PAR CATEGORIES</h2>
        </div>

        <Slider {...settings} className="product-slider-active-1">
          {categories.map((category, index) => (
            <div key={index} className="product-plr-1">
              <div className="single-product-wrap">
                <div className="product-img-action-wrap categories-wrap mb-20">
                  <div className="categories-img categories-img-zoom">
                    <Link to={`boutique/${category.name.replaceAll("/", "_")}`}>
                      <img
                        height="180px"
                        width="177"
                        src={`${window.IMAGE_API_URL}category_product/${category.image}`}
                        alt=""
                      />
                    </Link>
                  </div>
                  <div className="categories-content text-center">
                    <h3 style={{ height: "50px" }}>
                      <Link to={`boutique/${category.name}`}>
                        {category.name}
                      </Link>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-1.jpg" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Hospital Equipment</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-3.jpg" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Accessoires</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-4.jpg" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Personnel</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-6.jpg" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Pharmacie</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-5.jpg" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Vie autonome</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>

          <div className="product-plr-1">
            <div className="single-product-wrap">
              <div className="product-img-action-wrap categories-wrap mb-20">
                <div className="categories-img categories-img-zoom">
                  <Link to="product-details.html">
                    <img src="assets/images/product/categorie-2.png" alt="" />
                  </Link>
                </div>
                <div className="categories-content text-center">
                  <h3>
                    <a href="shop.html">Pression artérielle</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
         */}
        </Slider>
      </div>
    </div>
  );
};

export default CategorieProductArea;
