import React from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import CompareService from "../../../services/compare.service";
import DevisService from "../../../services/devis.service";
import WLService from "../../../services/wishList.service";

const SingleProduct = (props) => {
  const product = props.product;
  const productImages = product.image.split(";");

  const id_user = sessionStorage.getItem("id");

  const addToCompare = () => {
    if (CompareService.isItemInList(product.id)) {
      Swal.fire("ce produit est deja la liste");
    } else if (CompareService.getListCompares().length < 3) {
      CompareService.addCompare(product.id);
      NotificationManager.success("Le produit a été placé dans la list");
    } else {
      Swal.fire("Vous avez déjà 3 produits dans la liste");
    }
  };

  const addToWL = () => {
    if (id_user) {
      const data = {
        id_product: product.id,
        id_client: id_user,
      };
      WLService.addProductToWl(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };

  const addToCart = () => {
    if (id_user) {
      const data = {
        id_produit: product.id,
        id_user: id_user,
      };
      DevisService.addProductToDevis(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            // console.log("YOooooo");
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-4 col-12 col-sm-6 wow tmFadeInUp">
      <div className="single-product-wrap mb-50">
        <div className="product-img-action-wrap mb-10">
          <div
            className="product-img product-img-zoom"
            style={{
              height: "215px",
            }}
          >
            <Link to={`/${props.BaseUrl || 'details-produit'}/${product.name}`}>
              <img
                width="auto"
                className="default-img"
                src={window.IMAGE_API_URL + `products/${productImages[0]}`}
                alt=""
                style={{
                  // width: "auto",
                  width: "100%" ,
                  height: "100%",
                  objectFit: "contain",
                }}
              /> 
              {productImages[1] && (
                <img
                  height="215px"
                  className="hover-img"
                  src={window.IMAGE_API_URL + `products/${productImages[1]}`}
                  alt=""
                  style={{
                    // width: "auto",
                    width: "100%" /* or any custom size */,
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Link>
          </div>
          <div className="product-action-1">
            <button aria-label="Demander un devis" onClick={addToCart}>
              <i className="far fa-shopping-bag" />
            </button>
            <button aria-label="ajouter à Ma liste d'envies" onClick={addToWL}>
              <i className="far fa-heart" />
            </button>
            <button aria-label="Comparer" onClick={addToCompare}>
              <i className="far fa-signal" />
            </button>
          </div>
          <div className="product-badges product-badges-position product-badges-mrg">
            {/* {product.count > 5 && <span className=" red">Sale !</span>} */}
          </div>
        </div>
        <div className="product-content-wrap">
          <div className="product-category">
            <Link
              to={`/details-produit/${product.name}`}
              style={{ wordBreak: "break-all" }}
            >
              {`${product.category || ''} ${(product.category && product.sub_category) ? '/' : ''} ${product.sub_category || ''}`}
            </Link>
          </div>
          <h2>
            <Link to={`/details-produit/${product.name}`}>{product.name}</Link>
          </h2>
          <div style={{display : 'flex', width : '100%', flexDirection : 'column'}}>
            <div className="product-price">
              <span style={{ wordBreak: "break-all"}} className="new-price">
                {
                  product.tarif > 0 ?
                  (
                  product?.offer_pourcentage ?    
                  `${parseFloat(parseFloat(product.tarif) - ((parseFloat(product.tarif) * parseInt(product?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                  `${parseFloat(product.tarif).toFixed(2)} DHS TTC`
                  ) :
                  'demande de prix'
                }
              </span>
              {
              product?.offer_pourcentage ?
              <span style={{
                color: 'rgb(168, 160, 149)',
                textDecorationColor: 'initial',
                textDecorationLine: 'line-through',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                marginLeft : 5
              }}>{parseFloat(product.tarif).toFixed(2)}</span> :
              <></>
              }
            </div>
            {
              product?.offer_pourcentage ?
              <div style={{
                margin: "0 0 10px",
                display : 'flex',
                justifyContent : 'center'
              }}>
                <span style={{
                  marginRight : 3,
                  backgroundColor: '#e4573d',
                  display: 'inline-block',
                  fontSize: '13px',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  lineHeight: 1,
                  borderRadius: '2px',
                  color: '#ffffff',
                  marginRight: '10px',
                  padding: '5px 8px 6px'
                }}> {`SALE -${product?.offer_pourcentage}%`}</span>
              </div> :<></>
            }
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
