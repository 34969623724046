import { Link, NavLink } from "react-router-dom";
import AuthService from "../../../services/authentication";
import CategorieMenuForBoutiqueLI from "./CategorieMenuForBoutiqueLI";
import DropdownQuotationMenu from "./DropdownQuotationMenu";

const Navbar = () => {
  const logedIn = AuthService.getCurrentUser().token ? true : false;

  return (
    <>
      <header className="header-area header-height-4">
        <div className="header-top header-top-ptb-1 bg-gray d-none d-lg-block">
          <div className="custom-container">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="header-info">
                  <ul>
                    <li>
                    {/* <p style={{ display: "flex", justifyContent: "row",margin:"0px" }}>
                              {"Casablanca : +212 520-333012"}
                            </p>  
                            <p style={{ display: "flex", justifyContent: "row" }}>
                              {"Oujda : +212 520-333011"}
                            </p> */}
                      {/* <Link to="#">(+212) - 612 -331 -234</Link> */}
                      {/* <Link to="#"> */}
                        <div style={{display : 'flex', flexDirection : 'row'}}>
                          <div style={{display : 'flex', flexDirection : 'column', marginRight : 5}}>
                            <div>
                              <p style={{ display: "flex", justifyContent: "row",margin:"0px" }}>
                                {"Casablanca"}
                              </p>  
                            </div>
                            <div>
                              <p style={{ display: "flex", justifyContent: "row" }}>
                                {"Oujda"}
                              </p>
                            </div>
                          </div>
                          <div style={{display : 'flex', flexDirection : 'column'}}>
                            <div style={{display : 'flex'}}>
                              :
                                <b style={{ display: "flex", justifyContent: "row",margin:"0px" , paddingLeft : 5}}>
                                  {"+212 520-333012"}
                                </b>  
                            </div>
                            <div style={{display : 'flex'}}>
                              :
                              <b style={{display: "flex", justifyContent: "row", paddingLeft : 5}}>
                                {"+212 520-333011"}
                              </b>
                            </div>
                          </div>
                        </div>
                      {/* </Link> */}
                    </li>
                    <li style={{ display: "flex"}}>
                        {'Emplacement magasin :'}
                        <a
                          target="_blank"
                          href="https://www.google.com/maps/place/Healthcare+Facilities+Consult+Casa/@33.5445299,-7.6775986,15z/data=!4m5!3m4!1s0x0:0xc7a1ec4ad7fa9e3f!8m2!3d33.5445299!4d-7.6775986"
                          style={{marginLeft : 3,marginRight : 3}}
                        >
                          <b>{'Casablanca'}</b>
                        </a>
                        <a
                          target="_blank"
                          href="https://goo.gl/maps/Et9LkPsnxBU2vPb16"
                        >
                          <b>{" "}
                          {'/ Oujda'}</b>
                        </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-6 justify-content-end">
                <div className="covid-update text-center">
                  <p>
                    <NavLink to="/boutiqueCovid">COVID-19</NavLink> Tous les
                    produits associés
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom sticky-bar sticky-white-bg">
          <div className="custom-container">
            <div className="header-wrap header-space-between position-relative">
              <div className="logo logo-width-1">
                <a href="/accueil">
                  <img src="/assets/images/logo/logo.png" alt="logo" />
                </a>
              </div>
              <div className="main-menu main-menu-padding-1 main-menu-lh-3 d-none d-lg-block hover-boder hover-boder-modify">
                <nav>
                  <ul>
                    <li>
                      <NavLink to="/accueil">Accueil</NavLink>
                    </li>
                    <li>
                      <NavLink to="/apropos">À propos</NavLink>
                    </li>
                    <li>
                      <NavLink to="/boutique">Boutique</NavLink>
                      <CategorieMenuForBoutiqueLI />
                      {/*  <li><Link to="#">DIAGNOSTIC <i className="fa fa-chevron-right" /></Link>
                          <ul className="level-menu">
                            <li><Link to="product-details.html">List – Left Sidebar <i className="fa fa-chevron-right" /></Link>
                              <ul className="level-menu level-menu-modify">
                                <li><Link to="blog-details.html">Left Sidebar</Link></li>
                                <li><Link to="blog-details-right-sidebar.html">Right Sidebar</Link></li>
                                <li><Link to="blog-details-no-sidebar.html">No Sidebar</Link></li>
                              </ul>
                            </li>
                            <li><Link to="product-details-right-sidebar.html">ORTHOPEDIE FAUTEUILS ROULANTS</Link></li>
                            <li><Link to="product-details-no-sidebar.html">List – No Sidebar</Link></li>
                            <li><Link to="product-details-tab-left-sidebar.html">Tabs – Left Sidebar</Link></li>
                            <li><Link to="product-details-tab-right-sidebar.html">Tabs – Right Sidebar</Link></li>
                            <li><Link to="product-details-tab-no-sidebar.html">Tabs – No Sidebar</Link></li>
                          </ul>
                        </li> */}
                    </li>
                    <li>
                      <NavLink to="/blog">Blog</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact">Contact </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-action-right">
                <div className="header-action">
                  <div className="header-action-icon">
                    <Link className="search-active" to="#">
                      <i className="far fa-search" />
                    </Link>
                  </div>
                  {logedIn ? (
                    <div className="d-none d-md-flex">
                      <div className="header-action-icon header-action-mrg-none">
                        <div className="header-action-icon">
                          <Link to="/wishlist">
                            <i className="far fa-heart" />
                          </Link>
                        </div>
                        <div className="header-action-icon">
                          <Link to="/panier">
                            <i className="far fa-shopping-cart" />
                          </Link>
                        </div>
                        <div className="header-action-icon">
                          <Link to="/devis">
                            <i className="far fa-file-invoice" />
                          </Link>
                        </div>
                      </div>
                      <div className="header-action-icon">&nbsp;|</div>
                      <div className="header-action-icon">
                        <Link to="/Profil">
                          <i className="far fa-user" />
                        </Link>
                      </div>
                      <div className="header-action-icon">
                        <a href="/accueil" onClick={AuthService.logout}>
                          <i className="fas fa-sign-out-alt" />
                        </a>
                      </div>
                    </div>
                  ) : (
                    <div className="d-none d-md-flex">
                      <div className="d-none d-md-block header-action-icon">
                        <Link to="/login-register">
                          <i className="far fa-heart" />
                        </Link>
                      </div>
                      <div className="d-none d-md-block header-action-icon">
                        <Link to="/login-register">
                          <i className="far fa-shopping-cart" />
                        </Link>
                      </div>
                      <div className="d-none d-md-block header-action-icon">
                        <Link to="/login-register">
                          <i className="far fa-file-invoice" />
                        </Link>
                      </div>
                      <div className="d-none d-md-block sign-link">
                        <Link to="/login-register">
                          Se connecter / S'inscrire
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className="header-action-icon d-block d-lg-none">
                    <div className="burger-icon">
                      <span className="burger-icon-top" />
                      <span className="burger-icon-bottom" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Navbar;
