import { useRef } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import UserService from "../../../services/user.service";

const AskUs = () => {
  const form = useRef(null);

  const handelSubmit = (e) => {
    const fdToJson = (fd) => {
      let obj = {};
      for (let key of fd.keys()) {
        obj[key] = fd.get(key);
      }
      return obj;
    };
    e.preventDefault();
    UserService.contacter(fdToJson(new FormData(form.current)))
      .then((res) =>
        NotificationManager.success(
          "votre message a été bien enregistre",
          "",
          2000
        )
      )
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          console.log("YOooooo");
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "could not add product to wishlist",
                "",
                2000
              );
        }
      });
  };
  return (
    <div className="contact-us-area contact-us-bg pt-75 pb-75">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-width-58 custom-common-column">
            <div className="contact-from-area contact-from-area-bg padding-20-row-col wow tmFadeInUp">
              <h3>
                N'hesitez pas à nous contacter :<br></br> contact@hfc.ma
              </h3>
              <form
                ref={form}
                onSubmit={handelSubmit}
                className="contact-form-style text-center"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <input
                        name="first_name"
                        required
                        placeholder="Nom"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <input
                        name="email"
                        required
                        placeholder="Email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <input name="phone" placeholder="TéléPhone" type="text" />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="input-style mb-20">
                      <input
                        name="subject"
                        required
                        placeholder="sujet"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="textarea-style mb-30">
                      <textarea
                        required
                        name="message"
                        placeholder="Message"
                        defaultValue={""}
                      />
                    </div>
                    <button className="submit submit-auto-width" type="submit">
                      Envoyer
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-messege" />
            </div>
          </div>
          <div className="col-width-41 custom-common-column">
            <div className="contact-info-wrap">
              <div className="single-contact-info2-wrap wow tmFadeInUp">
                <div className="single-contact-info2-icon">
                  <i className="fal fa-phone" />
                </div>
                <div className="single-contact-info2-content">
                  <h3>Appelez à l'aide maintenant!</h3>
                  <br></br>
                  <p>
                    Casablanca : +212 520 333 012 <br></br> Oujda : +212 520 333
                    011
                  </p>
                </div>
              </div>
              <div className="single-contact-info2-wrap wow tmFadeInUp">
                <div className="single-contact-info2-icon">
                  <i className="fal fa-envelope" />
                </div>
                <div className="single-contact-info2-content">
                  <h3>Envoyez-nous un email</h3>
                  <br></br>
                  <p>contact@hfc.ma</p>
                </div>
              </div>
              <div className="single-contact-info2-wrap wow tmFadeInUp">
                <div className="single-contact-info2-icon">
                  <i className="fal fa-map-marked-alt" />
                </div>
                <div className="single-contact-info2-content">
                  <h3>Adresses</h3>
                  <br></br>
                  <p>
                    Casablanca : 504, Lotissement Hadj Fatah 20260 - El Oulfa
                    <br></br>
                    Oujda : 32, Lotissement Benmoussa - Quartier du CHU 60000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskUs;
