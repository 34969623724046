/* eslint-disable no-unused-vars */
import WhichPage from "../../Init/header/WhichPage";
import { useLocation, useHistory} from "react-router";
import { useEffect, useState } from "react";
import CategorieService from "../../../services/categories.service";
import SubCategorieService from "../../../services/subCategorie.service";
import { useParams } from "react-router-dom";
import ProductsService from "../../../services/product.service";
import SingleProduct from "../boutique/SingleProduct";
import BrandsService from "../../../services/brands.service";
import FamiliesService from "../../../services/families.service";
import WLService from "../../../services/wishList.service";
import { DotLoader } from "react-spinners";
import ReactPaginate from "react-paginate";
import "../boutique/styles.css";
import { alertTitleClasses } from "@mui/material";

const OFFSET = 24;
function compare(a, b) {
  if (a.id > b.id) {
    return -1;
  }
  if (a.id < b.id) {
    return 1;
  }
  return 0;
}
const Search = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [families, setFamilies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [wl, setWl] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const SearchQuery = params.search;

  //paginattion
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const loadOnPageChange = (p) => {
    setCurrentPage(p);
    const data = { items: OFFSET, page: p + 1, search : SearchQuery };
    ProductsService.productsByPageSearch(data)
      .then((res) => {
        setProducts(res.data.results[0].sort(compare));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const location = useLocation();
  const history = useHistory();
  const catOrBrand = useParams().cat;
  const subCatName = useParams().subcat;
  const familyName = useParams().family;

  useEffect(() => {
    // console.log(catOrBrand);
    if (!SearchQuery || SearchQuery.trim() === '')
      return history.push('/boutique');
    BrandsService.allBrands().then((res) => {
      setBrands(res.data.results);
    });
    CategorieService.allCategories().then((res) => {
      setCategories(res.data.results);
    });
    SubCategorieService.allSubCategories().then((res) => {
      setSubCategories(res.data.results);
    });
    FamiliesService.allFamilies().then((res) => {
      setFamilies(res.data.results);
      // console.log(res.data);
    });
    if (sessionStorage.getItem("id")) {
      WLService.getTheWL().then((res) => {
        setWl(res.data.results);
      });
    }

    if (catOrBrand && catOrBrand.split(":")[0] === "m") {
      ProductsService.productsByBrandName(catOrBrand.split(":")[1])
        .then((res) => {
          setProducts(res.data.results);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (familyName) {
      ProductsService.productsByFamilyName(familyName)
        .then((res) => {
          setProducts(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (subCatName) {
      ProductsService.productsBySubCatName(subCatName)
        .then((res) => {
          console.log(res.data);

          setProducts(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else if (catOrBrand && catOrBrand.split(":")[0] !== "m") {
      console.log("1", catOrBrand);
      console.log("2", catOrBrand.split(":")[0] !== "m");
      ProductsService.productsByCatName(catOrBrand.replaceAll("_", "/"))
        .then((res) => {
          console.log("3", res.data);

          setProducts(res.data.results);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      ProductsService.totalProductNumber().then((res) => {
        setPageCount(Math.floor(res.data.results.nbr / OFFSET) + 1);
      });
      const data = { items: OFFSET, page: currentPage + 1, search : SearchQuery};
      ProductsService.productsByPageSearch(data)
        .then((res) => {
          setProducts(res.data.results[0].sort(compare));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [catOrBrand, familyName, params, subCatName]);

  return (
    <div className="main-wrapper">
      <WhichPage page={location.pathname.slice(1)} />
      <div className="shop-area pt-75 pb-55">
        <div className="custom-container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9">
              <div className="shop-topbar-wrapper">
                <div className="totall-product">
                  <p>
                    Nous avons trouvé <span>{products.length}</span> produit(s)
                    disponibles pour vous
                  </p>
                </div>
              </div>
              <div className="shop-bottom-area">
                <div className="row text-center">
                  {loading ? (
                    <div className="text-center m-5">
                      <DotLoader
                        color={"#163C74"}
                        loading={loading}
                        size={50}
                      />
                    </div>
                  ) : (
                    products.map((product, i) => (
                      <SingleProduct key={i} product={product} />
                    ))
                  )}
                </div>

                {/* {pageCount > 1 && (
                  <div className="pro-pagination-style text-center mt-55">
                    <ReactPaginate
                      initialPage={0}
                      previousLabel={<i className="far fa-angle-double-left" />}
                      nextLabel={<i className="far fa-angle-double-right" />}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={(e) => {
                        loadOnPageChange(e.selected);
                      }}
                      // onPageActive={(e) => console.log("onPageActive", e)}
                      activeClassName={"activePage"}
                    />
                  </div>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
