import { useEffect, useState } from "react";

export const ProfessionalInfo = () => {
  const [styleInf, setStyleInf] = useState("pro-inf");

  function showProInfo(e) {
    if (e.target.checked) {
      setStyleInf("is-visible is-open");
    } else {
      setStyleInf("pro-inf");
    }
  }
  useEffect(() => {}, [styleInf]);

  return (
    <>
      <div className="form-check">
        <input
          id="proInfo"
          className="form-check-input"
          type="checkbox"
          name="isPro"
          value="1"
          onClick={(e) => showProInfo(e)}
        />
        <label htmlFor="proInfo">professionnel </label>
      </div>
      <div className={styleInf}>
        <div className="login-register-input-style input-style">
          <label> RC</label>
          <input type="text" placeholder="rc" name="rc" />
        </div>
        <div className="login-register-input-style input-style">
          <label> CATÉGORIE *</label>
          <input type="text" placeholder="clinique" name="category" />
        </div>
        <div className="login-register-input-style input-style">
          <label> ICE </label>
          <input type="text" placeholder="ice" name="ice" />
        </div>
        <div className="login-register-input-style input-style">
          <label> RAISON SOCIAL</label>
          <input
            type="text"
            placeholder="raison social"
            name="raison_sociale"
          />
        </div>
        <div className="login-register-input-style input-style">
          <label>SIÈGE SOCIAL </label>
          <input type="text" placeholder="siège social" name="siege_social" />
        </div>
        <div className="login-register-input-style input-style">
          <label> TP</label>
          <input type="text" placeholder="TP" name="TP" />
        </div>
        <div className="login-register-input-style input-style">
          <label> Identification Fiscale</label>
          <input type="text" placeholder="if" name="IDF" />
        </div>
        <div className="login-register-input-style input-style">
          <label> CNSS</label>
          <input type="text" placeholder="cnss" name="CNSS" />
        </div>
        <div className="login-register-input-style input-style">
          <label> PERSONNE DE CONTACT </label>
          <input
            type="text"
            placeholder="personne de contact"
            name="personne_contact"
          />
        </div>
      </div>
    </>
  );
};
