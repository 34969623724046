import React, {useEffect} from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import MobileHeader from "./Components/Init/header/MobileHeader";
import Navbar from "./Components/Init/nav/Navbar";
import Boutique from "./Components/Pages/boutique/Boutique";
import Contact from "./Components/Pages/contact/Contact";
import Home from "./Components/Pages/home/Home";
import Profile from "./Components/Pages/profil/Profil";
import Facture from "./Components/Pages/profil/Facture";
import Footer from "./Components/Init/footer/Footer";
import SideMenu from "./Components/Init/nav/SideMenu";
import SearchComponent from "./Components/Init/header/SearchComponent";
import About from "./Components/Pages/about/About";
import Blog from "./Components/Pages/blog/Blog";
import Login from "./Components/Pages/login/Login";
import WishList from "./Components/Pages/whishlist/WhishList";
import NotFound from "./Components/Pages/errors/404";
import Compare from "./Components/Pages/compare/Compare";
import ProductDetails from "./Components/Pages/details/ProductDetails";
import BlogDetails from "./Components/Pages/details/BlogDetails";
import Quotations from "./Components/Pages/quotations/Quotations";
import QuotationForm from "./Components/Pages/quotationform/QuotationForm";
import Complaints from "./Components/Pages/complaints/Complaints";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import ScrollToTop from "./Components/Init/ScrollToTop";
import BoutiqueCovid from "./Components/Pages/boutique/BoutiqueCovid";
import BoutiquePlusEtonnant from "./Components/Pages/boutique/BoutiquePlusEtonnant";
import BoutiqueOfferCombinees from "./Components/Pages/boutique/BoutiqueOfferCombinees";
import NewArrivalDetails from "./Components/Pages/details/NewArrivalDetails";
import AmazingDetails from "./Components/Pages/details/AmazingProducts";
import Invoice from "./Components/Pages/invoce/Invoice";
import GeneralCdt from "./Components/Pages/conditons/GeneralCdt";
import Panier from './Components/Pages/panier'
import Search from './Components/Pages/search'
import axios from 'axios';
import AchatImediat from './Components/Pages/achat_imediat';

function App() {
  useEffect(() => {
    if (!sessionStorage.getItem('Viewer')) {
      axios.patch(`${window.API_URL}user/views`, {}, {}).then(res => sessionStorage.setItem('Viewer', 'viewer')).catch(err => false);
    }
    if (localStorage.getItem('token') && !sessionStorage.getItem('token')) {
      sessionStorage.setItem('user', localStorage.getItem("user"));
      sessionStorage.setItem('name', localStorage.getItem("name"));
      sessionStorage.setItem('surname',localStorage.getItem("surname"));
      sessionStorage.setItem('email', localStorage.getItem("email"));
      sessionStorage.setItem('avatar', localStorage.getItem("avatar"));
      sessionStorage.setItem('role', localStorage.getItem("role"));
      sessionStorage.setItem('id', localStorage.getItem("id"));
      sessionStorage.setItem('civ', localStorage.getItem("civ"));
      sessionStorage.setItem('phone', localStorage.getItem("phone"));
      sessionStorage.setItem('token', localStorage.getItem("token"));
      window.location.reload();
    }
  }, []);

  return (
    <>
      <Router>
        <div className="main-wrapper">
          <NotificationContainer />
          <Navbar />
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/accueil" component={Home} />
            <Route path="/contact" component={Contact} />
            <Route
              exact
              path="/boutique/:cat?/:subcat?/:family?"
              component={Boutique}
            />
            <Route
              exact
              path="/search/:search"
              component={Search}
            />
            <Route exact path="/details_etonnant_produit" component={BoutiquePlusEtonnant} />
            <Route exact path="/boutiqueCovid" component={BoutiqueCovid} />
            <Route
              path="/login-register"
              render={() =>
                sessionStorage.getItem("token") ? <Home /> : <Login />
              }
            />
            <Route
              path="/profil"
              render={() =>
                sessionStorage.getItem("email") ? <Profile /> : <Login />
              }
            />
            <Route path="/apropos" component={About} />
            <Route path="/conditions" component={GeneralCdt} />
            <Route
              path="/devis"
              render={() =>
                sessionStorage.getItem("email") ? <Quotations /> : <Login />
              }
            />
            <Route path="/demander-devis" component={QuotationForm} />
            <Route path="/blog/:idCat?" component={Blog} />
            {/* <Route path="/wishlist" component={WishList} /> */}
            <Route
              path="/wishlist"
              render={() =>
                sessionStorage.getItem("email") ? <WishList /> : <Login />
              }
            />
            <Route
              path="/invoice/:id"
              render={() =>
                sessionStorage.getItem("email") ? <Invoice /> : <Login />
              }
            />
            <Route path="/compare" component={Compare} />
            {/* <Route
              path="/compare"
              render={() =>
                sessionStorage.getItem("email") ? <Compare /> : <Login />
              }
            /> */}
            {/* <Route path="/reclamation" component={Complaints} /> */}
            <Route
              path="/reclamation"
              render={() =>
                sessionStorage.getItem("email") ? <Complaints /> : <Login />
              }
            />
            <Route
              path="/details-produit/:id"
              component={ProductDetails}
            />
            <Route
              exact
              path="/panier"
              component={Panier}
            />
            <Route
              exact
              path="/achat-maintenant"
              component={AchatImediat}
            />
            <Route exact path="/facture/:id" component={Facture} />
            <Route
              path="/details-new_produit/:id"
              component={NewArrivalDetails}
            />
            <Route
              path="/offer_combinees/:id"
              component={AmazingDetails}
            />
            <Route
              exact
              path="/offer_combinees"
              component={BoutiqueOfferCombinees}
            />
            <Route exact path="/details-blog/:id" component={BlogDetails} />
            <Route component={NotFound} />
          </Switch>
          <Footer />
          <SideMenu />
          <SearchComponent />
        </div>
        <MobileHeader />
      </Router>
    </>
  );
}

export default App;