import { useEffect, useState } from "react";
import ProductsService from "../../../services/product.service";
import ProductSliders from "./slides/productsSliders";
import SingleProduct from '../boutique/SingleProduct'

const DetailSlider = ({productId}) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    ProductsService.getProductDetailSlider({product_id : productId}).then((res) => {
      setProducts(res.data.data[0]);
    });
  }, [productId]);

  return products.length > 0 ? (
    <div className="banner-area pb-45">
      <div className="custom-container">
        <div className="section-title-1" style={{marginBottom : 10}}>
          <h2>{`Produits suggérés`}</h2>
        </div>
        <div>
          <ProductSliders products={products} BaseUrl={'details-produit'}/>
        </div>
      </div>
    </div>
  ) : null;
};

export default DetailSlider;
