import BrandsBanner from "./BrandsBanner";
import CategorieBanner from "./CategorieBanner";
import CategorieProductArea from "./CategorieProductArea";
import HotItemsSlider from "./HotItemsSlider";
import LastBlog from "./LastBlog";
import NewArrivals from "./NewArrivals";
// import PromoDeJour from "./PromoDeJour";
// import SliderBanner from "./SliderBanner";
import AmazingProducts from "./amazing_products";
import SubscribeArea from "./SubscribeArea";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DayliDeal from "./daylideal/DayliDeal";
import MainSlidesSection from "./slides/MainSlidesSection";
import CovidSlider from "./covid_slider";
import PlusEtonnants from "./plus_etonnants_slider";

const Home = () => {
  return (
    <>
      <CategorieBanner />
      <MainSlidesSection />
      {/* <SliderBanner /> */}
      {/* <PromoDeJour /> */}
      <DayliDeal />
      <CategorieProductArea />
      <CovidSlider />
      <PlusEtonnants />
      {/* <HotItemsSlider /> */}
      <NewArrivals />
      {/* amazing product */}
      <AmazingProducts />
      <BrandsBanner />
      <SubscribeArea />
      <LastBlog />
    </>
  );
};
export default Home;
