import axios from "axios";
import authHeader from "./auth-header";

const userId = sessionStorage.getItem("id");

const sendReclamation = (data) => {
  // console.log(data.get("image"))
  const URL = `${window.API_URL}user/add_reclamation`;
  return axios.post(URL, data, authHeader());
};

const getReclamations = () => {
  const URL = `${window.API_URL}user/user_reclamation/${userId}`;
  return axios.get(URL, authHeader());
};

const ReclamationService = {
  sendReclamation,
  getReclamations,
};

export default ReclamationService;
