import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductsService from "../../../services/product.service";
import ProductSliders from "./slides/productsSliders";

const PlusEtonnants = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    ProductsService.allProductEtonants().then((res) => {
      setProducts(res.data.results);
    });
  }, []);
  return products.length > 0 ? (
    <div className="banner-area pb-45">
      <div className="custom-container">
        <div className="section-title-1">
          <h2>Les produits les plus étonnants</h2>
        </div>
        <div style={{
            display : 'flex',
            justifyContent : 'end'
          }}>
            <Link to={'/details_etonnant_produit'}>
              <b style={{
                textDecoration : 'underline',
                fontSize : 17
              }}>{'voir la liste'}</b>
            </Link>
          </div>
        <ProductSliders products={products} BaseUrl={'details-produit'}/>
      </div>
    </div>
  ) : null;
};

export default PlusEtonnants;
