import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Clipboard, MoonLoader } from "react-spinners";
import SliderService from "../../../../services/slider.service";

const RightSliders = ({subSliders=[]}) => {
  const history = useHistory();
  const [sliders, setSliders] = useState([]);
  // const [subSliders, setSubSliders] = useState([]);
  const ArrowNext = (props) => {
    const { onClick } = props;
    return (
      <div
        className={'ArrowNext'}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right" />
      </div>
    );
  };

  const ArrowPrev = (props) => {
    const { onClick } = props;
    return (
      <div
        className={'ArrowPrev'}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left" />
      </div>
    );
  };
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    SliderService.allSliders().then((res) => {
      setSliders(res.data.results);
      console.log(res.data);
      setLoading(false);
    });
    // SliderService.subSliders().then((res) => {
    //   setSubSliders(res.data.results);
    //   console.log(res.data);
    // });
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="m-2">
          {!loading ? (
            <Slider {...settings}>
              {sliders.map((slider, i) => (
                <div key={i}>
                  <div
                    onClick={() => slider?.image_url ? (document.location.href = slider.image_url) : false}
                    className="single-hero-slider single-animation-wrap slider-height-1 custom-d-flex custom-align-item-end bg-img"
                    style={{
                      cursor : slider?.image_url ? 'pointer' : undefined,
                      backgroundImage: ` url("${window.IMAGE_API_URL}sliders/${slider.image}")`,
                      backgroundPosition: 'center'
                    }}
                  >
                    <div className="hero-slider-content-1 slider-animated-1">
                      <p
                        className="animated"
                        style={{ fontSize: "30px", fontWeight: "bold" }}
                      >
                        {" "}
                        {slider.titre}
                      </p>

                      <h4
                        style={{ fontWeight: "bolder" }}
                        className="animated m-5"
                      >
                        {slider.Bulle}
                      </h4>
                      <p></p>
                      <p className="animated">{slider.description}</p>
                    </div>
                  </div>
                  <div className="btn-style-1" style={{
                    marginTop : 10
                  }}>
                    {slider.type === "Produit" ? (
                      <a
                      style={{
                        backgroundColor : (slider.color && slider.color !== '') ? slider.color : '#34448c'
                      }}
                      className="animated" href={(slider.url && slider.url !== '') ? slider.url : "/boutique"}>
                        {(slider.button_title && slider.button_title !== '') ? slider.button_title : 'Voir la boutique'}
                      </a>
                    ) : (
                      <a 
                      style={{
                        backgroundColor : (slider.color && slider.color !== '') ? slider.color : '#34448c'
                      }}
                      className="animated" href={(slider.url && slider.url !== '') ? slider.url : "/blog"}>
                        {(slider.button_title && slider.button_title !== '') ? slider.button_title : 'Voir tout les blogs'}
                      </a>
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="text-center m-5">
              <MoonLoader color={"#163C74"} loading={loading} size={30} />
            </div>
          )}
        </div>
      </div>
      <div className="clo-12">
        <div className="banner-area banner-area-mt">
          <div className="row">
            {subSliders.map((slider, i) => {return slider.position !=="Gauche" ?
              <div key={i} className="col-lg-6 col-md-6 col-12 col-sm-12">
                <div className="banner-wrap wow tmFadeInUp mt-2 mb-2">
                  <div className="banner-img banner-img-zoom">
                    <Link to={"/details-produit/" + slider.product}>
                      <img
                        src={`${window.IMAGE_API_URL}sliders/${slider.subimage}`}
                        alt=""
                        width="467px"
                        height="282px"
                      />
                    </Link>
                  </div>
                  <div className="banner-content-1">
                    <span>{slider.catg}</span>
                    <h2>{slider.product}</h2>
                    <div className="btn-style-1">
                      <Link
                        className="font-size-14 btn-1-padding-2"
                        to={"/details-produit/" + slider.product}
                      >
                        Voir details
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            : ""})}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSliders;
