const ContactsArea = () => {
  return (
    <>
      <div className="contact-info-wrap-2 mb-20">
        <div className="row mb-5 mt-5">
          <div className="col-12 col-md-6 d-flex justify-content-end">
            <div
              style={{
                boxShadow: "0px 0px 6px 6px #ccc",
                borderRadius: "26px",
              }}
              className="single-contact-info3-wrap m-3 p-4"
            >
              <div className="single-contact-info3-icon">
                <i className="far fa-envelope-open-text"></i>
              </div>
              <div className="single-contact-info3-content">
                <h3>Service commercial</h3>
                <p className="width-1">
                  Vous avez une question, une demande spécifique? Envoyez un
                  ticket et un de nos agents prendra contact avec vous
                </p>
                <div className="w-80 m-3 text-center">
                  <form
                    className="mr-5 mt-5"
                    action="mailto:hfc-casa@healthcarefacilitiesconsult.com"
                    method="GET"
                  >
                    <input
                      style={{
                        background: "#163C74",
                        color: "white",
                        borderRadius: "26px",
                      }}
                      type="submit"
                      className="btn"
                      value="Envoyez un ticket"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-start">
            <div
              style={{
                boxShadow: "0px 0px 6px 6px #ccc",
                borderRadius: "26px",
              }}
              className="single-contact-info3-wrap m-3 p-4"
            >
              <div className="single-contact-info3-icon">
                <i className="far fa-envelope-open-text"></i>
              </div>
              <div className="single-contact-info3-content">
                <h3>Support service technique</h3>
                <p className="width-1">
                  Besoin d'un support technique notre équipe est à votre
                  disposition du lundi au vendredi de 09h à 17h
                </p>
                <div className="w-80 m-3 text-center">
                  <form
                    className="mr-5 mt-5"
                    action="mailto:hfc-casa@healthcarefacilitiesconsult.com"
                    method="GET"
                  >
                    <input
                      style={{
                        background: "#163C74",
                        color: "white",
                        borderRadius: "26px",
                      }}
                      type="submit"
                      className="btn"
                      value="Envoyez un ticket"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-us-area contact-us-bg pt-65 pb-55">
        <div className="container">
          <div className="row  justify-center">
            <div className="col-lg-4 col-md-6 col-12 col-sm-12 wow tmFadeInUp p-5 ">
              <div className="single-contact-info3-wrap mb-30 ">
                <div className="single-contact-info3-icon">
                  <i className="fal fa-map-marker-alt"></i>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Adresses</h3>
                  <p className="width-1">
                    {" "}
                    Casablanca : 504, Lotissement Hadj Fatah 20260 - El Oulfa
                    <br></br> Oujda : 32, Lotissement Benmoussa - Quartier du
                    CHU 60000
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 col-sm-12 wow tmFadeInUp p-5">
              <div className="single-contact-info3-wrap mb-30">
                <div className="single-contact-info3-icon">
                  <i className="fal fa-phone"></i>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Appelez à l'aide maintenant!</h3>
                  <p>
                    {" "}
                    Casablanca: <span>+212 520 333 012</span>
                  </p>
                  <p>
                    {" "}
                    Oujda : <span>+212 520 333 011</span>
                  </p>
                  <p>
                    {" "}
                    Envoyez-nous un email : <br></br>
                    <span>contact@hfc.ma</span><br/>
                    <span>{'hfc-oujda@healthcarefacilitiesconsult.com'}</span><br/>
                    <span>hfc-casa@healthcarefacilitiesconsult.com</span><br/>
                    <span>hfc-oujda@hfc.ma</span><br/>
                    <span>hfc-casa@hfc.ma</span><br/>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 col-sm-12 wow tmFadeInUp p-5">
              <div className="single-contact-info3-wrap mb-30">
                <div className="single-contact-info3-icon">
                  <i className="fal fa-clock"></i>
                </div>
                <div className="single-contact-info3-content">
                  <h3>Heure de fonctionnement</h3>
                  <p> Lundi - vendredi: 09:00 - 20:00 </p>
                  <p> Dimanche et Samedi: 10:30 - 22:00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsArea;
