import moment from "moment";
import { useEffect, useState } from "react";
import BlogService from "../../../services/blogs.service";
import { Link } from "react-router-dom";

const LastBlog = () => {
  const [blog, setBlog] = useState("");
  useEffect(() => {
    BlogService.lastBlog().then((res) => {
      setBlog(res.data.result[0]);
      console.log(res.data);
    });
  }, []);

  return (
    <div className="product-area pt-40 pb-40">
      {blog && (
        <div className="custom-container ">
          <div className="section-title-1 mb-40">
            <h2>LE DERNIER BLOG</h2>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="col-lg-6 col-md-6 col-12 col-sm-6 grid-item wow tmFadeInUp">
              <div className="blog-wrap-2 mb-30">
                <div className="blog-img-2">
                  <Link to={`/details-blog/${blog.id}`}>
                    <img
                      src={window.IMAGE_API_URL + "blogs/" + blog.image}
                      alt=""
                    />
                  </Link>
                  <div className="blog-tag-2">
                    <a href="blog.html">Dernières nouvelles</a>
                  </div>
                </div>
                <div className="blog-content-2">
                  <div className="blog-meta-2">
                    <ul>
                      <li>
                        <i className="far fa-calendar" />{" "}
                        {moment(blog.created_at).fromNow()}
                      </li>
                      {/* <li>
                      <i className="far fa-eye" /> 453 views
                    </li> */}
                    </ul>
                  </div>
                  <h3>
                    <a href="blog-details.html">{blog.titre}</a>
                  </h3>
                  <div className="blog-btn">
                    <Link to={`/details-blog/${blog.id}`}>
                      Lire la suite <i className="far fa-long-arrow-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LastBlog;
