import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import DevisService from "../../../services/devis.service";
import WhichPage from "../../Init/header/WhichPage";
import EnterpriseInpusts from "./EnterpriseInpusts";
import PaymentInfo from "./PaymentInfo";
import ProductCart from "./ProductCart";
import { ClipLoader } from "react-spinners";
import "react-notifications/lib/notifications.css";

const QuotationForm = () => {
  const ids = [];
  const [adresse, setAdresse] = useState("");
  const [devisItems, setDevisItems] = useState([]);
  const user = AuthService.getCurrentUser();
  const [loading, setLoading] = useState(false);
  const updateDevisQte = (id, qte) => {
    DevisService.updateDevisQte(id, qte).then((res) => false);
  };

  const saveDemande = () => {
    if (adresse) {
      const data = { id_user: user.id, adresse_livraison: adresse };
      setLoading(true);
      DevisService.saveDemande(data)
        .then((res) => {
          setLoading(false);
          NotificationManager.success("Devis Envoyee avess succe", "", 2000);
          setTimeout(() => {
            window.location = "/boutique";
          }, 300);
        })
        .catch((err) => {
          NotificationManager.error(`${err}`);
          setLoading(false);
        });
    } else Swal.fire("entrez l'adresse de livraison");
  };

  const removeItem = (id) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#163C74",
      cancelButtonColor: "#0E9B4A",
      confirmButtonText: "Oui, supprimez-le",
    }).then((result) => {
      if (result.isConfirmed) {
        DevisService.removeProductFromDevis(id).then((res) =>
          Swal.fire("Supprimé !", "Your file has been deleted.", "success")
        );
        laodData();
      }
    });
  };
  const laodData = () => {
    DevisService.allDivisItems()
      .then((res) => {
        setDevisItems(res.data.results);
        NotificationManager.success(`${res.data.results.length} element`);
      })
      .catch((err) => NotificationManager.error(`${err}`));
  };
  useEffect(() => {
    laodData();
  }, []);
  return (
    <>
      <WhichPage page="Demande d'un devis" />
      <div className="login-register-area pt-75 pb-75">
        <div className="container">
          <div className="row">
            <div className="row login-register-wrap">
              <div className="col-12 col-md-8">
                <div className="login-register-form">
                  <div className="login-register-title">
                    <h1>Demander un devis</h1>
                  </div>
                  <form action="#">
                    <div className="row">
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>Nom *</label>
                        <input disabled type="text" value={user.firstName} />
                      </div>
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>Prenom *</label>
                        <input disabled type="text" value={user.lastName} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>civilité *</label>
                        <input type="text" value={user.civ} disabled />
                      </div>
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>Adresse e-mail*</label>
                        <input type="email" value={user.email} disabled />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>adresse de livraison *</label>
                        <input
                          type="text"
                          value={adresse}
                          onChange={(e) => setAdresse(e.target.value)}
                          placeholder="adresse de livraison"
                          autoFocus
                        />
                      </div>
                      <div className="col-12 col-md-6 login-register-input-style input-style">
                        <label>TÉLÉPHONE </label>
                        <input type="text" value={user.phone} disabled />
                      </div>
                    </div>
                    {/* <ProductCart /> */}
                    <div className="cart-table-content">
                      <div className="table-content table-responsive">
                        <table className="text-center">
                          <tbody>
                            {devisItems.map((item, i) => {
                              ids.push(item.id);
                              return (
                                <tr key={i}>
                                  <td>
                                    <div className="form-check">
                                      <Link
                                        to="#"
                                        onClick={() => removeItem(item.id)}
                                      >
                                        <i className="far fa-times" />
                                      </Link>
                                    </div>
                                  </td>
                                  <td className="product-thumbnail">
                                    <Link to={
                                    item.id_produit ? 
                                    `/details-produit/${item.product}` : 
                                    `/details-new_produit/${item.product}`
                                    }>
                                      <img
                                        src={`${window.IMAGE_API_URL}products/${
                                          item.image.split(";")[0]
                                        }`}
                                        alt=""
                                      />
                                    </Link>
                                  </td>
                                  <td className="product-name">
                                    <h5>
                                      {item.id_produit ? (
                                        <Link
                                          to={`/details-produit/${item.product}`}
                                        >
                                          {item.product}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/details-new_produit/${item.product}`}
                                        >
                                          {item.product}
                                        </Link>
                                      )}
                                    </h5>
                                  </td>
                                  <td>
                                  <div>
                                    <span>
                                    <b>{
                                      (
                                        item?.offer_pourcentage ?    
                                        `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                                        `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                                        )
                                    }</b>
                                    </span>
                                    {
                                      item?.offer_pourcentage ?  
                                      <span style={{
                                        color: 'rgb(168, 160, 149)',
                                        textDecorationColor: 'initial',
                                        textDecorationLine: 'line-through',
                                        textDecorationThickness: 'initial',
                                        textDecorationStyle: 'initial',
                                        marginLeft : 5
                                      }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                                    }
                                  </div>
                                  </td>
                                  <td className="cart-quality">
                                    <div className="product-quality w-100">
                                      <input
                                        className="cart-plus-minus-box input-text text"
                                        name="qtybutton"
                                        type="number"
                                        min="0"
                                        step="1"
                                        defaultValue={item.quantite_produit}
                                        onChange={(e) => {
                                          updateDevisQte(
                                            item.id,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="product-remove">
                                    {/* <Link to="#">Retirer</Link> */}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* <EnterpriseInpusts /> */}
                    <div className="m-5">
                      <span style={{ fontStyle: "italic" }}>
                        NB: Veuillez verifier l exactitude de vos données. Aucun
                        modification ne peut s effectuer apres le sauvegarde de
                        cette demande
                      </span>
                    </div>

                    <div className="d-flex justify-content-center login-register-btn">
                      <button
                        className="w-25"
                        style={{ background: "#6c757d" }}
                      >
                        Annuler
                      </button>
                      <button
                        className="w-25 ml-4"
                        type="button"
                        onClick={saveDemande}
                      >
                        {loading ? (
                          <ClipLoader color={"#ffffff"} loading={loading} />
                        ) : (
                          <span>Envoyer</span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <PaymentInfo />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotationForm;
