import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandsService from "../../../services/brands.service";

export const BrandMenu = (props) => {
  const brands = props.brands || [] ;
 
  return (
    <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-1 mb-20">
      <h4 className="sidebar-widget-title">Brands </h4>
      <div className="sidebar-brand-list">
        <ul className=" d-flex flex-column justify-content-center">
          {brands.map((brand, i) => (
            <li key={i}>
              <Link className="brand-link" to={`/boutique/m:${brand.name}`}>
                <img
                  src={`${window.IMAGE_API_URL}marques/${brand.image}`}
                  alt=""
                  width="110"
                />
                <label>{brand.name}</label>
                <span>({brand.nbr_product})</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
