import axios from "axios";
import authHeader from "./auth-header";
const idUser = sessionStorage.getItem("id");

const allDivisItems = () => {
  return axios.get(window.API_URL + `user/devis/` + idUser, authHeader());
};

const addProductToDevis = (data) => {
  return axios.post(window.API_URL + `user/devis_product`, data, authHeader());
};

const removeProductFromDevis = (id) => {
  return axios.delete(
    window.API_URL + `user/devis_product/${id}`,
    authHeader()
  );
};

const saveDemande = (data) => {
  return axios.post(window.API_URL + `user/demande_devis/${idUser}`, data, authHeader());
};
const loadDevisByClient = (id) => {
  return axios.get(window.API_URL + `user/mes_demandes/${id}`, authHeader());
};
const updateDevisQte = (idDevis, qte) => {
  const data = {
    id: idDevis,
    quantite_produit: qte,
  };
  return axios.patch(window.API_URL + `user/devis_product`, data, authHeader());
};
const GetDemandeDevisByIdDevis = (id_devis) => {
  return axios.get(
    window.API_URL + `user/facture/${id_devis}`,
    authHeader()
  );
};

const DevisService = {
  allDivisItems,
  addProductToDevis,
  removeProductFromDevis,
  loadDevisByClient,
  updateDevisQte,
  saveDemande,
  GetDemandeDevisByIdDevis,
};

export default DevisService;
