import axios from 'axios';

const allBrands = () => {
    return axios.get(window.API_URL+"/marques")
}

const BrandService ={
    allBrands
}

export default BrandService ;