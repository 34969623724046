/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import DevisService from "../../../services/devis.service";
import ProductsService from "../../../services/product.service";
import WLService from "../../../services/wishList.service";
import WhichPage from "../../Init/header/WhichPage";
import ShareOnFacebook from "../mediashare/ShareOnFacebook";
import ShareOnLinkedin from "../mediashare/ShareOnLinkedin";
import ShareOnTwitter from "../mediashare/ShareOnTwitter";
import MenuArea from "./MenuArea";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AuthService from "../../../services/auth.service";
import CompareService from "../../../services/compare.service";
import Swal from "sweetalert2";
import axios from "axios";
import { ColorSize } from "./ColorSize";
import { CreatePanier } from "../../../services/panier";
import ImageListItem from '@mui/material/ImageListItem';
import DetailSlider from "../home/productDetailSlider";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const ProductDetails = () => {
  const ArrowNext = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          right: "35%",
          bottom: "-15px",
          zIndex: "15",
          height: "40px",
          width: "40px",
          opacity: "1",
          color: "#163C74",
          backgroundColor: "whitesmoke",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-right" />
      </div>
    );
  };

  const ArrowPrev = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        style={{
          position: "absolute",
          fontSize: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          left: "35%",
          bottom: "-15px",
          zIndex: "15",
          height: "40px",
          width: "40px",
          opacity: "1",
          color: "#163C74",
          backgroundColor: "whitesmoke",
          borderRadius: "50%",
          cursor: "pointer",
        }}
        onClick={onClick}
      >
        <i className="fas fa-chevron-left" />
      </div>
    );
  };
  const settings = {
    // customPaging: function (i) {
    //   return (
    //     <Link to="#">
    //       <img src={`${window.IMAGE_API_URL}products/${images[i]}`} />
    //     </Link>
    //   );
    // },
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };
  const [product, setProduct] = useState("");
  const cat = product.category && product.category.replaceAll("/", "_");
  const [images, setimages] = useState([]);

  const addToDevis = () => {
    const idUser = sessionStorage.getItem("id");
    if (idUser) {
      const data = {
        id_produit: product.id,
        id_user: idUser,
      };
      DevisService.addProductToDevis(data)
        .then((res) => {
          NotificationManager.success("L'element est met dans le devis");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            console.log("YOooooo");
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };

  const addToCompare = () => {
    if (CompareService.isItemInList(product.id)) {
      Swal.fire("ce produit est deja dans la liste");
    } else if (CompareService.getListCompares().length < 3) {
      CompareService.addCompare(product.id);
      NotificationManager.success("le produit est met en campare list");
    } else {
      Swal.fire("Vous avez deja 3 produit dans la liste");
    }
  };

  const addToWL = () => {
    const idUser = sessionStorage.getItem("id");
    if (idUser) {
      const data = { id_product: product.id, id_client: idUser };

      WLService.addProductToWl(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            console.log("YOooooo");
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );

            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };

  const productId = useParams().id;

  useEffect(() => {
    const data = { items: 2, page: 1, search : productId};
    ProductsService.productsByPageSearch(data)
    .then((res) => {
      if (res.data.results[0].length) {
          res.data.results[0] = res.data.results[0][0];
            setProduct({
              ...res.data.results[0],
              ColorSize: res.data.results[0].ColorSize
                ? JSON.parse(res.data.results[0].ColorSize).reduce(
                    (prev, val, ind, allColors) => {
                      let i = 0;
                      for (i = 0; i < allColors.length; i++) {
                        if (allColors[i].size === val.size) break;
                      }
                      if (i !== ind) val = { ...val, notprint: true };
                      prev.push(val);
                      return prev;
                    },
                    []
                  )
                : [],
            });
      }
    });
    // ProductsService.productById(productId).then((res) => {
    //   setProduct({
    //     ...res.data.result[0],
    //     ColorSize: res.data.result[0].ColorSize
    //       ? JSON.parse(res.data.result[0].ColorSize).reduce(
    //           (prev, val, ind, allColors) => {
    //             let i = 0;
    //             for (i = 0; i < allColors.length; i++) {
    //               if (allColors[i].size === val.size) break;
    //             }
    //             if (i !== ind) val = { ...val, notprint: true };
    //             prev.push(val);
    //             return prev;
    //           },
    //           []
    //         )
    //       : [],
    //   });
    // });
  }, [productId]);

  useEffect(() => {
    if (product !== "") {
      setimages(
        product.ColorSize.reduce(
          (prev, val) => {
            if (val?.image) prev.push(`${window.API_URL}${val.image.replace(/^\//, '')}`);
            return prev;
          },
          product.image
            ? product.image
                .split(";")
                .filter((val) => val !== "")
                .map((val) => window.IMAGE_API_URL + "products/" + val)
            : []
        )
      );
    }
  }, [product]);

  const [ClientSelect, setClientSelect] = useState({
    color: null,
    size: null,
    quantity: 1,
  });

  const [hoverImgColor, sethoverImgColor] = useState(null);

  const InsertToPanier = () => {
    if (!sessionStorage.getItem("id"))
      return NotificationManager.warning("vous n'êtes pas encore connectée");
    if (!ClientSelect.color || !ClientSelect.size)
      return NotificationManager.warning("you must select a size and color");
    CreatePanier({
      product_id: product.id,
      color: ClientSelect.color,
      size: ClientSelect.size,
      quantity: 1,
    })
      .then((res) => NotificationManager.success("product added to panier"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ||
            err?.response?.data?.message ||
            "something went wrong"
        )
      );
  };
  return (
    <>
      <WhichPage page="Boutique / Detail" />
      <div className="product-details-area padding-30-row-col pt-75 pb-55">
        <div className="custom-container">
          <div className="row flex-row-reverse">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="product-details-wrap">
                <div className="product-details-wrap-top">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="product-details-slider-wrap">
                        <div className="pro-dec-big-img-slider">
                          <Slider {...settings}>
                            {hoverImgColor ? (
                              <div className="single-big-img-style">
                                 <ImageListItem
                                    cols={1}
                                    rows={1}
                                    style={{
                                      display : 'flex',
                                      justifyContent : 'center',
                                      padding : 10
                                    }}
                                  >
                                    <img
                                      style={
                                        {
                                          borderRadius : 20
                                        }
                                      }
                                      {...srcset(hoverImgColor, 250, 200, 1, 1)}
                                      alt={'product image'}
                                      loading="lazy"
                                    />
                                  </ImageListItem>
                              </div>
                            ) : (
                              images.map(
                                (img, i) =>
                                  img && (
                                    <div
                                      key={i}
                                      className="single-big-img-style"
                                    >
                                      <ImageListItem
                                        cols={1}
                                        rows={1}
                                        style={{
                                          display : 'flex',
                                          justifyContent : 'center',
                                          padding : 10
                                        }}
                                      >
                                        <img
                                          style={
                                            {
                                              borderRadius : 20
                                            }
                                          }
                                          {...srcset(img, 250, 200, 1, 1)}
                                          alt={'product image'}
                                          loading="lazy"
                                        />
                                      </ImageListItem>
                                    </div>
                                  )
                              )
                            )}
                          </Slider>
                        </div>
                        <div className="product-dec-slider-small product-dec-small-style1"></div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="product-details-content pro-details-content-pl">
                        <div className="pro-details-category">
                          <ul>
                            <li>
                              <Link to={`/boutique/${cat}`}>{cat}</Link> /{" "}
                            </li>
                            <li>
                              <Link
                                to={`/boutique/${cat}/${product.sub_category}`}
                              >
                                {product.sub_category}{" "}
                              </Link>
                              /{" "}
                            </li>
                          </ul>
                        </div>
                        <h1> {product.name}</h1>
                        <div className="pro-details-brand-review">
                          <div>
                            <div className="pro-details-brand">
                              <span>
                                {" "}
                                Marque{" "}
                                <Link to={`/boutique/m:${product.marque}`}>
                                  <b>{product.marque}</b>
                                </Link>
                              </span>
                            </div>
                            <div className="pro-details-brand" style={{display : 'flex'}}>
                              <div style={{marginRight : 5}}>
                                <span>
                                  <b style={{
                                    color : '#e4573d',
                                    fontSize : 24
                                  }}>{
                                  product.tarif > 0 ?
                                  (
                                  product?.offer_pourcentage ?    
                                  `${parseFloat(parseFloat(product.tarif) - ((parseFloat(product.tarif) * parseInt(product?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                                  `${parseFloat(product.tarif).toFixed(2)} DHS TTC`
                                  ) :
                                  'demande de prix'
                                  }</b>
                                </span>
                                {
                                product?.offer_pourcentage ?  
                                <span style={{
                                  color: 'rgb(168, 160, 149)',
                                  textDecorationColor: 'initial',
                                  textDecorationLine: 'line-through',
                                  textDecorationThickness: 'initial',
                                  textDecorationStyle: 'initial',
                                  marginLeft : 5
                                }}>{parseFloat(product.tarif).toFixed(2)}</span> : <></>
                                }
                              </div>
                              {
                                product?.offer_pourcentage ?
                              <div style={{
                                margin: "0 0 10px",
                                display : 'flex'
                              }}>
                                <span style={{
                                  marginRight : 3,
                                  backgroundColor: '#e4573d',
                                  display: 'inline-block',
                                  fontSize: '13px',
                                  fontWeight: 700,
                                  textTransform: 'uppercase',
                                  letterSpacing: '1px',
                                  lineHeight: 1,
                                  borderRadius: '2px',
                                  color: '#ffffff',
                                  marginRight: '10px',
                                  padding: '5px 8px 6px'
                                }}>-{product.offer_pourcentage}%</span>
                              </div> :<></>
                              }
                            </div>
                            <ColorSize
                              color={ClientSelect.color}
                              size={ClientSelect.size}
                              setSelectedColor={(color) =>
                                setClientSelect((old) => ({ ...old, color }))
                              }
                              setSelectedSize={(size) =>
                                setClientSelect((old) => ({ ...old, size }))
                              }
                              setQuantity={(quantity) =>
                                setClientSelect((old) => ({ ...old, quantity }))
                              }
                              ColorSizes={product.ColorSize}
                              onHoverImg={(imgPath) =>
                                sethoverImgColor(imgPath)
                              }
                            />
                          </div>
                          <div className="pro-details-rating-wrap">
                            {product.Catalogue ? (
                              <a
                                href={
                                  window.CATALOGUE_URL +
                                  product.Catalogue
                                }
                                target="_blank"
                              >
                                <i className="far fa-eye" /> voir catalogue
                              </a>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="pro-details-price-short-description">
                          <div className="pro-details-action-wrap">
                            <div className="buy">
                              <button onClick={InsertToPanier}>
                                Ajouter au panier
                              </button>
                            </div>
                            <div className="pro-details-add-to-cart">
                              <button onClick={addToDevis}>
                                Demander un devis
                              </button>
                            </div>
                            <div className="buy">
                              <a href={`/achat-maintenant?color=${ClientSelect.color || ''}&size=${ClientSelect.size || ''}&product_id=${product.id}&type=${'product'}`} target="_blank">
                                <button>
                                  Acheter maintenant
                                </button>
                              </a>
                            </div>
                            <div className="pro-details-action tooltip-style-4">
                              <button
                                aria-label="ajouter à la list des envies"
                                onClick={addToWL}
                              >
                                <i className="fad fa-heart" />{" "}
                              </button>
                              <button
                                aria-label="Comparer"
                                onClick={addToCompare}
                              >
                                <i className="far fa-signal" />{" "}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="tooltip-style-4">
                          <ShareOnFacebook />
                          <ShareOnLinkedin />
                          <ShareOnTwitter />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-details-wrap-bottom">
                    {
                        product.description ?
                        <div className="tab-style-1 nav mb-35">
                            <h3>Description</h3>
                        </div> : <></>
                    }
                  <div className="tab-content">
                    <div id="product-details-1" className="tab-pane active">
                      <div className="product-details-description">
                        <p>{product.description || ''}</p>
                        <p>{product.caracteristique ? `Caractéristique : ${product.caracteristique}` : ''}</p>
                        <p>{product.infos_supp ? `Infos Supplémentaires : ${product.infos_supp}` : ''} </p>
                        <p>{product.designation ? `Code produit : ${product.designation}` : ''} </p>
                        <p>{product.reference ? `Reference : ${product.reference}` : ''} </p>
                        {
                          (product.surface > 0 || product.volume > 0
                          || product.hauteur > 0 || product.longueur > 0 ||
                          product.largeur > 0 || product.taille) ?
                          <h4>Plus de details : </h4> :
                          <></>
                        }
                        <ul>
                          {product.surface > 0 && (
                            <li>
                              {product.surface
                                ? "surface : " + product.surface
                                : ""}
                            </li>
                          )}
                          {product.volume > 0 && (
                            <li>
                              {" "}
                              {product.volume
                                ? "Volume : " + product.volume
                                : ""}
                            </li>
                          )}
                          {product.hauteur > 0 && (
                            <li>
                              {" "}
                              {product.hauteur
                                ? "Hauteur : " + product.hauteur
                                : ""}
                            </li>
                          )}
                          {product.longueur > 0 && (
                            <li> Longueur : {product.longueur}</li>
                          )}
                          {product.largeur > 0 && (
                            <li> Largeur : {product.largeur}</li>
                          )}
                          {product.taille && (
                            <li> taille : {product.taille}</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product-details-wrap-bottom">
                  {product.Catalogue ? (
                    <iframe
                      src={
                        window.CATALOGUE_URL +
                        product.Catalogue
                      }
                      style={{ width: "100%", height: "500px" }}
                      frameBorder="0"
                    ></iframe>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <MenuArea />
          </div>
        </div>
        {
          product.id ?
          <DetailSlider productId={product.id}/> :
          <></>
        }
      </div>
    </>
  );
};

export default ProductDetails;
