import axios from 'axios';

const allFamilies = () => {
    return axios.get(window.API_URL+"familles")
}

const FamiliesService ={
    allFamilies
}

export default FamiliesService ;