import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CategorieService from "../../../services/categories.service";
import "./categorieBanner.css";

const CategorieBanner = () => {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    CategorieService.hot_categories().then((res) => {
      setCategories(res.data.results);

      console.log(res.data.results[0]);
    });
  }, []);

  return (
    <div className="header-top header-top-ptb-1 bg-gray d-none d-lg-block">
      <div className="custom-container">
        <div className="row align-items-center">
          <div className="col-xl-12 col-lg-12">
            <section className="best-categorie">
              <ul className="best-categorie-ul">
                {categories.map((category, index) => (
                  <li className="item" key={index}>
                    <Link to={`boutique/${category.catg.replaceAll("/", "_")}`}>
                      <b>{category.catg}</b>
                    </Link>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategorieBanner;
