import axios from 'axios';

const allSubCategories = () => {
    return axios.get(window.API_URL+"sub_categories")
}

const SubCategorieService ={
    allSubCategories
}

export default SubCategorieService ;