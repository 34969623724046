import AuthService from "../../../services/authentication"


const ProfileTabMenu = () => {
    return(
        <div className="col-lg-4 col-md-4">
            <ul id="ProfileTabs" className="myaccount-tab-menu nav nav-tabs" role="tablist">
            {/* <div className="myaccount-tab-menu nav" role="tablist" id={'ProfileTabs'}>*/}
                <li>
                    <a className="active" href="#dashboad" data-toggle="tab">Tableau de bord</a>
                </li>
                <li>
                    <a href="#download" data-toggle="tab">Mes Devis</a>
                </li>
                <li>
                    <a href="#command" data-toggle="tab">Mes orders</a>
                </li>
                <li>
                    <a href="#orders" data-toggle="tab">liste de souhaits</a>
                </li>
                <li>
                    <a href="#address-edit" data-toggle="tab">Adresses</a>
                </li>
                <li>
                    <a href="#complaints" data-toggle="tab">Reclamation</a>
                </li>
                <li>
                    <a href="#account-info" data-toggle="tab"> Détails du compte</a>
                </li>
                <li>
                    <a href="/login-register" onClick={AuthService.logout}>Se déconnecter</a>
                </li>
            {/* </div> */}
            </ul>
        </div>
        
    )
}

export default ProfileTabMenu
