const MissionArea = () => {
  return (
    <div className="mision-area bg-gray-2 pt-65 pb-45">
      <div className="container">
        <div className="section-title-2 mb-45 wow tmFadeInUp">
          <h2> La qualité au service de nos clients.</h2>
          <p>
            Par le biais de notre site web, et de nos pages sur les réseaux
            sociaux, nous livrons partout au Maroc. Toutefois, nous disposons de
            2 points de vente un à Casablanca et l'autre à Oujda. Nos équiopes
            se ferons un laisir de vous conseiller et de vous servir.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12 col-sm-6 wow tmFadeInUp">
            <div className="medizin-box mb-30" data-vivus-hover>
              <div className="icon-box-wrapper">
                <div className="medizin-icon-wrap">
                  <img
                    className="svgInject"
                    src="assets/images/icon-img/linea-basic-compass.svg"
                    alt=""
                  />
                </div>
                <div className="icon-box-content">
                  <div className="title">
                    <h3>Notre vision</h3>
                    <p>
                      Dans un monde en plaine évolution, HFC entend suivre la
                      vague pour proposer à ses patrtenaires les produits
                      innovants issus des dernières technologies mondiales,
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12 col-sm-6 wow tmFadeInUp">
            <div className="medizin-box mb-30" data-vivus-hover>
              <div className="icon-box-wrapper">
                <div className="medizin-icon-wrap">
                  <img
                    className="svgInject"
                    src="assets/images/icon-img/linea-basic-flag2.svg"
                    alt=""
                  />
                </div>
                <div className="icon-box-content">
                  <div className="title">
                    <h3>Nos Valeurs</h3>
                  </div>
                  <ul>
                    <li>Empathie</li>
                    <li>Excellence</li>
                    <li>Créativité</li>
                    <li>proximité</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12 col-sm-6 wow tmFadeInUp">
            <div className="medizin-box mb-30" data-vivus-hover>
              <div className="icon-box-wrapper">
                <div className="medizin-icon-wrap">
                  <img
                    className="svgInject"
                    src="assets/images/icon-img/linea-basic-life-buoy.svg"
                    alt=""
                  />
                </div>
                <div className="icon-box-content">
                  <div className="title">
                    <h3>Notre mission</h3>
                  </div>
                  <p>
                    Accompagner nos patrtenaires dans leurs projets de
                    construction ou d'extension de leurs ciniques. Fournir les
                    meilleurs produits aux meilleurs prix. Mettre à la
                    disposition de nos clients particulier des produits adaptés
                    à leurs besoins et les ,livrer partout au Maroc
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-12 col-sm-6 wow tmFadeInUp">
            <div className="medizin-box mb-30" data-vivus-hover>
              <div className="icon-box-wrapper">
                <div className="medizin-icon-wrap">
                  <img
                    className="svgInject"
                    src="assets/images/icon-img/linea-basic-life-buoy.svg"
                    alt=""
                  />
                </div>
                <div className="icon-box-content">
                  <div className="title">
                    <h3>Nos Objectifs</h3>
                  </div>
                  <p>
                    HFC a l'ambition de devenir un des partenaires
                    incontournables dans le domaines de la Santé. Nous comptons
                    assurer pour le compte de nos partenaires toutes les
                    opérations du back-office pour que le Médecin puisse
                    consacrer toute son attention à l'exercice de son art.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionArea;
