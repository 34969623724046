import { useRef } from "react";
import UserService from "../../../services/user.service";
import { NotificationManager } from "react-notifications";
import NotificationContainer from "react-notifications/lib/NotificationContainer";

const ContactForm = () => {
  const form = useRef(null);

  const handelSubmit = (e) => {
    const fdToJson = (fd) => {
      let obj = {};
      for (let key of fd.keys()) {
        obj[key] = fd.get(key);
      }
      return obj;
    };
    e.preventDefault();
    UserService.contacter(fdToJson(new FormData(form.current)))
      .then((res) =>
        NotificationManager.success("votre message a été bien enregistre")
      )
      .catch((err) => NotificationManager.error(`${err}`));
  };

  return (
    <div className="row">
      <NotificationContainer />
      <div className="col-xl-8 col-lg-10 ml-auto mr-auto">
        <div className="contact-from-area  padding-20-row-col wow tmFadeInUp">
          <h3>Question ou besoin d'un complément d'informations?</h3>
          <form
            ref={form}
            onSubmit={handelSubmit}
            className="contact-form-style text-center"
          >
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input
                    name="first_name"
                    defaultValue={sessionStorage.getItem("name")}
                    required
                    placeholder="Nom"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input
                    name="email"
                    defaultValue={sessionStorage.getItem("email")}
                    required
                    placeholder="Email"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input
                    name="phone"
                    required
                    defaultValue={sessionStorage.getItem("phone")}
                    placeholder="TéléPhone"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input
                    name="subject"
                    required
                    placeholder="sujet"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <textarea
                    required
                    name="message"
                    placeholder="Message"
                    defaultValue={""}
                  />
                </div>
                <button className="submit submit-auto-width" type="submit">
                  Envoyer
                </button>
              </div>
            </div>
          </form>
          <p className="form-messege" />
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
