const NotFound = () => {
  return (
    <div className="error-area">
      <div className="container">
        <div className="row align-items-center height-100vh">
          <div className="col-lg-8 ml-auto mr-auto">
            <div className="error-content text-center">
              <div className="error-logo">
                <a href="/accueil">
                  <img src="/assets/images/logo/logo.png" alt="logo" />
                </a>
              </div>
              <div className="error-img">
                <img src="/assets/images/banner/page-404-image.jpg" alt="" />
              </div>
              <h2> Oups! Cette page est introuvable.</h2>
              <p>
                {" "}
                Il semble que rien n'a été trouvé à cet endroit. Essayez
                peut-être l'un des liens ci-dessous ou une recherche ?
              </p>
              <div className="search-style-4">
                <form action="#">
                  <input type="text" placeholder="Chercher" />
                  <button type="submit"> Chercher </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
