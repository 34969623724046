const Map = () => {
  return (
    <div className="d-none d-md-block contact-map pb-70">
      <div className="d-flex justify-content-center">
        <div className="mapouter m-4">
          <div className="gmap_canvas">
            <p>Casablanca</p>
            <iframe
              width={900}
              height={500}
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3325.340274497096!2d-7.679787284505428!3d33.544534351760355!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62daf39d8f1b3%3A0xc7a1ec4ad7fa9e3f!2sHealthcare%20Facilities%20Consult%20Casa!5e0!3m2!1sen!2sma!4v1649037859552!5m2!1sen!2sma"
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            />
          <hr></hr>
          <p>oujda</p>
            <iframe
              width={900}
              height={500}
              id="gmap_canvas"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13127.729252296585!2d-1.9140971!3d34.6564123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9dd05d987b772c88!2sHealthcare%20Facilities%20Consult%20Oujda!5e0!3m2!1sen!2sma!4v1649037962579!5m2!1sen!2sma"
              frameBorder={0}
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
