import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductsService from "../../../services/product.service";

const NewArrivals = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    ProductsService.newArrivals().then((res) => {
      setProducts(res.data.results[0]);
    });
  }, []);
  return products.length > 0 ? (
    <div className="banner-area pb-45">
      <div className="custom-container">
        <div className="section-title-1">
          <h2>NOUVEAUX ARRIVAGES</h2>
        </div>
        <div className="row mt-4">
          {products.map((item, i) => (
            <div
              key={i}
              className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"
            >
              <div className="banner-wrap wow tmFadeInUp mb-30">
                <div className="banner-img banner-img-zoom">
                  <Link to={`/details-new_produit/${item.name}`}>
                    <img
                      height="280px"
                      src={
                        window.IMAGE_API_URL +
                        `products/${item.image.split(";")[0]}`
                      }
                      alt=""
                    />
                  </Link>
                </div>
                <div className="banner-content-1">
                  <span>{item.category}</span>
                  <h2>{item.name}</h2>
                  <h3>Nouveau</h3>
                  <div className="btn-style-1">
                    <Link
                      className="font-size-14 btn-1-padding-2"
                      to={`/details-new_produit/${item.name}`}
                    >
                      Voir détails{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null;
};

export default NewArrivals;
