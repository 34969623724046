import axios from "axios";
import authHeader from "./auth-header";

const handelSessionStrege = (data) => {
  sessionStorage.setItem("token", data.token);
  localStorage.setItem('token', data.token);
  for (const key in data.user) {
    sessionStorage.setItem(key, `${data.user[key]}`);
    localStorage.setItem(key, `${data.user[key]}`);
  }
  window.location.reload()
};

const login = (email, password) => {
  return axios
    .post(window.API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
        handelSessionStrege(response.data.data);
      }
      return response.data;
    });
};

const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const getCurrentUser = () => {
  // console.log(sessionStorage.getItem("user"));
  const user = {
    user: sessionStorage.getItem("user"),
    avatar: sessionStorage.getItem("avatar"),
    role: sessionStorage.getItem("role"),
    id: sessionStorage.getItem("id_user"),
    token: sessionStorage.getItem("token"),
  };
  return user;
};

const forgotPassword = (data) => {
  return axios.post(window.API_URL + 'ResetPassword', data)
}


const AuthService = {
  login,
  logout,
  getCurrentUser,
  handelSessionStrege,
  forgotPassword
};
export default AuthService;
