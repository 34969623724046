import WhichPage from "../../Init/header/WhichPage";

const GeneralCdt = () => {
  return (
    <>
      <WhichPage page="les conditions générales d'utilisations" />
      <div className="login-register-area pt-75 pb-75">
        <div className="container">
          <div className="row d-flex flex-column justify-content-center">
            <h2> conditions générales d'utilisations</h2>
            <p>
              <br></br>
              Les présentes conditions générales de vente régissent l’ensemble
              des relations entre le client et le site d’e-commerce
              www.healthcarefacilitiesconsult.com représenté par la Société
              Healthcare Facilities Consult SARL (Société spécialisée dans la
              distribution des dispositifs médicaux et des réactifs de
              laboratoire), elles font office de contrat. Toute commande passée
              sur ce site suppose du client son acceptation inconditionnelle et
              irrévocable de ces conditions. Ces conditions sont susceptibles
              d’être modifiées, cependant chaque utilisateur n’est concerné que
              par les conditions en vigueur lors de sa commande. La validation
              d’une commande constitue une signature électronique de la part de
              l’utilisateur par laquelle il s’engage à respecter l’ensemble des
              conditions générales de vente. Le présent contrat est un contrat à
              distance qui a pour objet de définir les droits et obligations des
              parties dans le cadre de la vente des produits de la société
              Healthcare Facilities Consult, sur internet, par l’intermédiaire
              de la plate-forme ECOM du Centre Monétique Interbancaire.
              www.healthcarefacilitiesconsult.com est un service et une marque
              déposée par la Société Healthcare Facilities Consult S.A.R.L. ECOM
              est un service de gestion des transactions et une marque déposée
              par le Centre Monétique Interbancaire (CMI). Prix Les prix
              affichés sur notre site (www.healthcarefacilitiesconsult.com) sont
              en Dirham Marocain et Toutes Taxes Comprises (TTC). Cependant, ils
              ne comprennent pas le prix de la livraison. Le prix total à payer
              est affiché de manière claire pendant la phase finale du processus
              d’achat, l’utilisateur ne valide donc sa commande qu’après avoir
              pris connaissance du montant total à verser. Les tarifs en vigueur
              sont ceux affichés sur notre site au moment de la commande, le
              client n’est donc concerné par aucune promotion, offre ou
              réduction antérieure ou ultérieure à son achat. Disponibilité Les
              offres de produits proposées sur
              www.healthcarefacilitiesconsult.com ne sont valables que dans la
              limite du stock disponible. Si l’un des produits commandés n’est
              pas disponible, le client recevra un e-mail l’informant soit du
              changement du délai de livraison, ou de l’annulation de la
              commande et par conséquent de son remboursement. Il est à signaler
              que l’indisponibilité d’un produit n’engage en aucun cas la
              Société Healthcare Facilities Consult SARL à payer des frais de
              dédommagement au client, ce dernier ne recevra que le
              remboursement de la somme qu’il aura payée. Livraison Healthcare
              Facilities Consult SARL assure la livraison à ses clients à
              l’adresse indiquée lors de leur commande. Le délai de cette
              livraison est fonction de la disponibilité des produits, du mode
              de paiement choisi, ainsi que du type de livraison que
              l’utilisateur a sélectionné. Le délai de livraison est calculé en
              jours ouvrés, cependant il reste indicatif et ne saurait donner
              lieu à des réclamations de la part du client si le retard à partir
              de la date de livraison supposée est inférieur à 7 jours. Au-delà
              de ce délai (délai de livraison + 7 jours) le client peut demander
              l’annulation de sa commande. Le délai de la livraison se calcule à
              partir du moment où la commande est validée, c'est-à-dire après
              réception du règlement et non pas avant. Si le client a décidé
              d’annuler sa commande pour un retard de livraison excédent les
              délais sus cités, il sera remboursé. Le prix de la livraison
              dépend du poids des produits achetés, du montant total de la
              commande ainsi que du type de livraison que l’utilisateur a
              choisi. Ce montant est clairement affiché sur la facture de
              l’acheteur. Pour les habitants des villes de Casablanca et Oujda,
              la livraison se fait moyennant une somme de 20,00 DH TTC. Au
              moment de la réception de la marchandise, le client est tenu de
              vérifier les produits qu’il a achetés et de signaler tout dommage
              ou non-conformité constatée à travers une réclamation adressée par
              mail à l’adresse suivante :
              service.client@healthcarefacilitiesconsult.com dans un délai de 48
              heures. Paiement Le client possède cinq modes de règlement pour
              payer ses achats : le paiement par carte bancaire, le virement
              bancaire, le paiement par dépôt d'espèces dans une agence
              bancaire, le paiement en contre-remboursement (à la livraison) et
              le paiement par chèque bancaire certifié. Ces cinq modes de
              paiement sont régis par les lois marocaines et toute tentative de
              fraude est sévèrement sanctionnée par les lois en vigueur. Dans le
              cas de paiement par carte bancaire, la remise de la transaction
              pour débit de votre compte est effectuée dans la journée qui suit
              la date de la confirmation de livraison. Vos paiements
              Multi-canaux sont sécurisés par le Centre Monétique Interbancaire
              (CMI) qui offre un service de paiement entièrement sécurisé. Le
              Consommateur garantit à la Société Healthcare Facilities Consult
              qu’il dispose des autorisations éventuellement nécessaires pour
              utiliser le mode de paiement choisi par lui, lors de la validation
              de sa commande. En cas de paiement par carte bancaire, les
              dispositions relatives à l’utilisation frauduleuse du moyen de
              paiement prévues dans les conventions conclues entre le
              Consommateur et l’émetteur de la carte entre la Société Healthcare
              Facilities Consult et son établissement bancaire s’appliquent. Ce
              paiement peut être effectué par une carte bancaire marocaine ou
              étrangère. Les données personnelles de l’utilisateur sont cryptées
              lors de la transaction sur la plateforme Ecom pour garantir la
              plus stricte confidentialité. Le client est tenu donc de
              communiquer des informations sincères et avérées, toute fausse
              information est considérée comme frauduleuse et pourrait engendrer
              l’annulation de la commande et faire objet de poursuites
              judiciaires. Par ailleurs, il se porte garant auprès du site qu’il
              dispose des autorisations nécessaires pour utiliser ce mode de
              paiement. Le système de paiement par carte bancaire est certifié
              par le CMI, VISA, MasterCard et l’ensemble des banques marocaines.
              En cas de paiement par carte bancaire, le client peut se voir
              demander des pièces justificatives afin de vérifier son identité
              et afin de prévenir toute tentative de fraude. Le paiement par
              virement bancaire ne permet pas de réserver les produits. La
              commande n'est validée qu'après réception du paiement. Au-delà
              d'un délai de 15 jours, si la Société Healthcare Facilities
              Consult ne reçoit pas de virement au compte suivant : (RIB: 011
              780 0000682100010661 13 – Banque : BANK OF AFRICA : Agence Roches
              Noires - 73, Rue Jules Cesar - Casablanca) , la commande est
              annulée. Le client devrait prendre en considération qu’un virement
              bancaire nécessite au moins 48 heures pour que l’argent soit
              transféré d’un compte à un autre. Pour des raisons pratiques,
              l’utilisateur devra indiquer en référence de son virement le
              numéro de sa commande. La validation d’une commande réglée par
              virement bancaire ne se fait qu’après réception de ce dernier. Le
              paiement par dépôt d'espèces dans une agence BANK OF AFRICA ne
              permet pas au client de réserver les produits de sa commande.
              Cette dernière n'est effective qu'après réception du paiement. Le
              client devra envoyer le montant correspondant à sa commande au
              compte suivant : (N° de compte: (011 780 0000682100010661 13 -
              Banque : BANK OF AFRICA). Au-delà d'un délai de 15 jours et en cas
              de non réception du règlement, la commande est systématiquement
              annulée. Un dépôt d'espèces prend en général 24 heures pour
              arriver au compte du bénéficiaire. Il est conseillé de mentionner
              la référence de la commande au moment du dépôt d'espèces auprès de
              la banque. La validation ne se fait qu’après réception du
              paiement, après quoi la marchandise est expédiée à son acheteur.
              Toute commande avec paiement en contre-remboursement (à la
              livraison) est soigneusement vérifiée par le service de contrôle
              de fraudes de Healthcare Facilities Consult, cela nécessite donc
              un temps de traitement avant validation. Une fois validée, elle
              est expédiée au client qui devra impérativement régler le montant
              de ses achats en espèces au facteur. En cas de refus de paiement
              de la part du client, la société se réserve le droit de le
              poursuivre en justice. Ce mode de paiement n'est pas applicable à
              l'ensemble des commandes mais uniquement à certains produits et
              pour des montants n'excédant pas 5000,00 DH TTC pour l'ensemble de
              la commande. Le paiement par chèque bancaire à la livraison est
              accepté à condition qu'il soit certifié par la banque du client.
              Le chèque en question doit être libellé à l'ordre de la société
              Healthcare Facilities Consult. Il est conseillé de mentionner la
              référence de la commande au dos du chèque. Le traitement des
              commandes payées par chèque bancaire peut prendre du temps. En
              effet, une fois la commande effectuée, le client devra nous faire
              parvenir, par mail ou par fax, une copie du chèque certifié. Une
              fois cette copie reçue, la commande est validée, puis expédiée
              dans les plus brefs délais. Le chèque doit être remis au livreur
              au moment de la livraison. Notez bien que les produits de la
              commande ne peuvent être réservés qu'à partir du moment de la
              réception de la copie du chèque. Il est à signaler que nous
              n'acceptons pas les chèques non certifiés, que le chèque doit
              absolument être libellé à l'ordre de la Société Healthcare
              Facilities Consult et qu'il doit être remis au livreur au moment
              même de la livraison sinon le colis ne peut pas être délivré.
              Preuve des transactions payées par carte bancaire Les données
              enregistrées par le CMI sur la plate-forme de paiement Multi-canal
              pour le compte de la Société Healthcare Facilities Consult
              constituent la preuve de l’ensemble des transactions commerciales
              passées entre le client et la société Healthcare Facilities
              Consult sur le site www.healthcarefacilitiesconsult.com. Garantie
              Tous les produits vendus sur notre site bénéficient d’une garantie
              constructeur qui diffère selon le type et la marque de chaque
              produit. Fiche produit Nous nous efforçons de mettre des fiches
              produits renseignées au maximum pour vous présenter chacun de nos
              produits. Cependant, les images fournies sur le site ne sont pas
              contractuelles, elles sont données à titre indicatif pour
              illustrer de la manière la plus ressemblante qui soit un produit.
              Seules les informations contenues dans le titre, la description
              courte et la fiche technique du produit engagent la responsabilité
              de la société vis-à-vis du client. Droit de rétractation Selon la
              loi, le client a un délai de rétraction de 7 jours à compter du
              jour de la réception du colis. Á cet effet, chaque client est tenu
              de nous communiquer par email le jour même la date de réception du
              dit colis, le cas échéant, nous considérerons qu’il a été reçu 2
              jours après la date de son expédition. Lorsque le délai de sept
              jours expire un samedi, un dimanche ou un jour férié ou chômé, il
              est prolongé jusqu'au premier jour ouvrable suivant. Cependant, ce
              droit à la rétraction est soumis à certaines conditions : - Le
              produit ne doit pas avoir été ouvert ou déballé, il doit être
              intact tel qu’il a été envoyé, autrement il n’est plus
              remboursable. - Les frais d’envoi et de retour sont à la charge du
              client. - Dans le cas du paiement par carte bancaire, des frais de
              traitement sont prélevés sur le montant à rembourser pouvant
              atteindre 2,5% du montant de la commande. Le remboursement se fait
              dans un délai de 15 jours maximum. Les codes promotionnels
              Healthcare Facilities Consult propose régulièrement des codes
              promotionnels dans la presse et chez ses partenaires afin que ses
              clients puissent bénéficier de réductions sur leurs achats. Un
              code n’est utilisable qu’une seule fois par personne et n’est en
              aucun cas remboursable ou cumulable. Les codes promotionnels ne
              sont valables que pendant la période indiquée sur la publicité qui
              les accompagne. Afin de profiter de la réduction attribuée à un
              code, il est indispensable de l’inscrire dans la case réservée à
              cet effet lors de la commande sur notre site. Un code n’est donc
              pas utilisable après validation d’une commande. Toute réclamation
              dans ce sens ne serait donc pas prise en compte. Protection des
              données personnelles Le client est tenu de fournir des
              informations sincères et exactes lors de son inscription. Toute
              fausse information entraîne une annulation de la commande. Les
              données personnelles des clients sont utilisées dans la plus
              stricte confidentialité lors du traitement de la commande. Seuls
              les employés de la Société Healthcare Facilities Consult y ont
              accès. Les informations recueillies sur le site
              www.healthcarefacilitiesconsult.com font l’objet d’un traitement
              destiné à la livraison des produits aux clients. Les destinataires
              des données sont : la société Healthcare Facilities Consult qui
              gère le site www.healthcarefacilitiesconsult.com Ce traitement a
              été notifié et autorisé par la CNDP au titre du récépissé N°
              …………., du ../../2021. Healthcare Facilities Consult pourrait
              éventuellement utiliser ces données lors de campagnes
              publicitaires afin de communiquer aux clients ses différentes
              offres et promotions spéciales. Le formulaire de contact mis à la
              disposition de nos clients dans la rubrique "contactez-nous" a
              pour objet de répondre aux demandes d'informations de nos clients
              actuels ou potentiels avec pour objectif final de faciliter
              l'opération de vente en ligne. Les données recueillies par le
              biais de ce formulaire ne sont nullement destinées à la
              prospection commerciale. Ce traitement a été notifié et autorisé
              par la CNDP au titre du récépissé N° ………. Conformément à la loi n°
              09-08 promulguée par le Dahir 1-09-15 du 18 février 2009, relative
              à la protection des personnes physiques à l'égard du traitement
              des données à caractère personnel, vous bénéficiez d’un droit
              d’accès et de rectification aux informations qui vous concernent,
              que vous pouvez exercer en vous adressant à notre service client
              en appelant le 0520 333 012. Vous pouvez également, pour des
              motifs légitimes, vous opposer à ce que les données qui vous
              concernent fassent l’objet d’un traitement. Notre base de données
              fait l'objet d'un transfert chez l'hébergeur de notre site web. Ce
              traitement a été notifié et autorisé par la CNDP au titre du
              récépissé n° ……….., du ../../2021. Propriété Intellectuelle
              L’ensemble du contenu de Healthcare Facilities Consult (textes,
              images, vidéos, … etc.) est propriété de la Société Healthcare
              Facilities Consult. Toute tentative de reproduction est considérée
              comme étant illégale et frauduleuse, elle ferait donc objet de
              poursuites judiciaires. Force majeure La Société Healthcare
              Facilities Consult n’est tenue pour l’exécution de ses obligations
              que dans la mesure où aucun événement de force majeure ne vient
              les entraver.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralCdt;
