import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const ProductCard = ({
    product, url
}) => (
    <div className="product-plr-1" style={{
      maxHeight : '100%',
      width : '100%',
      maxWidth : 300
    }}>
    <div className="single-product-wrap">
      <div className="product-img-action-wrap categories-wrap mb-20">
        <div className="categories-img categories-img-zoom">
          <Link to={`/${url}/${product.name}`}>
             <img
              width="auto"
              className="default-img"
              src={
                window.IMAGE_API_URL +
                `products/${product.image.split(";")[0]}`
              }
              alt=""
              style={{
                width: "100%" ,
                height: "100%",
                objectFit: "contain",
                borderRadius : 3
              }}
            /> 
          </Link>
        </div>
        <div className="categories-content text-center">
          <h3>
            <Link to={`/${url}/${product.name}`}>
              {product.name}
            </Link>
          </h3>
        </div>
        <div style={{display : 'flex', width : '100%', flexDirection : 'column'}}>
            <div className="product-price">
              <span style={{ wordBreak: "break-all"}} className="new-price">
                <b style={{alignSelf : 'center'}}>
                {
                  product.tarif > 0 ?
                  (
                  product?.offer_pourcentage ?    
                  `${parseFloat(parseFloat(product.tarif) - ((parseFloat(product.tarif) * parseInt(product?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                  `${parseFloat(product.tarif).toFixed(2)} DHS TTC`
                  ) :
                  'demande de prix'
                }
                </b>
              </span>
              {
              product?.offer_pourcentage ?
              <span style={{
                color: 'rgb(168, 160, 149)',
                textDecorationColor: 'initial',
                textDecorationLine: 'line-through',
                textDecorationThickness: 'initial',
                textDecorationStyle: 'initial',
                marginLeft : 5
              }}>{parseFloat(product.tarif).toFixed(2)}</span> :
              <></>
              }
            </div>
            {
              product?.offer_pourcentage ?
              <div style={{
                margin: "0 0 10px",
                display : 'flex',
                justifyContent : 'center'
              }}>
                <span style={{
                  marginRight : 3,
                  backgroundColor: '#e4573d',
                  display: 'inline-block',
                  fontSize: '13px',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  lineHeight: 1,
                  borderRadius: '2px',
                  color: '#ffffff',
                  marginRight: '10px',
                  padding: '5px 8px 6px'
                }}> {`SALE -${product?.offer_pourcentage}%`}</span>
              </div> :<></>
            }
          </div>
      </div>
    </div>
  </div>
)

const ProductSliders = ({
    products,
    BaseUrl
}) => {

  const sliderToShow = products.length > 5 ? 5 : products.length;
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: sliderToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: products.length > 3 ? 3 : products.length,
          slidesToScroll: products.length > 3 ? 3 : products.length
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: products.length > 2 ? 2 : products.length,
          slidesToScroll: products.length > 2 ? 2 : products.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="categories-area pb-70">
      <div className="custom-container">
        <Slider {...settings} className="product-slider-active-1">
          {products.map((val, index) => (
            <ProductCard key={index} product={val} url={BaseUrl}/>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ProductSliders;
