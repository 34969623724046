import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandService from "../../../services/brands.service";

const BrandsBanner = () => {
  // window.IMAGE_API_URL
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    BrandService.allBrands().then((res) => {
      setBrands(res.data.results);
    });
  }, []);

  return (
    <div className="brand-logo-area pb-35">
      <div className="custom-container">
        <div className="section-title-1 wow tmFadeInUp mb-30">
          <h2>PAR MARQUES</h2>
        </div>
        <div className="row align-items-center wow tmFadeInUp">
          {brands.map((brand, index) => (
            <div key={index} className="col-lg-2 col-md-4 col-6 col-sm-4">
              <div className="single-brand-logo mb-30">
                <Link to={`boutique/m:${brand.name}`}>
                  <img
                    src={`${window.IMAGE_API_URL}marques/${brand.image}`}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandsBanner;
