import axios from "axios";

const allSliders = () => {
  return axios.get(window.API_URL + "sliders");
};
const subSliders = () => {
  return axios.get(window.API_URL + "sub_sliders");
};

const SliderService = {
  allSliders,
  subSliders,
};
export default SliderService;
