import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.IMAGE_API_URL = "https://healthcarefacilitiesconsult.com/api/uploads/images/";
window.API_URL = "https://healthcarefacilitiesconsult.com/api/";
window.socket_API_URL = "https://healthcarefacilitiesconsult.com/api";
window.CATALOGUE_URL = "https://healthcarefacilitiesconsult.com/api/catalogue/pdf/";

// window.IMAGE_API_URL = "http://localhost:3001/api/uploads/images/";
// window.API_URL = "http://localhost:3001/api/";
// window.socket_API_URL = "http://localhost:3001/api";
// window.CATALOGUE_URL = "http://localhost:3001/catalogue/pdf/";

var compares = [];
localStorage.app = JSON.stringify(compares);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
