import { useEffect, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import {
  getPanier,
  DeletePanier,
  UpdatePanier,
  CreateOrder
} from "../../../services/panier";
import WhichPage from "../../Init/header/WhichPage";
import { useHistory } from "react-router-dom";

const Panier = () => {
  const history = useHistory();
  const ids = [];
  const [Items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const updateQte = (id, qte) => {
    UpdatePanier({
      panier_id : id,
      quantity : qte
    }).then((res) => setItems((old) => old.map(val => val.panier_id === id ? {...val, quantity : qte} : val)))
  };

  const removeItem = (id) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#163C74",
      cancelButtonColor: "#0E9B4A",
      confirmButtonText: "Oui, supprimez-le",
    }).then((result) => {
      if (result.isConfirmed) {
        DeletePanier(id).then((res) => {
          setItems((old) => old.filter((val) => val?.panier_id !== id))
          Swal.fire("Supprimé !", "Product has been deleted.", "success")
        });
      }
    });
  };

  const SendOrder = () => {
    if (!total)
      return NotificationManager.error("you must select at least one product !!");
    Swal.fire({
      title: "Etes-vous sûr ?",
      text: `total : ${parseFloat(parseFloat(total) + parseInt(total >= 1000 ? 0 : 35)).toFixed(2)} DH`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#163C74",
      cancelButtonColor: "#0E9B4A",
      confirmButtonText: "Oui, order-le",
    }).then((result) => {
      if (result.isConfirmed) {
        CreateOrder({
          products : Items.filter((val) => val?.checked)
        }).then((res) => (NotificationManager.success("you order it's been created successfully !!"),(history.push(`/Profil?order_id=${res.data?.data[0]?.order_id}#command`))))
        .catch((err) => NotificationManager.error(err?.response?.data?.error || err?.error || "something went wrong !!"))
      }
    });
  }

  const laodData = () => {
    setLoading(true);
    getPanier().then(res => (setItems(res.data.data),setLoading(false),NotificationManager.success(`${res.data?.data?.length || 0} element`)));
  };

  useEffect(() => {
    laodData();
  }, []);

  useEffect(() => 
  setTotal(
    Items.reduce(
      (prev, val) => val.checked ? 
      parseFloat(parseFloat(prev) + (
        val.offer_pourcentage ?
        (parseFloat((parseFloat(val.tarif) - ((parseFloat(val.tarif) * parseInt(val.offer_pourcentage)) / 100)) * parseInt(val.quantity)))
        : (parseFloat(val.tarif) * parseInt(val.quantity))
      )).toFixed(2)
      : prev,
    0)), [Items]);
  
  const SelectColorImg = (order) => {
    try {
      let ColorSize = order.ColorSize ? JSON.parse(order.ColorSize) : [];
      let ImagePath =
      ColorSize.reduce((prev, color) => 
      !prev ? ((color.color === order.color && color.size === order.size) ? color.image : prev) : prev, null);
      if (ImagePath)
        return `${window.API_URL}${ImagePath.replace(/^\//, '')}`;
    }catch (e){
    }
    return `${window.IMAGE_API_URL}${"products/" + order?.image?.split(";")[0]}`;
  }

  return (
    <>
      <WhichPage page="Panier" />
      <div className="cart-area pt-75 pb-35">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form action="#">
                <div className="cart-table-content text-center" style={{overflow : 'auto'}}>
                  <div className="table-content table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="width-thumbnail">
                            Select
                          </th>
                          <th className="width-thumbnail">
                          </th>
                          <th className="width-thumbnail">Product</th>
                          <th className="width-thumbnail">Name</th>
                          <th className="width-thumbnail">Prix</th>
                          <th className="width-quantity">Quantity</th>
                          <th className="width-quantity">Color</th>
                          <th className="width-quantity">Size</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center m-5" colSpan="5">
                              <BeatLoader
                                color={"#163C74"}
                                loading={loading}
                                size={30}
                              />
                            </td>
                          </tr>
                        ) : (
                          Items.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <input
                                  type="checkbox"
                                  value={item.checked ? true : false}
                                  style={{width : 20}}
                                  onChange={(e) => setItems((old) => old.map((val, ind) => ind === i ? {...val, checked : e.target.checked} : val))}
                                  />
                                </td>
                                <td>
                                  <div className="form-check">
                                    <Link
                                      to="#"
                                      onClick={() => removeItem(item.panier_id)}
                                    >
                                      <i className="far fa-times" />
                                    </Link>
                                  </div>
                                </td>
                                <td className="product-thumbnail">
                                  <Link to={`/details-produit/${item.name}`}>
                                    <img
                                      src={SelectColorImg(item)}
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td className="product-name">
                                  <h5>
                                    <Link
                                      to={`/details-produit/${item.name}`}
                                    >
                                      {item.name}
                                    </Link>
                                  </h5>
                                </td>
                                <td>
                                  <div>
                                    <span>
                                    <b>{
                                      (
                                        item?.offer_pourcentage ?    
                                        `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                                        `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                                        )
                                    }</b>
                                    </span>
                                    {
                                      item?.offer_pourcentage ?  
                                      <span style={{
                                        color: 'rgb(168, 160, 149)',
                                        textDecorationColor: 'initial',
                                        textDecorationLine: 'line-through',
                                        textDecorationThickness: 'initial',
                                        textDecorationStyle: 'initial',
                                        marginLeft : 5
                                      }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                                    }
                                  </div>
                                </td>
                                <td className="cart-quality">
                                  <div className="product-quality w-100">
                                    <input
                                      className="cart-plus-minus-box input-text qty text"
                                      name="qtybutton"
                                      type="number"
                                      min="1"
                                      step="1"
                                      value={item.quantity}
                                      max={JSON.parse(item?.ColorSize || '[]')
                                      .reduce((prev, color) =>
                                      !prev ? ((item.color === color.color && item.size === color.size) ? color.quantity : prev) : prev , 0)}
                                      onChange={(e) => ((parseInt(e.target.value) <= parseInt(e.target.max)) && updateQte(item.panier_id, e.target.value))}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <p>
                                      {item.color}
                                  </p>
                                </td>
                                <td>
                                  <p>
                                      {item.size}
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <p style={{fontSize : 15, color : 'grey', wordWrap : 'break-word'}}>Livraisons gratuites pour les commandes supérieures à 1000 DH, et 35 DH pour les commandes inférieures.</p>
                  </div>
                  <div style={{display : 'flex', justifyContent : 'space-between'}}>
                    <div />
                    <div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"sub-total :"}</p>
                        <p style={{fontWeight : 'bold'}}>{total} DH</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Livraison :"}</p>
                        <p style={{fontWeight : 'bold'}}>{total >= 1000 ? 'gratuit' : '35 DH'}</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Total :"}</p>
                        <p style={{fontWeight : 'bold'}}>{parseFloat(parseFloat(total) + parseInt(total >= 1000 ? 0 : 35)).toFixed(2)} DH</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Items : "}</p>
                        <p style={{fontWeight : 'bold'}}>{Items.reduce((prev, val) => val?.checked ? prev + 1 : prev, 0)} Item</p>
                      </div>
                    </div>
                  </div>
                  <div className="row cart-shiping-update-wrapper">
                    <div className="col-12 col-md-6 continure-clear-btn">
                      <div className="continure-btn ml-4">
                        <Link to="/boutique">revenir à la boutique</Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 update-btn text-right">
                      <div style={{
                          display: "inline-block",
                          fontSize: 15,
                          fontWeight: 700,
                          color: "#ffffff",
                          borderRadius: 26,
                          padding: "13px 36px",
                          backgroundColor: "#2f4e81",
                          cursor : 'pointer'
                      }}
                      onClick={SendOrder}
                      >
                        Create Order
                      </div>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Panier;
