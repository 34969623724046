import React, { useEffect, useState, useRef } from "react";
import WhichPage from "../../Init/header/WhichPage";
import ContactForm from "./ContactForm";
const Complaints = () => {
  
  return (
    <div className="main-wrapper">
      <WhichPage page=" Contact" />
      <div className="contact-us-area pt-65 pb-55">
        <div className="container">
          <div className="section-title-2 mb-45 wow tmFadeInUp">
            <h2>N'hésitez pas à réclamer quoi que ce soit</h2>
            <p>Vous pouvez réclamer tous les problèmes que vous rencontrez </p>
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Complaints;
