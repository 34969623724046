import axios from "axios";

const allProducts = () => {
  return axios.get(window.API_URL + `products`);
};

const getProductDetailSlider = ({
  product_id
}) => {
  return axios.get(window.API_URL + `detail-slider`, {
    params : {
      product_id
    }
  });
};

const allProductEtonants = () => {
  return axios.get(`${window.API_URL}/product_plus_etonnants`);
}

const allProductCovid = () => {
  return axios.get(`${window.API_URL}/product_mnt_special`);
}

const productById = (id) => {
  return axios.get(window.API_URL + `product/${id}`);
};

const productsByCat = (id) => {
  return axios.get(window.API_URL + `products/category/${id}`);
};
const productsBySubCat = (id) => {
  return axios.get(window.API_URL + `products/subcat/${id}`);
};

const productsByFamily = (id) => {
  return axios.get(window.API_URL + `products/famille/${id}`);
};

const productsByBrands = (id) => {
  return axios.get(window.API_URL + `products/brand/${id}`);
};

const productsByCatName = (name) => {
  return axios.post(window.API_URL + `product_catg`, { name: name });
};
const productsBySubCatName = (name) => {
  return axios.post(window.API_URL + `product_subcat`, { name: name });
};

const productsByFamilyName = (name) => {
  return axios.post(window.API_URL + `products_famille`, { name: name });
};

const productsByBrandName = (name) => {
  return axios.post(window.API_URL + `product_brand`, { name: name });
};

const productsInSold = () => {
  return axios.get(window.API_URL + `products_solde`);
};

const bestSelling = () => {
  return axios.get(window.API_URL + `hot_iteams`);
};

const promoDeJour = () => {
  return axios.get(window.API_URL + "promo_jour");
};
const newArrivals = () => {
  return axios.get(window.API_URL + `new_arrival`);
};

const AmazingProducts = () => {
  return axios.get(window.API_URL + `/amazing/products`);
};

const onSearch = (name) => {
  return axios.post(window.API_URL + `product_search`, { name: name });
};

const getNewProductById = (id) => {
  return axios.get(window.API_URL + `new_arrival/` + id);
};

const getAmazingProductById = (id) => {
  return axios.get(window.API_URL + `amazing/products/` + id);
};

const allCovidProducts = () => {
  return axios.get(window.API_URL + `product_mnt_special`);
};
const totalProductNumber = () => {
  return axios.get(window.API_URL + `productsNumber`);
};
const productsByPage = (data) => {
  return axios.post(window.API_URL + `products`, data);
};

const productsByPageSearch = (data) => {
  return axios.get(window.API_URL + `products/search`, {
    params : data
  });
};

const newproductsBySearch = (name) => {
  return axios.get(window.API_URL + `new_arrival/search/${name}`);
};

const AmazingproductsBySearch = (name) => {
  return axios.get(window.API_URL + `amazing_products/search/${name}`);
};

const ProductsService = {
  allProducts,
  getProductDetailSlider,
  productsBySubCat,
  productsByCat,
  productsInSold,
  productsByFamily,
  productsByBrands,
  productById,
  newArrivals,
  productsByCatName,
  productsByFamilyName,
  productsBySubCatName,
  productsByBrandName,
  promoDeJour,
  onSearch,
  bestSelling,
  getNewProductById,
  allCovidProducts,
  totalProductNumber,
  productsByPage,
  AmazingProducts,
  getAmazingProductById,
  productsByPageSearch,
  allProductEtonants,
  allProductCovid,
  newproductsBySearch,
  AmazingproductsBySearch
};

export default ProductsService;
