import { useEffect, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import {
  CreateCommandImediat
} from "../../../services/panier";
import ProductServices from '../../../services/product.service'
import WhichPage from "../../Init/header/WhichPage";
import { useHistory } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const AchatImediat = () => {
  const [orderInfo, setorderInfo] = useState({
    firstName : '',
    lastName: '',
    phone: '',
    email: '',
    product_id: null,
    quantity: 1,
    product_type: '',
    color : '',
    size : '',
  });
  const [total, setTotal] = useState(0);
  const [Items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const SendOrder = () => {
    if (!Items.length)
      return NotificationManager.error("product not exist !!");
    if (!JSON.parse(Items[0].ColorSize || '[]').reduce(
    (prev, current) => (!prev && current.size === orderInfo.size && current.color === orderInfo.color) ? current : prev,
    false
    ))
      return NotificationManager.error("color/size not found !!");
    Swal.fire({
      title: "Etes-vous sûr ?",
      text: `total : ${parseFloat(total + (total >= 1000 ? 0 : 35)).toFixed(2)} DH`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#163C74",
      cancelButtonColor: "#0E9B4A",
      confirmButtonText: "Oui, order-le",
    }).then((result) => {
      if (result.isConfirmed) {
        CreateCommandImediat(orderInfo).then((res) => (
          setorderInfo({
            firstName : '',
            lastName: '',
            phone: '',
            email: ''
          }),
          NotificationManager.success(res?.data?.message || "you order it's been created successfully !!")))
        .catch((err) => NotificationManager.error(err?.response?.data?.error || err?.response?.data?.message || err?.error || "something went wrong !!"))
      }
    });
  }

  const loadData = () => {
    setLoading(false);
    let [
        color,
        size,
        product_type,
        product_id
    ] = [_GET('color') || '', _GET('size') || '', _GET('type') || 'product', _GET('product_id')];
    if (!product_id || !/^[\d]{1,100}$/.test(product_id))
        return NotificationManager.error(`Product not found !!`);
    let onSuccess = (res) => 
    (setItems(res.data.data || res.data.result),setLoading(false),NotificationManager.success(`Product loaded successfully`),setorderInfo({...orderInfo,color,size,product_type,product_id}))
    let onFailure = (res) => 
    (setLoading(false),NotificationManager.error(res?.response?.data?.error || res?.response?.data?.message || `Product not found !!`))
    try {
        if (product_type === 'arrival') ProductServices.getNewProductById(product_id).then(onSuccess).catch(onFailure);
        else if (product_type === 'offer_combinee') ProductServices.getAmazingProductById(product_id).then(onSuccess).catch(onFailure);
        else if (product_type === 'product') ProductServices.productById(product_id).then(onSuccess).catch(onFailure);
        else return NotificationManager.error("product not found !!");
    } catch(err) {
        onFailure(err);
    }
  };

  useEffect(() => {
    if (Items.length) {
      setTotal(Items[0]?.offer_pourcentage ?
      (
        (parseFloat(Items[0]?.tarif) - ((parseFloat(Items[0]?.tarif) * parseInt(Items[0]?.offer_pourcentage)) / 100)) * orderInfo.quantity
      ) :
      Items[0]?.tarif * orderInfo.quantity
      ) 
    }
  },[Items, orderInfo]);

  useEffect(() => {
    loadData();
  }, []);
  
  const SelectColorImg = (order) => {
    try {
      let ColorSize = order.ColorSize ? JSON.parse(order.ColorSize) : [];
      let ImagePath =
      ColorSize.reduce((prev, color) => 
      !prev ? ((color.color === order.color && color.size === order.size) ? color.image : prev) : prev, null);
      if (ImagePath)
        return `${window.API_URL}${ImagePath.replace(/^\//, '')}`;
    }catch (e){
    }
    return `${window.IMAGE_API_URL}${"products/" + order?.image?.split(";")[0]}`;
  }

  let _GET = (param) => urlSearchParams.get(param)

  return (
    <>
      <WhichPage page="Panier" />
      <div className="cart-area pt-75 pb-35">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form action="#">
                <div className="cart-table-content text-center" style={{overflow : 'auto'}}>
                  <div className="table-content table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th className="width-thumbnail">Product</th>
                          <th className="width-thumbnail">Name</th>
                          <th className="width-thumbnail">Price</th>
                          <th className="width-quantity">Quantity</th>
                          <th className="width-quantity">Size</th>
                          <th className="width-quantity">Color</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center m-5" colSpan="5">
                              <BeatLoader
                                color={"#163C74"}
                                loading={loading}
                                size={30}
                              />
                            </td>
                          </tr>
                        ) : (
                          Items.map((item, i) => {
                            return (
                              <tr key={i}>
                                <td className="product-thumbnail">
                                  <Link to={orderInfo.product_type === 'product' ? `/details-produit/${item.name}` :
                                  (orderInfo.product_type === 'arrival' ?`/details-new_produit/${item.name}` : 
                                  `/offer_combinees/${item.name}`
                                  )
                                }>
                                    <img
                                      src={SelectColorImg(item)}
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td className="product-name">
                                  <h5>
                                    <Link
                                      to={
                                        orderInfo.product_type === 'product' ? `/details-produit/${item.name}` :
                                        (orderInfo.product_type === 'arrival' ?`/details-new_produit/${item.name}` : 
                                        `/offer_combinees/${item.name}`
                                        )
                                      }>
                                      {item.name}
                                    </Link>
                                  </h5>
                                </td>
                                <td>
                                <div>
                                    <span>
                                    <b>{
                                      (
                                        item?.offer_pourcentage ?    
                                        `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                                        `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                                        )
                                    }</b>
                                    </span>
                                    {
                                      item?.offer_pourcentage ?  
                                      <span style={{
                                        color: 'rgb(168, 160, 149)',
                                        textDecorationColor: 'initial',
                                        textDecorationLine: 'line-through',
                                        textDecorationThickness: 'initial',
                                        textDecorationStyle: 'initial',
                                        marginLeft : 5
                                      }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                                    }
                                  </div>
                                </td>
                                <td className="cart-quality">
                                  <div className="product-quality w-100">
                                    <input
                                      className="cart-plus-minus-box input-text qty text"
                                      name="qtybutton"
                                      type="number"
                                      min="1"
                                      step="1"
                                      value={orderInfo.quantity}
                                      max={item.quantite}
                                      onChange={(e) => (e.target.value <= item.quantite && setorderInfo({...orderInfo, quantity : e.target.value}))}
                                    />
                                  </div>
                                </td>
                                <td>
                                    <Box sx={{ minWidth: 80 }}>
                                        <FormControl>
                                        <InputLabel id="demo-simple-select-label">Size</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{fontSize : 15, minWidth : 100}}
                                            value={orderInfo.size || ''}
                                            label="size"
                                            onChange={(event) => setorderInfo({...orderInfo, size : (event.target.value || ''), color : ''})}
                                        >
                                            <MenuItem value={''}>None</MenuItem>
                                            {
                                                JSON.parse(item.ColorSize || '[]').map((val, ind) => 
                                                    <MenuItem key={ind} value={val.size}>{val.size}</MenuItem>
                                                )
                                            }
                                        </Select>
                                        </FormControl>
                                    </Box>
                                </td>
                                <td>
                                    <Box sx={{ minWidth: 80 }}>
                                        <FormControl>
                                        <InputLabel id="demo-simple-select-label">Color</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            style={{fontSize : 15, minWidth : 100}}
                                            value={orderInfo.color || ''}
                                            label="color"
                                            onChange={(event) => setorderInfo({...orderInfo, color : (event.target.value || '')})}
                                        >
                                            <MenuItem value={''}>None</MenuItem>
                                            {
                                                JSON.parse(item.ColorSize || '[]').map((val, ind) => 
                                                    val.size === orderInfo.size ?
                                                    <MenuItem key={ind} value={val.color}>{val.color}</MenuItem>
                                                    : []
                                                )
                                            }
                                        </Select>
                                        </FormControl>
                                    </Box>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                    <div style={{fontSize : 24, fontWeight : 'bold', padding : 10}}>
                        le destinataire Informations 
                    </div>
                    <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                        <div style={{padding : 10}}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Nom"
                                value={orderInfo?.firstName}
                                onChange={(e) => setorderInfo({...orderInfo, firstName : e.target.value})}
                                type={'text'}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Prenom"
                                type={'text'}
                                value={orderInfo?.lastName}
                                onChange={(e) => setorderInfo({...orderInfo, lastName : e.target.value})}
                            />
                        </div>
                        <div style={{padding : 10}}>
                            <TextField
                                required
                                id="outlined-required"
                                label="Phone"
                                type={'text'}
                                value={orderInfo?.phone}
                                onChange={(e) => setorderInfo({...orderInfo, phone : e.target.value})}
                            />
                            <TextField
                                required
                                id="outlined-required"
                                label="Email"
                                type={'email'}
                                value={orderInfo?.email}
                                onChange={(e) => setorderInfo({...orderInfo, email : e.target.value})}
                            />
                        </div>
                    </Box>
                    <div>
                      <p style={{fontSize : 15, color : 'grey', wordWrap : 'break-word'}}>Livraisons gratuites pour les commandes supérieures à 1000 DH, et 35 DH pour les commandes inférieures.</p>
                    </div>
                    <div style={{display : 'flex', justifyContent : 'space-between'}}>
                    <div />
                    <div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"sub-total : "}</p>
                        <p style={{fontWeight : 'bold'}}>{parseFloat(total).toFixed(2)} DH</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"shipping : "}</p>
                        <p style={{fontWeight : 'bold'}}>{(total >= 1000 ? 'gratuit' : '35 DH') || 0}</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"total : "}</p>
                        <p style={{fontWeight : 'bold'}}>{parseFloat(parseFloat(total) + (total >= 1000 ? 0 : 35)).toFixed(2)} DH</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Quantity : "}</p>
                        <p style={{fontWeight : 'bold'}}>{orderInfo?.quantity || 0} Item</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Color : "}</p>
                        <p style={{fontWeight : 'bold'}}>{orderInfo?.color}</p>
                      </div>
                      <div style={{display : 'flex', fontSize : 30}}>
                        <p>{"Size : "}</p>
                        <p style={{fontWeight : 'bold'}}>{orderInfo?.size}</p>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="row cart-shiping-update-wrapper">
                    <div className="col-12 col-md-6 continure-clear-btn">
                      <div className="continure-btn ml-4">
                        <Link to="/boutique">revenir à la boutique</Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 update-btn text-right">
                      <div style={{
                          display: "inline-block",
                          fontSize: 15,
                          fontWeight: 700,
                          color: "#ffffff",
                          borderRadius: 26,
                          padding: "13px 36px",
                          backgroundColor: "#2f4e81",
                          cursor : 'pointer'
                      }}
                      onClick={SendOrder}
                      >
                        Acheter maintenant
                      </div>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AchatImediat;
