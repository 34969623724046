import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import ReclamationService from '../../../services/reclamation.service'
import { ClipLoader } from "react-spinners";
import AuthService from "../../../services/auth.service";

const Complaints = () => {
    const [loading, setLoading] = useState(false)
    const [reclamations, setReclamations] = useState([])
    const [columns, setColumns] = useState([

        {
            name: "Numéro de réclamation",
            selector: "id",
            sortable: true,
            width: "100px",
        },
        {
            name: "Status",
            selector: "statut",
            sortable: true,
            width: "100px",
        },
        {
            name: "Titre",
            selector: "titre",
            sortable: true,
            width: "150px",
            wrap: true
        },
        {
            name: "Message",
            selector: "description",
            sortable: true,
            width: "200px",
            wrap: true
        },
        {
            name: "Reponce",
            selector: "reponces_admin",
            sortable: true,
            wrap: true
        },

    ])
    const tableData = {
        columns,
        data: reclamations
    };
    useEffect(() => {
        setLoading(true)
        ReclamationService.getReclamations()
            .then(res => {
                setReclamations(res.data.results)
                setLoading(false)
            })
            .catch(function (error) {
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                }
                else if (error.response.status === 403) {
                    setLoading(false)
                    error.response.data.error || error.response.data.message ?
                        NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
                        :
                        NotificationManager.warning("Could not add admin", "", 2000)

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.response.status === 401) {
                    setLoading(false)
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    setTimeout(() => {
                        AuthService.logout()
                    }, 500);
                }
            });
    }, [])


    return (
        !loading ?
            <div className="myaccount-content">
                <DataTableExtensions {...tableData}>
                    <DataTable
                        columns={columns}
                        data={reclamations}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        
                    />
                </DataTableExtensions>
               
            </div>
            :
            <ClipLoader color={"#fffff"} loading={loading} />
    )
}


export default Complaints;