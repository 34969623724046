/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/authentication";
import CategorieService from "../../../services/categories.service";
export default function MobileHeader() {
  const logedIn = AuthService.getCurrentUser().token ? true : false;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    CategorieService.allCategories().then((res) =>
      setCategories(res.data.results)
    );
  }, []);

  return (
    <div className="mobile-header-active mobile-header-wrapper-style">
      <div className="mobile-header-wrapper-inner">
        <div className="mobile-header-top">
          <div className="mobile-header-logo">
            <a href="/">
              <img src="/assets/images/logo/logo.png" alt="logo" />
            </a>
          </div>
          <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
            <button className="close-style search-close">
              <i className="icon-top" />
              <i className="icon-bottom" />
            </button>
          </div>
        </div>
        <div className="mobile-header-content-area">
          <div className="covid-update covid-update-mobile mobile-header-border">
            <p>
              <Link to="/boutique">COVID-19</Link> Tous les produits associés
            </p>
          </div>
          <div className="mobile-menu-wrap mobile-header-border">
            {/* mobile menu start */}
            <nav>
              <ul className="mobile-menu">
                <li className="menu-item-has-children">
                  <a href="/accueil">Accueil</a>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/apropos">À Propos</Link>
                </li>
                <li className="menu-item-has-children ">
                  <Link to="/boutique">Boutique</Link>
                  <ul className="dropdown">
                    {categories.map((cat, i) => (
                      <li key={i}>
                        <Link to={`/boutique/${cat.name}`}>{cat.name}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="menu-item-has-children">
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/wishlist">
                    Ma liste d'envies
                    <i className="far fa-heart ml-4" />
                  </Link>
                </li>
                <li>
                  <Link to="/devis">
                    Demander un devis
                    <i className="far fa-file-invoice ml-4" />
                  </Link>
                </li>
                <li>
                  <Link to="/reclamation">
                    Reclamation{" "}
                    <i className="fas fa-comment-exclamation ml-4" />
                  </Link>
                </li>
                <li>
                  <Link to="/messages">
                    <i className="far fa-comment-alt-dots" /> Besoin d'aide ?
                  </Link>
                </li>
              </ul>
            </nav>
            {/* mobile menu end */}
          </div>
          <div className="mobile-header-info-wrap mobile-header-border">
            {logedIn ? (
              <div className="single-mobile-header-info">
                <a href="/accueil" onClick={AuthService.logout}>
                  Déconnexion <i className="fas fa-sign-out-alt ml-5" />
                </a>
              </div>
            ) : (
              <div className="single-mobile-header-info">
                <Link to="/login-register">Se connecter / S'inscrire</Link>
              </div>
            )}
            <div className="single-mobile-header-info">
              <a target="_blank" href="https://www.google.com/maps">
                Emplacement du magasin
              </a>
            </div>
            <div className="single-mobile-header-info">
              <Link to="#">(+212) - 625 - 701 - 262 </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
