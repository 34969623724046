import { LinkedinShareButton } from "react-share";

const ShareOnLinkedin = (props) => {
  const postUrl = "https://hfc.storeproductmaroc.com/public/blog/detail/5";
  return (
    <LinkedinShareButton
      //   url={props.urlToShare}
      url={postUrl}
      className="ml-4"
      title="this is a title"
    >
      <i className="fab fa-linkedin" />
    </LinkedinShareButton>
  );
};

export default ShareOnLinkedin;
