const logout = () => {
  sessionStorage.clear();
  window.location.reload();
};
const getCurrentUser = () => {
  // console.log(sessionStorage.getItem("user"));
  const user = {
    user: sessionStorage.getItem("user"),
    firstName: sessionStorage.getItem("name"),
    lastName: sessionStorage.getItem("surname"),
    email: sessionStorage.getItem("email"),
    avatar: sessionStorage.getItem("avatar"),
    role: sessionStorage.getItem("role"),
    id: sessionStorage.getItem("id"),
    civ: sessionStorage.getItem("civ"),
    phone: sessionStorage.getItem("phone"),
    token: sessionStorage.getItem("token"),
  };
  return user;
};

const AuthService = {
  logout,
  getCurrentUser,
};
export default AuthService;
