import { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import DevisService from '../../../services/devis.service'
import { ClipLoader } from "react-spinners";
import AuthService from "../../../services/auth.service";
import Moment from "react-moment";

const Devis = () => {

    // const gotoFacture = () => {

    // }

    const [loading, setLoading] = useState(false)
    const [devis, setDevis] = useState([])
    const [columns, setColumns] = useState([

        {
            name: "Numéro de devis",
            selector: "demande_devis_id",
            sortable: true,
            width: "120px",
        },
        {
            name: "Date de commande",
            selector: "date_commande",
            sortable: true,
            width: "40%",
            cell: (d) => <div className="d-flex w-100 align-items-center justify-content-start">
                <Moment format="DD/MM/YYYY" className="text-center">
                    {d.date_commande}
                </Moment>
                <div className="text-muted mx-3">
                    ( <Moment fromNow>{d.date_commande}</Moment> )
                </div>
            </div>
        },
        {
            name: "Valider",
            selector: "valide",
            sortable: true,
            width: "100px",
            cell: (d) =><span>{d.tarif ? "Oui" : "Non"}</span>
        },
        {
            name: "Facture",
            selector: "facture",
            sortable: false,
            width: "auto",
            cell: (d) => d.tarif ? <button className="btn btn-link" onClick={() => window.location = `/facture/${d.demande_devis_id_devis}`}>Voire devis </button> : null
        }

    ])
    const tableData = {
        columns,
        data: devis
    };

    useEffect(() => {
        setLoading(true)
        DevisService.loadDevisByClient(sessionStorage.getItem("id"))
            .then(res => {
                setDevis(res.data.results)
                console.log(res.data.results)
                setLoading(false)
            })
            .catch(function (error) {
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                }
                else if (error.response.status === 403) {
                    setLoading(false)
                    error.response.data.error || error.response.data.message ?
                        NotificationManager.warning(error.response.data.error ? error.response.data.error : error.response.data.message, "", 2000)
                        :
                        NotificationManager.warning("Could load devis", "", 2000)

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.response.status === 401) {
                    setLoading(false)
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    setTimeout(() => {
                        AuthService.logout()
                    }, 500);
                }
            });
    }, [])


    return (
        !loading ?
            <div className="myaccount-content">
                <DataTableExtensions {...tableData}>
                    <DataTable
                        columns={columns}
                        data={devis}
                        noHeader
                        defaultSortField="id"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover

                    />
                </DataTableExtensions>

            </div>
            :
            <ClipLoader color={"#fffff"} loading={loading} />
    )
}


export default Devis;