import axios from "axios";
import authHeader from "./auth-header";

const idUser = sessionStorage.getItem("id");
// abonner au newsletter
const suscribe = (email) => {
  return axios.post(window.API_URL + "subscribe", { email: email });
};

//contact us
const contacter = (info) => {
  return axios.post(window.API_URL + "contact", info);
};

//
const signup = (data) => {
  return axios.post(window.API_URL + "signup", data);
};

const updateInfo = (data) => {
  return axios.patch(`${window.API_URL}user/user_infos/${idUser}`, data, authHeader());
};
const updatePassword = (data) => {
  return axios.patch(`${window.API_URL}user/user_mdp/${idUser}`, data, authHeader());
};
const getUserById = () => {
  return axios.post(`${window.API_URL}user/user/${idUser}`, {}, authHeader());
};

const UserService = {
  suscribe,
  contacter,
  signup,
  updateInfo,
  getUserById,
  updatePassword,
};
export default UserService;
