/* eslint-disable no-unused-vars */
import WhichPage from "../../Init/header/WhichPage";
import HotNewProduct from "./HotNewProduct";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import ProductsService from "../../../services/product.service";
import SingleProduct from "./SingleProduct";
import CategorieMenu from "./CategorieMenu";
import CategorieService from "../../../services/categories.service";
import SubCategorieService from "../../../services/subCategorie.service";
import FamiliesService from "../../../services/families.service";

const BoutiqueCovid = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [families, setFamilies] = useState([]);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    CategorieService.allCategories().then((res) =>
      setCategories(res.data.results)
    );
    SubCategorieService.allSubCategories().then((res) =>
      setSubCategories(res.data.results)
    );
    FamiliesService.allFamilies().then((res) => {
      setFamilies(res.data.results);
      // console.log(res.data);
    });
    ProductsService.allCovidProducts()
      .then((res) => {
        setProducts(res.data.results);
        // console.log(res.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="main-wrapper">
      <WhichPage page={location.pathname.slice(1)} />
      <div className="shop-area pt-75 pb-55">
        <div className="custom-container">
          <div className="row flex-row-reverse">
            <div className="col-lg-9">
              <div className="shop-topbar-wrapper">
                <div className="totall-product">
                  <p>
                    Nous avons <span>{products.length}</span> produit(s) pour
                    COVID
                  </p>
                </div>
              </div>
              <div className="shop-bottom-area">
                <div className="row text-center">
                  {loading && (
                    <div className="row w-100 justify-content-center m-5">
                      <div
                        className="spinner-border text-success"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      {/* <MoonLoader /> */}
                    </div>
                  )}
                  {products.map((product, i) => (
                    <SingleProduct
                      key={i}
                      // addCallBack={handelWLcallBack}
                      product={product}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sidebar-wrapper sidebar-wrapper-mr1">
                <CategorieMenu
                  cat={categories}
                  subcat={subCategories}
                  families={families}
                />
                <HotNewProduct className="mt-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoutiqueCovid;
