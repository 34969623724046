import { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useParams } from "react-router";
import { BeatLoader, ClipLoader } from "react-spinners";
import ReactToPrint from "react-to-print";
import AuthService from "../../../services/auth.service";
import DevisService from "../../../services/devis.service";

const btnStyle = {
  color: "#fff",
  background: "#163c74",
  fontWeight: "600",
  height: "48px",
  borderRadius: "26px",
};

const Invoice = () => {
  const [devis, setDevis] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = useParams().id;
  const componentRef = useRef(null);
  useEffect(() => {
    DevisService.getDevisById(id)
      .then((res) => {
        console.log(res.data);
        setDevis(res.data.results);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        // console.log(error)
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          error.response.data.error
            ? NotificationManager.warning(error.response.data.error, "", 2000)
            : NotificationManager.warning(
                "Could not load client list",
                "",
                2000
              );

          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout();
          }, 500);
        }
      });
  }, []);
  return (
    <div className="d-fex justify-center">
      {/* [ Invoice ] start */}
      {/* <div className="container"> */}
      {!loading ? (
        <div className="ml-5 mr-5">
          <div ref={componentRef} className="card" id="printable_devis">
            <div className="row invoice-contact">
              <div className="col-md-12">
                <div className="invoice-box row">
                  <div className="col-sm-12">
                    <table className="table table-responsive invoice-table table-borderless p-l-20">
                      <tbody>
                        <tr>
                          <td>
                            <a href="index.html" className="b-brand">
                              <img
                                className="img-fluid"
                                src={"/assets/images/logo/logo-white.png"}
                                alt="hfc logo"
                                width="100px"
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Healthcare Facilities Consult</td>
                        </tr>
                        <tr>
                          <td>
                            504 lotissement haj Fateh  20260  El Oulfa Casablanca Maroc
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a
                              className="text-secondary"
                              href="mailto:contact@healthcarefacilitiesconsult.com"
                              target="_top"
                            >
                              contact@healthcarefacilitiesconsult.com
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>+212 625-701262</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-md-4" />
            </div>
            <div className="card-body">
              <div className="row invoive-info">
                <div className="col-md-4 col-xs-12 invoice-client-info">
                  <h6>Informations client :</h6>
                  <h6 className="m-0">{"kkk"}</h6>
                  <p className="m-0 m-t-10">{"kkk"}</p>
                  <p className="m-0">{"kkk"}</p>
                  <p>
                    {/* <a
                        className="text-secondary"
                        href={"mailto:" + client.email}
                        target="_top"
                      >
                        {client.email}
                      </a> */}
                  </p>
                </div>
                {/* <div className="col-md-4 col-sm-6">
                    <h6>Information Professionnelle :</h6>
                     <table className="table table-responsive invoice-table invoice-order table-borderless">
                      <tbody>
                        <tr>
                          <th>Date :</th>
                          <td>November 14</td>
                        </tr>
                        <tr>
                          <th>Status :</th>
                          <td>
                            <span className="label label-warning">Pending</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Id :</th>
                          <td>#146859</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                <div className="col-md-4 col-sm-6">
                  <h6 className="m-b-20">
                    Numéro de devis:{" "}
                    <span className="mx-2"> {devis.demandeDevisId}</span>
                  </h6>
                  <h6 className="m-0 m-t-10">
                    Date de demande :
                    {/* <h6 className="text-uppercase text-primary mt-2"> */}
                    <moment format="DD/MM/YYYY" className="text-primary mx-2">
                      {devis.date_commande}
                    </moment>
                  </h6>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <form action="#">
                    <div className="cart-table-content">
                      <div className="table-content table-responsive">
                        <table>
                          <thead>
                            <tr>
                              <th className="width-thumbnail">Produit</th>
                              <th className="width-name" />
                              <th className="width-quantity">Quantité</th>
                              <th className="width-price"> Prix</th>
                              <th className="width-subtotal">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="product-thumbnail">
                                <a href="product-details.html">
                                  <img
                                    src="/assets/images/cart/cart-1.jpg"
                                    alt=""
                                  />
                                </a>
                              </td>
                              <td className="product-name">
                                <h5>
                                  <a href="product-details.html">
                                    Cara Portable Air Compressor
                                  </a>
                                </h5>
                              </td>
                              <td className="cart-quality">
                                <div className="product-quality">
                                  <h4>1</h4>
                                </div>
                              </td>
                              <td className="product-price">
                                <span className="amount">$120.00</span>
                              </td>
                              <td className="product-total">
                                <span>$120.00</span>
                              </td>
                            </tr>
                            <tr>
                              <td className="product-total" colSpan="3"></td>
                              <td>Total{"   "}:</td>
                              <td className="product-total">
                                <span>$120.00</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center m-5">
              {loading ? (
                <div className="text-center m-5">
                  <BeatLoader color={"#163C74"} loading={loading} size={30} />
                </div>
              ) : (
                <ReactToPrint
                  trigger={() => (
                    <button type="button" className="btn" style={btnStyle}>
                      Imprimer
                    </button>
                  )}
                  content={() => componentRef.current}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center m-5">
          <BeatLoader color={"#163C74"} loading={loading} size={30} />
        </div>
      )}
      {/* </div> */}
      {/* [ Invoice ] end */}
    </div>
  );
};

export default Invoice;
