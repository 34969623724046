import React, { useState } from "react";
import AuthService from "../../../services/authentication";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

const LoginForm = ({ show ,toggle }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formdata, setformdata] = useState();
  const [loading, setLoading] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.login(email, password)
      .then((res) => {
        setLoading(false);
        NotificationManager.success("vous êtes connecté");
        window.location.reload();
      })
      .catch(function (error) {
        setLoading(false);
        if (error?.response?.status && error?.response?.status === 403) {
          setLoading(false);
          error.response.data.erreur
            ? NotificationManager.warning(error?.response?.data?.erreur, "", 2000)
            : NotificationManager.warning(
                "Could not connect to your account",
                "",
                2000
              );
        }
      });
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  let changePwSwal = {
    title: "Mot de passe oublié?",
    focusConfirm: false,
    html: `
        <input class="swal2-input" id="emailReset" type="email" placeholder="Entrer votre Email" /><br />
      `,
    type: "warning",
    showCancelButton: true,
    cancelButtonColor: "grey",
    confirmButtonText: "Envoyer",
    allowOutsideClick: false,
    preConfirm: () => ({
      email: document.getElementById("emailReset").value,
    }),
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });

  const handleResetPassword = () => {
    const resetPw = async () => {
      const swalval = await MySwal.fire(changePwSwal);
      let v = (swalval && swalval.value) || swalval.dismiss;
      if ((v && v.email) || v === "cancel") {
        if (v !== "cancel") {
          setformdata(swalval);
          // console.log(swalval.value)
          AuthService.forgotPassword(swalval.value)
            .then((res) => {
              swalWithBootstrapButtons.fire(
                "Succès!",
                "veuillez vérifier votre courrier électronique pour récupérer votre mot de passe.",
                "success"
              );
            })
            .catch(function (error) {
              // console.log(error)

              if (error.response.status === 403) {
                error.response.data.error || error.response.data.message
                  ? NotificationManager.warning(
                      error.response.data.error
                        ? error.response.data.error
                        : error.response.data.message,
                      "",
                      2000
                    )
                  : NotificationManager.warning(
                      "Could not reset password",
                      "",
                      2000
                    );

                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
              }
            });
        }
      } else {
        await MySwal.fire({
          type: "error",
          title: "All fields are required!!",
        });
        resetPw();
      }
    };
    resetPw();
  };

  return (
    <div className={show ? "col-lg-8 hid-box":"col-lg-8 show-box" }>
      <div className="login-register-wrap login-register-gray-bg">
        <div className="login-register-title">
          <h1>Se connecter</h1>
        </div>
        <div className="login-register-form">
          <form onSubmit={handleLogin}>
            <div className="login-register-input-style input-style input-style-white">
              <label>E-mail d'utilisateur *</label>
              <input
                type="text"
                required
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login-register-input-style input-style input-style-white">
              <label>Mot de passe *</label>
              <input
                type="password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="lost-remember-wrap">
              <div className="remember-wrap">
                {/* <input type="checkbox" /> */}
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={handleResetPassword}
                >
                  {" "}
                  Mot de passe oublié?{" "}
                </button>
              </div>
              {/* <div className="lost-wrap">
                <Link to="#">Mot de passe perdu?</Link>
              </div> */}
            </div>
            <div className="login-register-btn">
              <button type="submit">
                {loading ? (
                  <ClipLoader loading={loading} color="#fffff" />
                ) : (
                  "Connexion"
                )}
              </button>
            </div>
          </form>
        </div>
        <div className="btn-style-2 mt-4 text-right">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
          >
            S'inscrire <i className="far fa-long-arrow-right" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
