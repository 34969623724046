import { useEffect, useState } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import DevisService from "../../../services/devis.service";
import WhichPage from "../../Init/header/WhichPage";

const Quotations = () => {
  const ids = [];
  const [devisItems, setDevisItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateDevisQte = (id, qte) => {
    DevisService.updateDevisQte(id, qte).then((res) => console.log(res.data));
  };
  const removeAll = () => {
    if (ids.length > 0)
      Swal.fire({
        title: "Etes-vous sûr?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#163C74",
        cancelButtonColor: "#0E9B4A",
        confirmButtonText: "Oui, supprimez-le",
      }).then((result) => {
        if (result.isConfirmed) {
          for (let i = 0; i < ids.length; i++) {
            DevisService.removeProductFromDevis(ids[i]).then((res) =>
              Swal.fire("Supprimé !", "Your file has been deleted.", "success")
            );
          }
          setDevisItems([]);
        }
      });
  };
  const removeItem = (id) => {
    Swal.fire({
      title: "Etes-vous sûr?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#163C74",
      cancelButtonColor: "#0E9B4A",
      confirmButtonText: "Oui, supprimez-le",
    }).then((result) => {
      if (result.isConfirmed) {
        DevisService.removeProductFromDevis(id).then((res) =>
          Swal.fire("Supprimé !", "Your file has been deleted.", "success")
        );
        laodData();
      }
    });
  };
  const laodData = () => {
    DevisService.allDivisItems()
      .then((res) => {
        setDevisItems(res.data.results);
        NotificationManager.success(`${res.data.results.length} element`);
        setLoading(false);
      })
      .catch((err) => NotificationManager.error(`${err}`));
  };
  useEffect(() => {
    laodData();
  }, []);

  return (
    <>
      <WhichPage page="Devis" />
      <div className="cart-area pt-75 pb-35">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <form action="#">
                <div className="cart-table-content text-center">
                  <div className="table-content table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th></th>
                          <th className="width-thumbnail">Product</th>
                          <th></th>
                          <th>Prix</th>
                          <th className="width-quantity">Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td className="text-center m-5" colSpan="5">
                              <BeatLoader
                                color={"#163C74"}
                                loading={loading}
                                size={30}
                              />
                            </td>
                          </tr>
                        ) : (
                          devisItems.map((item, i) => {
                            ids.push(item.id);
                            return (
                              <tr key={i}>
                                <td>
                                  <div className="form-check">
                                    <Link
                                      to="#"
                                      onClick={() => removeItem(item.id)}
                                    >
                                      <i className="far fa-times" />
                                    </Link>
                                  </div>
                                </td>
                                <td className="product-thumbnail">
                                  <Link to={
                                    item.id_produit ? 
                                    `/details-produit/${item.product}` : 
                                    `/details-new_produit/${item.product}`
                                  }>
                                    <img
                                      src={`${window.IMAGE_API_URL}products/${
                                        item.image.split(";")[0]
                                      }`}
                                      alt=""
                                    />
                                  </Link>
                                </td>
                                <td className="product-name">
                                  <h5>
                                    {item.id_produit ? (
                                      <Link
                                        to={`/details-produit/${item.product}`}
                                      >
                                        {item.product}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/details-new_produit/${item.product}`}
                                      >
                                        {item.product}
                                      </Link>
                                    )}
                                  </h5>
                                </td>
                                <td>
                                  <div>
                                    <span>
                                    <b>{
                                      (
                                        item?.offer_pourcentage ?    
                                        `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                                        `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                                        )
                                    }</b>
                                    </span>
                                    {
                                      item?.offer_pourcentage ?  
                                      <span style={{
                                        color: 'rgb(168, 160, 149)',
                                        textDecorationColor: 'initial',
                                        textDecorationLine: 'line-through',
                                        textDecorationThickness: 'initial',
                                        textDecorationStyle: 'initial',
                                        marginLeft : 5
                                      }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                                    }
                                  </div>
                                </td>
                                <td className="cart-quality">
                                  <div className="product-quality w-100">
                                    <input
                                      className="cart-plus-minus-box input-text qty text"
                                      name="qtybutton"
                                      type="number"
                                      min="0"
                                      step="1"
                                      defaultValue={item.quantite_produit}
                                      onChange={(e) => updateDevisQte(item.id, e.target.value)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div className="row cart-shiping-update-wrapper">
                    <div className="col-12 col-md-6 continure-clear-btn">
                      <div className="continure-btn ml-4">
                        <Link to="/boutique">revenir à la boutique</Link>
                      </div>
                      <div className="clear-btn">
                        <Link to="#" onClick={removeAll}>
                          <i className="fal fa-times" /> Enlever tout
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 update-btn text-right">
                      <Link
                        className="mr-4"
                        to={ids.length > 0 ? "/demander-devis" : "#"}
                      >
                        Demander un devis
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotations;
