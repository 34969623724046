

const FunFacts = () => {
    

    return (
        <div className="funfact-area pt-70 pb-35">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-12 col-sm-6 wow tmFadeInUp">
              <div className="single-funfact text-center mb-30">
                <h2 className="count">1790</h2>
                <span>Clients heureux</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 col-sm-6 wow tmFadeInUp">
              <div className="single-funfact text-center mb-30">
                <h2 className="count">491</h2>
                <span>Projets terminés</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 col-sm-6 wow tmFadeInUp">
              <div className="single-funfact text-center mb-30">
                <h2 className="count">245</h2>
                <span>Experts</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 col-sm-6 wow tmFadeInUp">
              <div className="single-funfact text-center mb-30">
                <h2 className="count">1090</h2>
                <span>Des postes</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default FunFacts ;