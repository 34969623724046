import axios from "axios";

const allCategories = () => {
  return axios.get(window.API_URL + "categories");
};
const hot_categories = (params) => {
  return axios.get(window.API_URL + "hot_categories");
};

const CategorieService = {
  allCategories,
  hot_categories,
};

export default CategorieService;
