import WhichPage from "../../Init/header/WhichPage"
import AboutUsArea from "./AboutUsArea"
import AskUs from "./AskUs"
import Brands from "./Brands"
import FunFacts from "./FunFacts"
import MissionArea from "./MissionArea"

const About = () => {


    return (
        <>

        <WhichPage page=" À Propos" />
        <AboutUsArea />
        <MissionArea />
        <FunFacts />
        <AskUs />
        <Brands />
        </>
    )
}

export default About ;