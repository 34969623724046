import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductsService from "../../../services/product.service";

const HotNewProduct = () => {
  const [product, setProduct] = useState("");
  useEffect(() => {
    ProductsService.productById(30).then((res) => {
      setProduct(res.data.result[0]);
      console.log(res.data);
    });
  }, []);
  return (
    <>
      {
        product ?
          <div className="sidebar-widget sidebar-widget-wrap slidebar-product-wrap-2 sidebar-widget-padding-5 text-center mb-20">
            <div className="slidebar-product-content-2 text-center">
              <h3>{product.description }</h3>
              <h2>{product.name }</h2>
            </div>
            <div className="slidebar-product-img-2 text-center">
              <Link to={"/details-produit/" + product.name}>
                {product.image && (
                  <img
                    src={
                      window.IMAGE_API_URL + `products/${product.image.split(";")[0]}`
                    }
                    alt=""
                  />
                )}
              </Link>
              <div className="slidebar-pro-badge slidebar-pro-badge-position1">
                <h3>
                  <span>12%</span>
                  Off
                </h3>
              </div>
            </div>
            <div className="btn-style-1">
              <Link
                className="font-size-14 btn-1-padding-4"
                to={"/details-produit/" + product.name}
              >
                Voir en details
              </Link>
            </div>
          </div>
          : null}
    </>
  );
};
export default HotNewProduct;
