import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BrandsService from "../../../services/brands.service";

const Brands = () => {
  const [brads, setBrads] = useState([]);
  useEffect(() => {
    BrandsService.allBrands().then((res) => {
      console.log(res.data);
      setBrads(res.data.results);
    });
  }, []);

  return (
    <div className="brand-logo-area pb-35">
      <div className="container">
        <div className="row align-items-center">
          {brads.map((brand, i) => (
            <div key={i} className="custom-col-5 wow tmFadeInUp">
              <div className="single-brand-logo mb-30">
                <Link to={`/boutique/m:${brand.name}`}>
                  <img
                    src={`${window.IMAGE_API_URL}marques/${brand.image}`}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
