import React from "react";
import WhichPage from "../../Init/header/WhichPage";
import ProfileTabContent from "./ProfileTabContent";
import ProfileTabMenu from "./ProfileTabMenu";
import {
    useHistory
} from 'react-router-dom';

const Profile =()=> {
    const history = useHistory();

    React.useEffect(() => {
        let navigateToTab = window.location.hash?.replace(/^#/, '');
        if (navigateToTab) {
            let element = document?.querySelector(`#ProfileTabs a[href="#${navigateToTab}"]`);
            element && setTimeout(() => document?.querySelector(`#ProfileTabs a[href="#${navigateToTab}"]`).click((e) => e.preventDefault()), 1000);
        }
    }
    ,[]);

    return(
       <>
        <WhichPage page=" Profil " />
        <div className="my-account-area pt-75 pb-75">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="myaccount-page-wrapper">
                            <div className="row">
                                <ProfileTabMenu />                                
                                <ProfileTabContent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       </>
    )
}

export default Profile ;