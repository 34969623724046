import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductsService from "../../../services/product.service";
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const SuggestedProducts = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    ProductsService.allProductEtonants().then((res) => setProducts(res.data.results.slice(0, 8)));
  }, []);

  return (
    <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-2 mb-20">
      <h4 className="sidebar-widget-title">Produits étonnants</h4>
      <div className="sidebar-post-wrap mt-30">
        {products.map((item, i) => (
          <div key={i} className="single-sidebar-post">
            <div className="sidebar-post-img">
              <Link to={`/details-produit/${item.name}`}>
                <ImageListItem
                  cols={1}
                  rows={1}
                  style={{
                    display : 'flex',
                    justifyContent : 'center'
                  }}
                >
                  <img
                    style={
                      {
                        borderRadius : 5
                      }
                    }
                    {...srcset(
                      window.IMAGE_API_URL +
                    `products/${item.image.split(";")[0]}`
                      , 250, 200, 1, 1)}
                    alt={'product image'}
                    loading="lazy"
                  />
                </ImageListItem>
                {/* <img
                height="150px"
                  className="default-img"
                  src={
                    window.IMAGE_API_URL +
                    `products/${item.image.split(";")[0]}`
                  }
                  alt=""
                /> */}
              </Link>
            </div>
            
            <div className="sidebar-post-content">
              <h4>
                <Link to={`/details-produit/${item.name}`}>{item.name}</Link>
              </h4>
              {
              item?.offer_pourcentage ?
              <div style={{
                margin: "0 0 10px"
              }}>
                <span style={{
                  backgroundColor: '#e4573d',
                  display: 'inline-block',
                  fontSize: '13px',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  lineHeight: 1,
                  borderRadius: '2px',
                  color: '#ffffff',
                  padding: '5px 8px 6px'
                }}> {`SALE -${item?.offer_pourcentage}%`}</span>
              </div> :<></>
              }
              <div style={{display : 'flex'}}>
                <span>
                  <b style={{fontSize : 12}}>
                  {
                  item.tarif > 0 ?
                  (
                    item?.offer_pourcentage ?    
                  `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                  `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                  ) :
                  'demande de prix'
                  }
                  </b>
                </span>
                {
                item?.offer_pourcentage ?  
                <span style={{
                  color: 'rgb(168, 160, 149)',
                  textDecorationColor: 'initial',
                  textDecorationLine: 'line-through',
                  textDecorationThickness: 'initial',
                  textDecorationStyle: 'initial',
                  marginLeft : 5
                }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const MenuArea = () => {
  return (
    <div className="col-lg-4">
      <div className="sidebar-wrapper sidebar-wrapper-mr1">
        <SuggestedProducts />
      </div>
    </div>
  );
};

export default MenuArea;
