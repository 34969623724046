import { useEffect, useState } from "react";
import { DotLoader } from "react-spinners";
import SliderService from "../../../../services/slider.service";
import LeftSlids from "./LeftSlids";
import RightSliders from "./RightSliders";

const MainSlidesSection = () => {
  const [loading, setLoader] = useState(true);
  const [LeftSliders, setLeftSliders] = useState([]);
  const [BotSliders, setBotSliders] = useState([]);

  useEffect(() => {
    SliderService.subSliders()
      .then((res) => {
        setLeftSliders(res.data.results.filter((val) => val.position === 'Gauche'))
        setBotSliders(res.data.results.filter((val) => val.position !== 'Gauche'))

        setLoader(false);
      })
      .catch(() => setLoader(false));
  }, []);
  
  return (
    <div className="slider-banner-area padding-10-row-col">
      <div className="custom-container">
        {loading ? (
          <div className="text-center m-5">
            <DotLoader color={"#163C74"} loading={loading} size={50} />
          </div>
        ) : (
          <div className="row flex-column-reverse flex-lg-row bg-color mt-2">
            <div className="col-12 col-lg-4 d-none d-lg-block">
              <LeftSlids subSliders={LeftSliders} />
            </div>
            <div className="col-12 col-lg-8">
              <RightSliders subSliders={BotSliders} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainSlidesSection;
