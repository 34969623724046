import { useState } from "react";
import UserService from "../../../services/user.service";
import Swal from "sweetalert2";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./subscribeArea.css";

const SubscribeArea = () => {
  const [email, setEmail] = useState(sessionStorage.getItem("email") || "");
  const [btnStyle, setStyle] = useState({ display: "none" });

  const toggler = () => {
    setStyle({ display: "flex" });
  };
  const hideToggler = () => {
    setStyle({ display: "none" });
    setEmail("");
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    if (email) {
      UserService.suscribe(email)
        .then((res) => Swal.fire("Vous êtes abonné maintenant"))
        .catch((err) =>
          NotificationManager.warning(`${err.response.data.error}`)
        );
    }
  };
  return (
    <div className="contact-area bg-gray-2">
      <NotificationContainer />
      <div className="custom-container custom-container-sb">
        <div className="row ">
          <div className="col-lg-12 d-flex flex-column justify-content-center">
            <h3 style={{ fontWeight: "bolder" }} className="font-weight-light">
              Abonnez-vous et recevez en exclusitvié nos derniers arrivages
            </h3>
            <div className="btn-style-1">
              <button
                style={{ width: "250px" }}
                type="button"
                className="btn btn-bg mt-2"
                onClick={toggler}
              >
                Abonnez-vous
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="AbonModel" style={btnStyle}>
        <form onSubmit={handelSubmit} className="subscribe-form">
          <div className="row input-group justify-content-center">
            <div className="col-12 col-lg-20 subs">
              
              <input
                type="email"
                onChange={onChangeEmail}
                className="form-control text-center m-2 mt-4"
                placeholder="exemple@email.com"
                value={email}
                name="email"
                required
              />
            </div>
            <div className="col-12 co-lg-6 text-center">
              <button
                id="cancel-btn"
                className="btn btn-bg m-2"
                style={{ backgroundColor: "gray" }}
                type="button"
                onClick={hideToggler}
              >
                Annuler
              </button>
              <button
                id="save-btn"
                className="btn-bg btn m-2 justify-self-end"
                type="submit"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SubscribeArea;
