import WhichPage from "../../Init/header/WhichPage";
import WishListDetails from './WishListDetails'


const WishList = () => {
    

    return (
      <>
        <WhichPage page=" List des envies" />
        <WishListDetails />
      </>
    );
}

export default WishList ;


