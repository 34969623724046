import { useEffect } from "react";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import AuthService from "../../../services/authentication";
import CompareService from "../../../services/compare.service";
import DevisService from "../../../services/devis.service";
import ProductsService from "../../../services/product.service";

const CompareTabContent = () => {
  const [products, setProducts] = useState([]);
  const loadData = () => {
    const id_Products = CompareService.getListCompares();
    for (let i = 0; i < id_Products.length; i++) {
      ProductsService.productById(id_Products[i]).then((res) => {
        setProducts((products) => [...products, res.data.result[0]]);
      });
    }
  };
  const id_user = sessionStorage.getItem("id");
  const addToCart = (product) => {
    if (id_user) {
      const data = {
        id_produit: product.id,
        id_user: id_user,
      };
      DevisService.addProductToDevis(data)
        .then(() => {
          NotificationManager.success("consultez la liste");
        })
        .catch(function (error) {
          if (!error.response) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
          } else if (error.response.status === 403) {
            error.response.data.error || error.response.data.message
              ? NotificationManager.warning(
                  error.response.data.error
                    ? error.response.data.error
                    : error.response.data.message,
                  "",
                  2000
                )
              : NotificationManager.warning(
                  "could not add product to wishlist",
                  "",
                  2000
                );
          } else if (error.response.status === 401) {
            NotificationManager.error(
              "Votre session est expiree, veuillez vous reconnectez",
              "",
              4000
            );
            setTimeout(() => {
              AuthService.logout();
            }, 500);
          }
        });
    } else {
      NotificationManager.warning("vous n'êtes pas encore connectée");
    }
  };
  const removeAll = (e) => {
    e.preventDefault();
    setProducts([]);
    var compares = [];
    localStorage.setItem("compares", JSON.stringify(compares));
  };
  const removeItem = (id, e, index) => {
    e.preventDefault();
    var compares = JSON.parse(localStorage.getItem("compares"));
    compares.splice(index, 1);
    localStorage.setItem("compares", JSON.stringify(compares));
    for (let i = 0; i < products.length; i++) {
      setProducts(products.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    // for (let i = 0; i < id_Products.length; i++) {
    //   ProductsService.productById(id_Products[i]).then((res) => {
    //     console.log(res.data);
    //     setProducts([...products, res.data.result[0]]);
    //   });
    // }
    loadData();
  }, []);
  return (
    <div className="compare-page-wrapper pt-75 pb-75">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* Compare Page Content Start */}
            <div className="col-12 text-right m-3">
              {products[0] && (
                <Link
                  style={{ fontSize: "18px", color: "#163C74" }}
                  to="#"
                  onClick={removeAll}
                >
                  <i className="fal fa-times" /> Enlever tout
                </Link>
              )}
            </div>
            <div className="compare-page-content-wrap">
              <div className="compare-table table-responsive">
                <table className="table table-bordered mb-0 text-center">
                  <thead>
                    <tr>
                      {products[0] && (
                        <th>
                          <Link
                            to={"/details-produit/" + products[0].name}
                            className="title"
                          >
                            {products[0].name}
                          </Link>
                          <Link
                            style={{
                              fontSize: "18px",
                              color: "#163C74",
                              marginLeft: "30%",
                            }}
                            to="#"
                            onClick={(e) => removeItem(products[0].id, e, 0)}
                          >
                            <i className="fal fa-times" />
                          </Link>
                        </th>
                      )}
                      {products[1] && (
                        <th>
                          <Link
                            to={"/details-produit/" + products[0].name}
                            className="title"
                          >
                            {products[0].name}
                          </Link>
                          <Link
                            style={{
                              fontSize: "18px",
                              color: "#163C74",
                              marginLeft: "30%",
                            }}
                            to="#"
                            onClick={(e) => removeItem(products[1].id, e, 1)}
                          >
                            <i className="fal fa-times" />
                          </Link>
                        </th>
                      )}
                      {products[2] && (
                        <th>
                          <Link
                            to={"/details-produit/" + products[0].name}
                            className="title"
                          >
                            {products[0].name}
                          </Link>
                          <Link
                            style={{
                              fontSize: "18px",
                              color: "#163C74",
                              marginLeft: "30%",
                            }}
                            to="#"
                            onClick={(e) => removeItem(products[2].id, e, 2)}
                          >
                            <i className="fal fa-times" />
                          </Link>
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* <td width="100px" className="first-column">
                        Image
                      </td> */}

                      {products[0] && (
                        <td className="product-image-title">
                          <Link
                            to={"/details-produit/" + products[0].name}
                            className="image"
                          >
                            <img
                              width="200px"
                              className="img-fluid"
                              src={
                                window.IMAGE_API_URL +
                                "products/" +
                                products[0].image.split(";")[0]
                              }
                              alt="Comparer le Produit"
                            />
                          </Link>
                        </td>
                      )}
                      {products[1] && (
                        <td width="150px" className="product-image-title">
                          <Link
                            to={"/details-produit/" + products[1].name}
                            className="image"
                          >
                            <img
                              width="200px"
                              className="img-fluid"
                              src={
                                window.IMAGE_API_URL +
                                "products/" +
                                products[1].image.split(";")[0]
                              }
                              alt="Comparer le produit"
                            />
                          </Link>
                        </td>
                      )}
                      {products[2] && (
                        <td width="150px" className="product-image-title">
                          <Link
                            to={"/details-produit/" + products[2].name}
                            className="image"
                          >
                            <img
                              width="200px"
                              className="img-fluid"
                              src={
                                window.IMAGE_API_URL +
                                "products/" +
                                products[2].image.split(";")[0]
                              }
                              alt="Comparer le produit"
                            />
                          </Link>
                        </td>
                      )}
                    </tr>
                    <tr>
                      {products[0] && (
                        <td width="150px" className="compare-dec">
                          <p>{products[0].description}</p>
                        </td>
                      )}
                      {products[1] && (
                        <td width="150px" className="compare-dec">
                          <p>{products[1].description}</p>
                        </td>
                      )}
                      {products[2] && (
                        <td width="150px" className="compare-dec">
                          <p>{products[2].description}</p>
                        </td>
                      )}
                    </tr>

                    <tr>
                      {products[0] && (
                        <td width="150px" className="compare-btn">
                          <Link to="#" onClick={() => addToCart(products[0])}>
                            Demander un devis
                          </Link>
                        </td>
                      )}
                      {products[1] && (
                        <td width="150px" className="compare-btn">
                          <Link to="#" onClick={() => addToCart(products[1])}>
                            Demander un devis
                          </Link>
                        </td>
                      )}
                      {products[2] && (
                        <td width="150px" className="compare-btn">
                          <Link to="#" onClick={() => addToCart(products[2])}>
                            Demander un devis
                          </Link>
                        </td>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* Compare Page Content End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareTabContent;
