import { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Moment from "react-moment";
import NotificationManager from "react-notifications/lib/NotificationManager";
import "react-notifications/lib/notifications.css";
// import Swal from "sweetalert2";
import AuthService from "../../../services/auth.service";
import DevisService from '../../../services/devis.service'
import { ClipLoader } from "react-spinners";
// import ReactToPrint from "react-to-print";
import "./print.css"

const Facture = () => {
    const params = useParams();
    const componentRef = useRef();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState({});
    const [devis, setDevis] = useState([]);
    const [tarifTotal, setTarifTotal] = useState(null);
    const [tarifTTC, setTarifTTC] = useState(null);
    const [tarifHT, setTarifHT] = useState(null);
    const [tarifTVA, setTarifTVA] = useState(null);
    const [color, setColor] = useState("#00000");
    const [devisTarif, setDevisTarif] = useState(null);
    const [validDays, setValidDays] = useState(0);
    const [validMonths, setValidMonths] = useState(0);

    useEffect(() => {
        setLoading(true);
        DevisService.GetDemandeDevisByIdDevis(params.id)
            .then((res) => {
                const data = res.data.results;
                console.log(data);
                setClient({
                    fullName: data[0].firstName + " " + data[0].lastName,
                    adresse: data[0].adresse,
                    email: data[0].email,
                    phone: data[0].phone,
                });
                setDevisTarif(data[0].tarifTotal);
                setValidDays(data[0].expiration_days)
                setValidMonths(data[0].expiration_months)
                if (data.length > 0) {
                    // var total = 0;
                    var tht = 0
                    var tva = 0
                    var ttc = 0
                    data.map((item, key) => {
                        if (item.newProductId) {
                            ttc += parseFloat(item.quantite_produit * item.newProductTarif);
                        }
                        if (item.productId) {

                            tht += parseFloat(item.quantite_produit * item.productTarif);
                            tva += parseFloat(item.quantite_produit * item.tva * (item.productTarif / 100));
                            ttc += parseFloat(item.quantite_produit * item.productTarif * (1 + item.tva / 100));
                            // console.log(total);
                        }
                    });
                    // setTarifTotal(total);
                    setTarifHT(tht)
                    setTarifTVA(tva)
                    setTarifTTC(ttc)
                    setLoading(false);
                }
                setDevis(data);
            })
            .catch(function (error) {
                // console.log(error)
                if (!error.response) {
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                } else if (error.response.status === 403) {
                    setLoading(false);
                    error.response.data.error
                        ? NotificationManager.warning(error.response.data.error, "", 2000)
                        : NotificationManager.warning(
                            "Could not load client list",
                            "",
                            2000
                        );

                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                } else if (error.response.status === 401) {
                    setLoading(false);
                    NotificationManager.error(
                        "Votre session est expiree, veuillez vous reconnectez",
                        "",
                        4000
                    );
                    //   setTimeout(() => {
                    //     AuthService.logout();
                    //   }, 500);
                }
            });
    }, []);

    return (
        <>

            {devis.length > 0 ? (
                <div className="container my-3">

                    <div className="row">
                        {/* [ Invoice ] start */}
                        {/* <div className="container"> */}
                        <div className="col-12">
                            <div ref={componentRef} className="card" id="printable_devis">
                                <div className="row invoice-contact">
                                    <div className="col-md-12">
                                        <div className="invoice-box row">
                                            <div className="col-sm-12">
                                                <table className="table table-responsive invoice-table table-borderless p-l-20 hfc-info">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <a href="index.html" className="b-brand">
                                                                    <img
                                                                        className="img-fluid"
                                                                        src="/assets/images/logo/logo.png" alt="logo"
                                                                        width={120}
                                                                    />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Healthcare Facilities Consult</td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                504 lotissement haj Fateh  20260  El Oulfa Casablanca Maroc
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <a
                                                                    className="text-secondary"
                                                                    href="mailto:contact@healthcarefacilitiesconsult.com"
                                                                    target="_top"
                                                                >
                                                                    contact@healthcarefacilitiesconsult.com
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>+212 625-701262</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4" />
                                </div>
                                <div className="card-body">
                                    <div className="row invoive-info">
                                        <div className="col-md-4 col-xs-12 invoice-client-info">
                                            <h6>Informations client :</h6>
                                            <h6 className="m-0">{client.fullName}</h6>
                                            <p className="m-0 m-t-10">{client.adresse}</p>
                                            <p className="m-0">{client.phone}</p>
                                            <p>
                                                <a
                                                    className="text-secondary"
                                                    href={"mailto:" + client.email}
                                                    target="_top"
                                                >
                                                    {client.email}
                                                </a>
                                            </p>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <h6 className="m-b-20">
                                                Numéro de devis:{" "}
                                                <span className="mx-2"> {devis[0].demandeDevisId}</span>
                                            </h6>
                                            <h6 className="m-0 m-t-10">
                                                Date de demande :
                                                {/* <h6 className="text-uppercase text-primary mt-2"> */}
                                                <Moment format="DD/MM/YYYY" className="text-primary mx-2">
                                                    {devis[0].date_commande}
                                                </Moment>
                                            </h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="table-responsive">
                                                <table className="table invoice-detail-table">
                                                    <thead>
                                                        <tr className="thead-default">
                                                            <th>Produits</th>
                                                            <th>Quantité</th>
                                                            <th width="10%">Prix HT</th>
                                                            <th width="15%">TVA</th>
                                                            <th width="10%">Prix TTC / Unite</th>
                                                            <th>Prix TTC</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {devis.map((item, key) =>
                                                            item.productId ? (
                                                                <tr key={key} id={key}>
                                                                    <td>
                                                                        <img
                                                                            src={
                                                                                window.IMAGE_API_URL +
                                                                                "products/" +
                                                                                item.productImage.split(";")[0]
                                                                            }
                                                                            alt="contact-img"
                                                                            title="contact-img"
                                                                            className="rounded mr-3"
                                                                            height={48}
                                                                        />
                                                                        <p className="m-0 d-inline-block align-middle font-16">
                                                                            {item.productsName}
                                                                        </p>
                                                                    </td>
                                                                    <td>{item.quantite_produit}</td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            {/* Prix HT */}
                                                                            {parseFloat(
                                                                                item.productTarif
                                                                            ).toFixed(2)}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            {/* TVA */}
                                                                            {parseFloat(
                                                                                item.tva * (item.productTarif / 100)
                                                                            ).toFixed(2)}
                                                                            {" "}
                                                                            ({parseFloat(
                                                                                item.tva
                                                                            ).toFixed(2)} %)
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {/*Prix TTC / Unite  */}
                                                                        {parseFloat(
                                                                            item.productTarif * (1 + item.tva / 100)
                                                                        ).toFixed(2)}

                                                                    </td>
                                                                    <td>
                                                                        {/* Total */}
                                                                        {parseFloat(
                                                                            item.quantite_produit * item.productTarif * (1 + item.tva / 100)
                                                                        ).toFixed(2)}{" "}
                                                                        MAD
                                                                    </td>
                                                                </tr>
                                                            ) : item.newProductId ? (
                                                                <tr>
                                                                    <td>
                                                                        <img
                                                                            src={
                                                                                window.IMAGE_API_URL +
                                                                                "products/" +
                                                                                item.newProductImage.split(";")[0]
                                                                            }
                                                                            alt="contact-img"
                                                                            title="contact-img"
                                                                            className="rounded mr-3"
                                                                            height={48}
                                                                        />
                                                                        <p className="m-0 d-inline-block align-middle font-16">
                                                                            {item.newProductName}
                                                                        </p>
                                                                    </td>
                                                                    <td>{item.quantite_produit}</td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            {/* Prix HT */}
                                                                            {parseFloat(
                                                                                item.newProductTarif
                                                                            ).toFixed(2)}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group">
                                                                            {/* TVA */}
                                                                            {parseFloat(
                                                                                0 * (item.newProductTarif / 100)
                                                                            ).toFixed(2)}
                                                                            {" "}
                                                                            ({parseFloat(
                                                                                0
                                                                            ).toFixed(2)} %)
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {/*Prix TTC / Unite  */}
                                                                        {parseFloat(
                                                                            item.newProductTarif * (1 + 0 / 100)
                                                                        ).toFixed(2)}

                                                                    </td>
                                                                    <td>
                                                                        {/* Total */}
                                                                        {parseFloat(
                                                                            1 * item.newProductTarif * (1 + 0 / 100)
                                                                        ).toFixed(2)}{" "}
                                                                        MAD
                                                                    </td>
                                                                    <td>
                                                                        {parseFloat(
                                                                            item.quantite_produit * item.newProductTarif
                                                                        ).toFixed(2)}{" "}
                                                                        MAD
                                                                    </td>
                                                                </tr>
                                                            ) : null
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row w-50 ml-auto">
                                        <div className="col-sm-12">
                                            <table className="table table-responsive invoice-table invoice-total">
                                                <tbody>
                                                    <tr className="text-info">
                                                        <td style={{ verticalAlign: "center" }} className="text-left">
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary m-r-10">Total HT :</h6>
                                                        </td>
                                                        <td style={{ verticalAlign: "center" }}>
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary">
                                                                {parseFloat(tarifHT).toFixed(2)} MAD
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-info">
                                                        <td style={{ verticalAlign: "center" }} className="text-left">
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary m-r-10">Total TVA :</h6>
                                                        </td>
                                                        <td style={{ verticalAlign: "center" }}>
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary">
                                                                {parseFloat(tarifTVA).toFixed(2)} MAD
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-info">
                                                        <td style={{ verticalAlign: "center" }} className="text-left">
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary m-r-10">Livraison :</h6>
                                                        </td>
                                                        <td style={{ verticalAlign: "center" }}>
                                                            {/* <hr /> */}
                                                            <h6 className="text-secondary">
                                                                {tarifTTC < 1000 ? `${35} MAD` : `gratuit`}
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-info">
                                                        <td style={{ verticalAlign: "center" }}>
                                                            {/* <hr /> */}
                                                            <h5 className="text-primary m-r-10">Total TTC :</h5>
                                                        </td>
                                                        <td style={{ verticalAlign: "center" }}>
                                                            {/* <hr /> */}
                                                            <h5 className="text-primary">
                                                                {parseFloat(tarifTTC + (tarifTTC < 1000 ? 35 : 0)).toFixed(2)} MAD
                                                            </h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                            <hr />
                                            {validMonths || validDays ?
                                                <div className="text-muted">
                                                    <p>
                                                    Validité du devis:  {validMonths} mois et {validDays} jour(s)
                                                    </p>
                                                </div>
                                                : null
                                            }
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-12 invoice-btn-group text-center">
                                        {devisTarif ? (
                                            <ReactToPrint
                                                trigger={() => (
                                                    <button type="button" className="btn btn-primary buttons">
                                                        Imprimer
                                                    </button>
                                                )}
                                                content={() => componentRef.current}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                        {/* [ Invoice ] end */}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Facture;
