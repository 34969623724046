import React, { useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import AuthService from "../../../services/authentication";
import UserService from "../../../services/user.service";
import { ProfessionalInfo } from "./ProfissionalInfo";

const RegisterForm = ({ show, toggle }) => {
  const [condAccepted, setcondAccepted] = useState(false);
  const form = useRef(null);

  const fdToJson = (fd) => {
    let obj = {};
    for (let key of fd.keys()) {
      obj[key] = fd.get(key);
    }
    return obj;
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    UserService.signup(fdToJson(data))
      .then((res) => {
        NotificationManager.success("vous êtes connecté");

        AuthService.handelSessionStrege(res.data.data);
      })
      .catch(function (error) {
        if (!error.response) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
        } else if (error.response.status === 403) {
          console.log("YOooooo");
          error.response.data.error || error.response.data.message
            ? NotificationManager.warning(
                error.response.data.error
                  ? error.response.data.error
                  : error.response.data.message,
                "",
                2000
              )
            : NotificationManager.warning(
                "could not add product to wishlist",
                "",
                2000
              );
        }
      });
  };

  return (
    <div className={show ? "col-lg-8 show-box" : "col-lg-8 hid-box"}>
      <div className="login-register-wrap">
        <div className="btn-style-2 mt-4 mb-4 text-left">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
          >
            <i className="far fa-long-arrow-left" /> Se connecter
          </Link>
        </div>
        <div className="login-register-title">
          <h1>S'inscrire</h1>
        </div>
        <div className="login-register-form">
          <form ref={form} onSubmit={handelSubmit}>
            <div className="login-register-input-style input-style">
              <label>Adresse e-mail*</label>
              <input name="email" type="email" required placeholder="email" />
            </div>
            <div className="login-register-input-style input-style">
              <label>Nom *</label>
              <input name="name" type="text" required placeholder="nom" />
            </div>
            <div className="login-register-input-style input-style">
              <label>Prenom *</label>
              <input name="surname" type="text" required placeholder="prenom" />
            </div>
            <div className="row login-register-input-style m-3">
              <label>SEXE *</label>
              <div className="col d-flex justify-content-center">
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="civ1"
                      value="homme"
                    />
                    <label className="form-check-label" htmlFor="civ1">
                      Homme
                    </label>
                  </div>
                </div>
              </div>
              <div className="col d-flex justify-content-center">
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="gender"
                      id="civ1"
                      value="femme"
                    />
                    <label className="form-check-label" htmlFor="civ1">
                      Femme
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="login-register-input-style input-style">
              <label>ADRESSE * </label>
              <input name="adresse" type="text" />
            </div>
            <div className="login-register-input-style input-style">
              <label>TÉLÉPHONE </label>
              <input name="phone" type="text" placeholder="telephone" />
            </div>

            <ProfessionalInfo />
            <div className="row m-3">
              <label>Civilité</label>
              <div className="col d-flex justify-content-center">
                <div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="civ"
                      id="civ1"
                      value="Mr"
                    />
                    <label className="form-check-label" htmlFor="civ1">
                      Mr
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="civ"
                    id="civ2"
                    value="Mme"
                  />
                  <label className="form-check-label" htmlFor="civ2">
                    Mme
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="civ"
                    id="civ3"
                    value="Mlle"
                  />
                  <label className="form-check-label" htmlFor="civ3">
                    Mlle
                  </label>
                </div>
              </div>
              <div className="col">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="civ"
                    id="civ4"
                    value="Dr"
                  />
                  <label className="form-check-label" htmlFor="civ4">
                    Dr
                  </label>
                </div>
              </div>
            </div>
            <div className="login-register-input-style input-style">
              <label>Mot de passe *</label>
              <input name="password" required type="password" />
            </div>
            <div>
              <p style={{color : 'gray'}}>
                {'le mot de passe doit contenir au moins un chiffre, une minuscule and une majuscule et une longueur minimale de 8 caractères.'}
              </p>
            </div>
            <div className="login-register-input-style input-style">
              <label>Confirmer le mot de passe *</label>
              <input required name="passwordConfirm" type="password" />
            </div>
            <div className="privacy-policy-wrap">
              <p>
                Vos données personnelles seront utilisées pour soutenir votre
                expérience sur ce site Web, pour gérer l'accès à votre compte et
                à d'autres fins décrites dans notre{" "}
                <Link to="#">politique de confidentialité</Link>
              </p>
            </div>
            <div className="m-5">
              <label>
                <input
                  style={{ height: "15px", width: "15px" }}
                  type="checkbox"
                  value={condAccepted}
                  onChange={(e) => setcondAccepted(e.target.value)}
                />{" "}
                {"      "}
                j'ai lu et j'accepte {"      "}
                <Link style={{ color: "#2f4e81" }} to="/conditions">
                  {" "}
                  les conditions générales d'utilisations
                </Link>{" "}
                et
                <Link style={{ color: "#2f4e81" }} to="/conditions#vente">
                  {" "}
                  les conditions générales de vente
                </Link>
              </label>
            </div>
            <div className="login-register-btn">
              <button  type="submit" value="submit">
                S'inscrire
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
