import WichPage from "../../Init/header/WhichPage";
import { Link } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogService from "../../../services/blogs.service";
import Rating from "react-rating";
import ProductsService from "../../../services/product.service";
import { BeatLoader } from "react-spinners";
import ReactPaginate from "react-paginate";
import ImageListItem from '@mui/material/ImageListItem';

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const OFFSET = 9;
const SuggestedProducts = () => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    ProductsService.allProductEtonants().then((res) => {
      setProducts(res.data.results.slice(0,4));
    });
  }, []);

  return (
    <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-2 mb-20">
      <h4 className="sidebar-widget-title">Produits étonnants</h4>
      <div className="sidebar-post-wrap mt-30">
        {products.map((item, i) => (
          <div key={i} className="single-sidebar-post">
            <div className="sidebar-post-img">
              <Link to={`/details-produit/${item.name}`}>
              <ImageListItem
                  cols={1}
                  rows={1}
                  style={{
                    display : 'flex',
                    justifyContent : 'center'
                  }}
                >
                  <img
                    style={
                      {
                        borderRadius : 5
                      }
                    }
                    {...srcset(
                      window.IMAGE_API_URL +
                    `products/${item.image.split(";")[0]}`
                      , 250, 200, 1, 1)}
                    alt={'product image'}
                    loading="lazy"
                  />
                </ImageListItem>
              </Link>
            </div>
            <div className="sidebar-post-content">
              <h4>
                <Link to={`/details-produit/${item.name}`}>{item.name}</Link>
              </h4>
              {
              item?.offer_pourcentage ?
              <div style={{
                margin: "0 0 10px"
              }}>
                <span style={{
                  backgroundColor: '#e4573d',
                  display: 'inline-block',
                  fontSize: '13px',
                  fontWeight: 700,
                  textTransform: 'uppercase',
                  letterSpacing: '1px',
                  lineHeight: 1,
                  borderRadius: '2px',
                  color: '#ffffff',
                  padding: '5px 8px 6px'
                }}> {`SALE -${item?.offer_pourcentage}%`}</span>
              </div> :<></>
              }
              <div style={{display : 'flex'}}>
                <span>
                  <b style={{fontSize : 12}}>
                  {
                  item.tarif > 0 ?
                  (
                    item?.offer_pourcentage ?    
                  `${parseFloat(parseFloat(item.tarif) - ((parseFloat(item.tarif) * parseInt(item?.offer_pourcentage)) / 100)).toFixed(2)} DHS TTC` :
                  `${parseFloat(item.tarif).toFixed(2)} DHS TTC`
                  ) :
                  'demande de prix'
                  }
                  </b>
                </span>
                {
                item?.offer_pourcentage ?  
                <span style={{
                  color: 'rgb(168, 160, 149)',
                  textDecorationColor: 'initial',
                  textDecorationLine: 'line-through',
                  textDecorationThickness: 'initial',
                  textDecorationStyle: 'initial',
                  marginLeft : 5
                }}>{parseFloat(item.tarif).toFixed(2)}</span> : <></>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
function compare(a, b) {
  if (a.id > b.id) {
    return -1;
  }
  if (a.id < b.id) {
    return 1;
  }
  return 0;
}

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const [cats, setCats] = useState([]);
  const [loading, setLoading] = useState(true);

  const [catToFilter, setCatToFilter] = useState(useParams().idCat || "");

  //paginattion
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const loadOnPageChange = (p) => {
    setCurrentPage(p);
    const data = { items: OFFSET, page: p + 1 };
    BlogService.blogsByPage(data)
      .then((res) => {
        setBlogs(res.data.results[0].sort(compare));
      })
      .catch(() => setLoading(false));

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    BlogService.allCategoriesBlogs()
      .then((res) => {
        setCats(res.data.results);
        setLoading("false");
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (catToFilter) {
      BlogService.blogByIdCat(catToFilter).then((res) => {
        setBlogs(res.data.results);
      });
    } else {
      BlogService.totalBolgsNbr().then((res) => {
        setPageCount(Math.floor(res.data.results.nbr / OFFSET) + 1);
      });

      const data = { items: OFFSET, page: currentPage + 1 };
      BlogService.blogsByPage(data)
        .then((res) => {
          setBlogs(res.data.results[0].sort(compare));
        })
        .catch(() => setLoading(false));
    }
  }, [catToFilter]);
  return (
    <>
      <WichPage page="Blog" />
      <div className="blog-area pt-75 pb-75">
        <div className="container">
          <div className="row flex-row-reverse">
            {/* <BlogsArea /> */}
            <div className="col-lg-8">
              <div className="row grid">
                {!loading ? (
                  <div className="text-center m-5">
                    <BeatLoader color={"#163C74"} loading={loading} size={30} />
                  </div>
                ) : (
                  blogs.map((blog, i) => (
                    <div
                      key={i}
                      className="col-lg-6 col-md-6 col-12 col-sm-6 grid-item wow tmFadeInUp"
                    >
                      <div className="blog-wrap-2 mb-30">
                        <div className="blog-img-2">
                          <Link to={`/details-blog/${blog.id}`}>
                            <img
                              src={`${window.IMAGE_API_URL}blogs/${blog.image}`}
                              alt=""
                            />
                          </Link>
                          <div className="blog-tag-2">
                            <Link to="blog.html">{blog.catblog}</Link>
                          </div>
                        </div>
                        <div className="blog-content-2">
                          <div className="blog-meta-2">
                            <ul>
                              <li>
                                <i className="far fa-calendar" />
                                {moment(blog.created_at).fromNow()}
                                {"  "}
                                <span style={{ fontSize: "12px" }}>
                                  (
                                  {moment(blog.created_at).format("YYYY-MM-DD")}
                                  )
                                </span>
                              </li>
                              <li>
                                {blog.rates && (
                                  <Rating
                                    initialRating={blog.rates || 0}
                                    readonly
                                    emptySymbol="far fa-star "
                                    fullSymbol="fas fa-star "
                                  />
                                )}
                              </li>
                            </ul>
                          </div>
                          <h3>
                            <Link to={`/details-blog/${blog.id}`}>
                              {blog.titre}
                            </Link>
                          </h3>
                          <div className="blog-btn">
                            <Link to={`/details-blog/${blog.id}`}>
                              Lire la suite{" "}
                              <i className="far fa-long-arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
              {pageCount > 1 && (
                <div className="pro-pagination-style text-center mt-55">
                  <ReactPaginate
                    initialPage={1}
                    previousLabel={<i className="far fa-angle-double-left" />}
                    nextLabel={<i className="far fa-angle-double-right" />}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => {
                      loadOnPageChange(e.selected);
                    }}
                    // onPageActive={(e) => console.log("onPageActive", e)}
                    activeClassName={"activePage"}
                  />
                </div>
              )}
            </div>
            {/* <MenuArea /> */}
            <div className="col-lg-4">
              <div className="sidebar-wrapper sidebar-wrapper-mr1">
                {/* <div className="d-none d-md-block">
                  <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-2 mb-20">
                    <h4 className="sidebar-widget-title">Chercher </h4>
                    <div className="search-style-3">
                      <form action="#">
                        <input type="text" placeholder="Chercher..." />
                        <button type="submit">
                          {" "}
                          <i className="far fa-search" />{" "}
                        </button>
                      </form>
                    </div>
                  </div>
                </div> */}
                <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-1 mb-20">
                  <h4 className="sidebar-widget-title">Blog Par Catégories </h4>
                  <div className="sidebar-categories-list">
                    <ul>
                      {cats.map((cat, i) => (
                        <li key={i}>
                          <Link to="#" onClick={() => setCatToFilter(cat.id)}>
                            {cat.name}
                            <span>({cat.nbrblg})</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <SuggestedProducts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
