export function ColorSize({
    size=undefined,
    color=undefined,
    setSelectedSize,
    setSelectedColor,
    setQuantity,
    ColorSizes=[],
    onHoverImg
}) {
    return (
        <div>
            {
                ColorSizes?.length ? (
                <div className="pro-details-brand" style={{width : '100%'}}>
                <span>
                    <div style={{display : 'flex', alignItems : 'center'}}>
                        <div>
                            Tailles 
                        </div>
                        <div style={{color : 'grey', padding : 4}}>
                            <b>{size || ''}</b>
                        </div>
                    </div>
                    <div style={{display : 'flex'}}>
                    {
                        ColorSizes?.map((val, ind) =>
                        !val?.notprint ?
                        <div key={ind}
                        style={{borderWidth : 2,
                        borderRadius : 10,
                        borderStyle : 'solid',
                        borderColor : val?.size === size ? 'grey' : 'white', 
                        marginLeft : 5, cursor : 'pointer'}}
                        onClick={() => (setSelectedSize(val?.size),setSelectedColor(val?.color),setQuantity(null))}
                        >
                            <p style={{padding : 3, fontWeight : 'bold',
                            color : val?.size === size ? 'black' : 'gray', 
                            }}>{val?.size}</p>
                        </div> :
                        <></>
                        )
                    }
                    </div>
                </span>
                {size ?
                <span>
                    <div style={{display : 'flex', alignItems : 'center'}}>
                        <div>
                            Colors:
                        </div>
                        <div style={{color : 'grey', padding : 4}}>
                            <b>{color || ''}</b>
                        </div>
                    </div>
                    <div style={{display : 'flex'}}>
                    {
                        ColorSizes?.map((val, ind) => 
                        val?.size === size ?
                        <div key={ind} style={{display : 'flex', alignItems : 'center'}}>
                            <div key={ind}
                            style={{
                            borderWidth : 2, borderStyle : 'solid',
                            width : val?.color === color ? 22 : 20, height : val?.color === color ? 22 : 20, borderColor : val?.color === color ? 'grey' : 'white',
                            marginLeft : 5, cursor : 'pointer',backgroundColor : val?.colorCode,
                            borderRadius : 20
                            }}
                            onMouseEnter={(e) => val.image && onHoverImg(`${window.API_URL}${val?.image.replace(/^\//, '') || ''}`)}
                            onMouseLeave={(e) => onHoverImg(null)}
                            onClick={() =>
                            (setSelectedColor(val?.color),setQuantity(val?.quantity))}
                            >
                            </div>
                                ({parseInt(val.quantity) > 100 ? '+100' :
                                (val.quantity || 0)})
                            <div>
                            </div>
                        </div>
                        :
                        <></>
                        )
                    }
                    </div>
                </span>
                : <></>
                }
                </div>
                ) :
                <></>
            }
        </div>
    )
}