import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import UserService from "../../../services/user.service";
import WLService from "../../../services/wishList.service";
import AuthService from "../../../services/auth.service";
import Complaints from "./Complaints"
import Devis from "./Devis"
import Account from "./Account"
import Orders from './orders'

const ProfileTabContent = () => {

  const pwdForm = useRef("null")
  const infoForm = useRef("null")

  const userFitstName = sessionStorage.getItem("name")
  const userLastName = sessionStorage.getItem("surname")



  const fdToJson = (fd) => {
    let obj = {};
    for (let key of fd.keys()) {
      obj[key] = fd.get(key);
    }
    return obj;
  };

  const updateInfo = (e) => {
    e.preventDefault()
    const data = fdToJson(new FormData(infoForm.current))
    UserService.updateInfo(data).then(
      NotificationManager.success('vôtre infos sont mis à jour')
    ).catch(
      err =>
        NotificationManager.warning(`${err}`)
    )
  }
  const [wls, setWls] = useState([]);

  useEffect(() => {
    WLService.getTheWL()
      .then((res) => {
        setWls(res.data.results);
        // console.log(res.data.results)
      })
      .catch((err) => {

        NotificationManager.warning(
          err.response && err.response.data && err.response.data.message
        );
      });
  }, []);
  return (
    <div className="col-lg-8 col-md-8">
      <div className="tab-content" id="myaccountContent">
        {/* Single Tab Content Start */}
        <div
          className="tab-pane fade show active"
          id="dashboad"
          role="tabpanel"
        >
          <div className="myaccount-content">
            <div className="welcome">
              <p>
                Bonjour, <strong style={{marginRight : 3}}>{userFitstName || 'user'} {userLastName || ''}</strong>
                (
                <Link to="login-register" onClick={AuthService.logout} className="logout">
                  Se déconnecter
                </Link>
                )
              </p>
            </div>
            <p className="mb-0">
              Depuis le tableau de bord de votre compte, vous pouvez consulter
              vos commandes récentes, gérer vos adresses de livraison et de
              facturation et modifier votre mot de passe et les détails de votre
              compte.
            </p>
          </div>
        </div>
        {/* List devis */}
        <div className="tab-pane fade" id="download" role="tabpanel">
          <Devis />
        </div>
        {/* Single Tab Content End */}
        {/* Single Tab Content Start */}
        <div className="tab-pane fade" id="address-edit" role="tabpanel">
          <div className="myaccount-table table-responsive text-center">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Nom</th>
                  <th>Adresse</th>
                  <th>Adresse de livraison</th>
                  <th>Telephone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {sessionStorage.getItem("name") || ''}
                  </td>
                  <td>
                    {sessionStorage.getItem("adresse") || ''}
                  </td>
                  <td>
                    {sessionStorage.getItem("address_livraison") ? sessionStorage.getItem("address_livraison") : "-"}
                  </td>
                  <td>
                    {sessionStorage.getItem("phone") || ''}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="myaccount-content myaccount-address">
            <div className="row">
              <div className="col">
                <div className="myaccount-address-wrap">
                  <h3>Adresse</h3>
                  <div className="myaccount-address-content">
                    <h4>{sessionStorage.getItem("name")}</h4>
                    <p>
                      {sessionStorage.getItem("adresse")}
                    </p>
                    <p>Télephone: {sessionStorage.getItem("phone")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* Single Tab Content End */}

        {/* Single Tab Content Start */}
        <div className="tab-pane fade" id="orders" role="tabpanel">
          <div className="myaccount-content">
            <div className="myaccount-table table-responsive text-center">
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Image</th>
                    <th>Nom du prouit</th>
                    <th>date</th>
                    <th>Wishlist</th>
                  </tr>
                </thead>
                <tbody>
                  {wls.map((wl, i) =>
                    <tr>
                      <td>{wl.productImage ? <img
                        src={
                          window.IMAGE_API_URL + "products/" + wl.productImage.split(";")[0]
                        }
                        alt={wl.productImage.split(";")[0]}
                        width={100}
                      /> : <img
                        src={
                          window.IMAGE_API_URL + "products/" + wl.newProductImage.split(";")[0]
                        }
                        alt={wl.newProductImage.split(";")[0]}
                        width={100}
                      />}</td>
                      <td>{wl.productName ? wl.productName : wl.newProductName} </td>
                      <td>{moment(wl.created_at).format("YYYY-MM-DD")} <span> ( {moment(wl.created_at).fromNow()} )</span></td>
                      <td>
                        <a href="/wishlist" className="check-btn sqr-btn ">
                          Voire dans la list
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="tab-pane fade" id="complaints" role="tabpanel">
          <Complaints />
        </div>
        <div className="tab-pane fade" id="command" role="tabpanel">
          <Orders/>
        </div>
        {/* Single Tab Content End */}
        {/* Single Tab Content Start */}
        <div className="tab-pane fade" id="account-info" role="tabpanel">
          <Account/>
        </div>
      </div>
    </div>
  );
};

export default ProfileTabContent;
