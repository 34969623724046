const AboutUsArea = () => {


    return (
      <div className="about-us-area fix about-us-img pt-65 pb-75">
        <div className="container">
          <div className="section-title-2 mb-35 wow tmFadeInUp">
            <h2>La qualité au service de nos clients.</h2>

          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-us-img wow tmFadeInUp">
                <img src="/assets/images/banner/banner-9.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-us-content wow tmFadeInUp">
                <div className="total-years">
                  <h2 className="count">3</h2>

                  <h4>
                    Ans <br />
                    Expérience
                    <br />
                    Travail
                  </h4>
                </div>
                <h3>Matériel et fournitures médicales de haute qualité</h3>
                <p>
                  Healthcare Facilities Consult est une entreprise familiale
                  avec des decennies d'expérience cumulée dans le domaine
                  médical.Notre but est d'offrir à nos partenaires un
                  accompagnement et une expertise dans le domaine de la
                  conception et la réalisation des établissements sanitaires.
                  Pour les cliniques déjà opérationnelles, nous mettons à leur
                  disposition le matériel médical et le consommable
                  médico-chirurgical de toute nature. Nous nous engageons à
                  fournir le meilleur produit au meilleur prix. Nous offrons
                  également au client particulier une large gamme de produits
                  relatif au diagnistic médical, aide à la marche et
                  hospitalisation à domicile.
                </p>
                <p>
                  L'excellence du client a toujours été une priorité absolue et
                  elle le sera toujours. Merci d'avoir visité notre site. Nous
                  avons hâte de travailler avec vous!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mouse-scroll-area-2" id="scene">
          <div data-depth="0.3" className="layer about-us-shape-1">
            <div className="medizin-shape" />
          </div>
        </div>
      </div>
    );
}

export default AboutUsArea ;