import { Link, useLocation } from "react-router-dom";
import "./styles.css";

const ListItem = (props) => {
  const location = useLocation();
  let isActive = location.pathname.includes(props.name.replaceAll("/", "_"));

  //styling depending on status
  const allClassNames = isActive
    ? `activeCat ${props.className}`
    : `inactiveCat ${props.className}`;

  return (
    <li className={allClassNames}>
      {isActive && <i className="far fa-hand-point-right mr-1" />}
      <Link to={`/boutique/${props.name.replaceAll("/", "_")}`}>
        {" "}
        {props.name}{" "}
      </Link>
      {props.children}
    </li>
  );
};
const SubCategoriesForCatItem = (props) => {
  const location = useLocation().pathname;
  const fams = props.families;
  return (
    <ul>
      {props.subcat.map(
        (subcat, i) =>
          subcat.category_id === props.cat.id && (
            <li
              className={
                location.includes(subcat.name) ? "activeCat" : "inactiveCat"
              }
              key={i}
            >
              {location.includes(subcat.name) ? (
                <Link
                  className="activeCat"
                  style={{ color: "#024dbc" }}
                  to={`/boutique/${props.cat.name.replaceAll(
                    "/",
                    "_"
                  )}/${subcat.name.replaceAll("/", "_")}`}
                >
                  <i className="far fa-hand-point-right mr-1" />
                  {subcat.name}
                </Link>
              ) : (
                <Link
                  className="inactiveCat"
                  to={`/boutique/${props.cat.name.replaceAll(
                    "/",
                    "_"
                  )}/${subcat.name.replaceAll("/", "_")}`}
                >
                  {subcat.name}
                </Link>
              )}
              {props.children}
              <ul>
                {fams.map(
                  (family, i) =>
                    family.subcategory_id === subcat.id && (
                      <li key={i}>
                        {location ===
                        `/boutique/${family.category}/${family.sub_category}/${family.name}` ? (
                          <Link
                            style={{ color: "#024dbc" }}
                            to={`/boutique/${family.category}/${family.sub_category}/${family.name}`}
                          >
                            <i className="far fa-hand-point-right mr-1" />
                            {family.name}
                          </Link>
                        ) : (
                          <Link
                            to={`/boutique/${family.category}/${family.sub_category}/${family.name}`}
                          >
                            {family.name}
                          </Link>
                        )}
                      </li>
                    )
                )}
              </ul>
            </li>
          )
      )}
    </ul>
  );
};

const CategorieMenu = (props) => {
  const categories = props.cat;
  const subCategories = props.subcat;
  const families = props.families;

  return (
    <div className="d-none d-md-block">
      <div className="sidebar-widget sidebar-widget-wrap sidebar-widget-padding-1 mb-20">
        <h4 className="sidebar-widget-title">Catégories</h4>
        <div className="sidebar-categories-list">
          <ul className="categorie">
            {categories.map((category, index) => (
              <ListItem
                className=""
                key={index}
                name={category.name}
                to={category.id}
              >
                <SubCategoriesForCatItem
                  cat={category}
                  subcat={subCategories}
                  families={families}
                />
              </ListItem>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CategorieMenu;
