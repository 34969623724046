import moment from "moment";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import DevisService from "../../../services/devis.service";
import WLService from "../../../services/wishList.service";

const RowWithSingleWish = (props) => {
  const removeItem = () => {
    WLService.removeProductFromWL(props.wl.id)
      .then((res) => {
        NotificationManager.success("L'element est supprimé");
        WLService.getTheWL().then((res) => {
          props.cbWls(res.data.results);
        });
      })
      .catch((err) => NotificationManager.warning(`${err}`));
  };

  const addToDevis = () => {
    const data = {
      id_produit: props.wl.id_product,
      id_user: props.wl.id_client,
    };
    DevisService.addProductToDevis(data)
      .then((res) => {
        NotificationManager.success("L'element est met dans le devis");
        WLService.removeProductFromWL(props.wl.id).then((res) => {
          WLService.getTheWL().then((res) => {
            props.cbWls(res.data.results);
          });
        });
      })
      .catch((err) => console.log(err));
  };
  // useEffect(() => {
  //   ProductsService.productById(props.wl.id_product).then((res) => {
  //     setProduct(res.data.result[0]);
  //     console.log(res.data.result[0]);
  //   });
  // }, []);
  return (
    <tr>
      <td className="wishlist-product-thumbnail">
        <Link to="#" onClick={removeItem}>
          <i className="far fa-times" />
        </Link>
      </td>
      <td className="wishlist-product-img">
        <Link to={
          `/details-produit/${props.wl.productName}`
        }>
          <img
            src={`${window.IMAGE_API_URL}products/${
              props.wl.productImage && props.wl.productImage.split(";")[0]
            }`}
            alt=""
          />
        </Link>
      </td>
      <td className="wishlist-product-info">
        <h5>
          <Link to={`/details-produit/${props.wl.productName}`}>{props.wl.productName}</Link>
        </h5>
        <span>{moment(props.wl.created_at).format("YYYY-MM-DD")}</span>
        <h6>{moment(props.wl.created_at).fromNow()}</h6>
      </td>
      <td className="wishlist-product-add-wrap">
        <div className="wishlist-product-add">
          <Link to="#" onClick={addToDevis}>
            Demander un devis
          </Link>
        </div>
      </td>
    </tr>
  );
};
const WishListDetails = () => {
  const [wls, setWls] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    WLService.getTheWL()
      .then((res) => {
        setWls(res.data.results);
        console.log(res.data.results);
        setLoading(false);
        NotificationManager.success(`${res.data.results.length} element`);
      })
      .catch((err) => {
        setLoading(false);
        NotificationManager.warning(
          err.response && err.response.data && err.response.data.message
        );
      });
  }, []);

  return (
    <div className="wishlist-area pt-75 pb-75">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <form action="#">
              <div className="wishlist-table-content">
                <div className="table-content table-responsive">
                  {loading ? (
                    <div className="row text-center">
                      <BeatLoader
                        color={"#163C74"}
                        loading={loading}
                        size={30}
                      />
                    </div>
                  ) : (
                    <table>
                      <tbody>
                        {wls.map((wl, i) => (
                          <RowWithSingleWish key={i} wl={wl} cbWls={setWls} />
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishListDetails;
