import React, { useEffect, useState, useRef } from "react";

import { NotificationManager } from "react-notifications"
import './style.css'
import ReclamationService from '../../../services/reclamation.service'
import AuthService from "../../../services/auth.service";

import {io} from 'socket.io-client'


const ContactForm = () => {
  const form = useRef(null);
  
  const [image, setImage] = useState("");
  const [socket, setSocket] = useState(io(window.socket_API_URL))
  // useEffect(() => {
  //   socket.on("connect", ()=>{
  //     console.log("you are connected to the socket")
  //   })
  // },[])

  const handelSubmit = e => {
    e.preventDefault()
    const data = new FormData(form.current);
    data.append("user_id", sessionStorage.getItem("id"))
    ReclamationService.sendReclamation(data)
      .then((res) => {

        socket.emit("send-reclamation",AuthService.getCurrentUser(),data.get("type") )



        NotificationManager.success(
          "Your reclamation is sent successfully",
          "",
          2000
        );

      })
      .catch((error) => {
        if (error.response.status === 401) {
          NotificationManager.error(
            "Votre session est expiree, veuillez vous reconnectez",
            "",
            4000
          );
          setTimeout(() => {
            AuthService.logout()
          }, 500);
        }
        else if(error.response.status === 403)
        {
          NotificationManager.error(
            "Could not send reclamation",
            "",
            2000
          );
        }
      })

  }



  return (

    <div className="row">
      <div className="col-xl-8 col-lg-10 ml-auto mr-auto">
        <div className="contact-from-area  padding-20-row-col wow tmFadeInUp">
          <form className="contact-form-style text-center" ref={form} onSubmit={handelSubmit}>
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  {/* <input name="telephone" placeholder="selectionner le status" type="text" /> */}
                  <select name="type"  className="form-select rounded-pill" required placeholder="selectionner le status">
                    <option value="null" disabled selected >selectionner le statut</option>
                    <option value="Urgente" >Urgente</option>
                    <option value="Haute" >Haute</option>
                    <option value="Moyenne" >Moyenne</option>
                    <option value="Faible" >Faible</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="input-style mb-20">
                  <input name="title" required placeholder="Titre de la rèclamation" type="text" />
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div class="wrapper">
                  <label htmlFor="file" className="mr-4">Importer une image: </label>
                  <div class="file-upload">
                    <input type="file" name="image" id="file"
                      onChange={(e) => {
                        setImage(e.target.files[0] ? e.target.files[0] : "");
                      }} />
                    <i class="fa fa-arrow-up"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <div className="textarea-style mb-30">
                  <textarea name="message" required placeholder="description" />
                </div>
                <button className="submit submit-auto-width" type="submit">Envoyer</button>
              </div>

            </div>
          </form>
          <p className="form-messege" />
        </div>
      </div>
    </div>
  )
}

export default ContactForm;