import { FacebookShareButton } from "react-share";

const ShareOnFacebook = (props) => {
  const postUrl = "https://web.facebook.com/Healthcare.Facilities.Consult";
  return (
    <FacebookShareButton url={postUrl} className="ml-4" quote={props.desc}>
      <i className="fab fa-facebook-f" />
    </FacebookShareButton>
  );
};

export default ShareOnFacebook;
