import { Link } from "react-router-dom";

const LeftSlids = ({ subSliders }) => {
  return (
    <div  style={{ height: "100%" }}>
      {subSliders.map((val, ind)=> (
        <div key={ind} className="col-12" style={{ height: "35%",display:"initial"}}>
          <div className="banner-wrap">
            <div className="banner-img banner-img-zoom">
              <Link to={"/details-produit/" + val.product}>
                <img
                  src={`${window.IMAGE_API_URL}sliders/${val.subimage}`}
                  alt=""
                />
              </Link>
            </div>
            <div className="left-banner">
              <span>{val.catg}</span>
              <h2>{val.product}</h2>
            </div>
            <div className="btn-style-1 btn-slide">
              <Link
                className="font-size-14 btn-1-padding-2"
                to={"/details-produit/" + val.product}
              >
                Voir details
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LeftSlids;
