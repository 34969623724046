import axios from "axios";
import authHeader from "./auth-header";

const idUser = sessionStorage.getItem("id");
const getTheWL = () => {
  return axios.get(`${window.API_URL}user/my_wishlist/${idUser}`, authHeader());
};

const addProductToWl = (data) => {
  return axios.post(
    `${window.API_URL}user/whishlist_product`,
    data,
    authHeader()
  );
};

const removeProductFromWL = (id) => {
  return axios.delete(
    window.API_URL + `user/whishlist_product/${id}`,
    authHeader()
  );
};
const WLService = {
  getTheWL,
  addProductToWl,
  removeProductFromWL,
};
export default WLService;
